import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="footer d-none">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <p className="mb-0">
                                <Link to="/" target="_blank" className="text-muted">
                                    <strong>Desenvolvido pela 8Bits Company</strong>
                                </Link> ©
                            </p>
                        </div>
                        <div className="col-6 text-end">
                            <p className="mb-0">
                                <strong>Versão 1.0</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;