import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select'


function EditarUsuario() {
    const { id } = useParams(); // Obtém o id do usuário a partir da URL
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });


    const telefone = useRef(null);
    const cpf = useRef(null);

    //campos com mask não são inseridos com o reacthookform
    const [cpfUsuario, setCpfUsuario] = useState('');
    const [telefoneUsuario, setTelefoneUsuario] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const buscarUsuario = async () => {
            try {
                const response = await Api.get(`BuscarUsuario?id=${id}`);
                // Define os valores iniciais dos campos
                console.log(response.data)
                const { nome, cpf, email, telefone, nivel } = response.data.data[0];
                setValue('nome', nome);
                setValue('email', email);
                setValue('nivel', nivel);
                setTelefoneUsuario(telefone);
                setCpfUsuario(cpf);

            } catch (error) {
                console.log(error);
            }
        };


        const fetchNiveis = async () => {
            try {
                const NiveisResponse = await Api.get("ListarNiveis");
                console.log(NiveisResponse.data.data)
                setOptions(NiveisResponse.data.data)

                const nivelSelecionado = NiveisResponse.data.data.find(nivel => nivel.id === watch('nivel'));
                if (nivelSelecionado) {
                    handleNivelChange({ value: nivelSelecionado.id, label: nivelSelecionado.nome });
                }
            } catch (error) {
                console.log(error);
            }
        };
        buscarUsuario();
        fetchNiveis();
    }, [id, setValue]);


    const [selectedOption, setSelectedOption] = useState(null);

    const handleNivelChange = (selectedOption) => {
        console.log(selectedOption)
        setValue('nivel', selectedOption.value);
        setSelectedOption({ value: selectedOption.value, label: selectedOption.label })
    };


    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {
            // CAMPOS COM MASK precisam ser atualizados assim
            data.telefone = telefone.current.value;
            data.cpf = cpf.current.value;
            data.id = id;
            const response = await Api.put(`EditarUsuario`, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            console.log(response);

            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-usuarios'
            }

        } catch (error) {
            console.log(error);
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o usuario. Por favor, tente novamente mais tarde." + error.response.data.error
            });
        }
    };


    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon float-right">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Usuário</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Nome Completo*</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Telefone / Celular*</label>
                                    <IMaskInput
                                        mask="(00) 00000-0000"
                                        type="text"
                                        inputRef={telefone}
                                        {...register("telefone")}
                                        value={telefoneUsuario}
                                        onAccept={(value, mask) => {
                                            setTelefoneUsuario(value);
                                            mask.updateValue();
                                        }}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Email*</label>
                                    <input type="email" className="form-control" {...register("email")} />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="form-label">CPF*</label>
                                    <IMaskInput
                                        mask="000.000.000-00"
                                        type="text"
                                        inputRef={cpf}
                                        {...register("cpf")}
                                        defaultValue={cpfUsuario}
                                        value={cpfUsuario}
                                        onAccept={(value, mask) => {
                                            setCpfUsuario(value);
                                            mask.updateValue();
                                        }}
                                        className="form-control"
                                        placeholder="Digite o CPF do usuário"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Nível*</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={options.map((option) => {
                                            const id = option.id;
                                            const nome = option.nome;
                                            return {
                                                value: id,
                                                label: nome,
                                            };
                                        })}
                                        value={selectedOption}
                                        onChange={handleNivelChange}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarUsuario;
