import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logoBlack from "../../Components/nova-logo-black.png";


pdfMake.vfs = pdfFonts.pdfMake.vfs;


const generateCertificado = async (data) => {
    const {
        informacoesBasicas,
        vidros,
        clientes,
        funcionarios,
        peliculas,
        novoCliente,
    } = data;

    const docDefinition = {
        pageSize: "A4",
        pageOrientation: "portrait",
        content: [],
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            logo: {
                alignment: "right",
                width: 100
            },
            title: {
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subtitle: {
                fontSize: 12,
                margin: [0, 0, 0, 5]
            }
        },
        defaultStyle: {
            fontSize: 10,
        }
    };

    // Adicionar o título e o logotipo da empresa
    docDefinition.content.push(
        { image: logoBlack, style: "logo", alignment: "right", width: 105, height: 54 },
        { text: `O.S. Arquitetura ${informacoesBasicas.idOS ? "#" + informacoesBasicas.idOS : ''}`, style: "title" },
        {
            canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
            ]
        }
    );

    const addSectionIfDataExists = (sectionTitle, data) => {
        if (data) {
            docDefinition.content.push(
                { text: sectionTitle, style: "header" },
                { ul: data }
            );
        }
    };

    const getNomeCliente = (idCliente) => {
        if (clientes && Array.isArray(clientes)) {
            const cliente = clientes.find((cliente) => cliente.id === idCliente);
            if (cliente) {
                return cliente.nome || cliente.nome_fantasia || cliente.nome_contato || "Nome não encontrado";
            }
        }
        return "Nome do cliente não encontrado";
    };

    const getNomeFuncionario = (idList) => {
        console.log("lista: ", idList)
        let nomes = [];
        for (const id in idList) {
            const nome = funcionarios.find(funcionario => funcionario.id == idList[id]).apelido || funcionarios.find(funcionario => funcionario.id == idList[id]).nome;
            nomes.push(nome);
        }
        return nomes.join(', ');
    };


    const getNomePelicula = (idPelicula) => {
        if (peliculas && Array.isArray(peliculas)) {
            const pelicula = peliculas.find((pelicula) => pelicula.id === idPelicula);
            return pelicula ? pelicula.nome : " ";
        }
        return "Nome da película não encontrado";
    };


    // Função para obter as informações de um cliente (priorizando o novoCliente)
    const getClienteInfo = () => {
        if (novoCliente) {
            return {
                rua: novoCliente.endereco,
                numero: novoCliente.numero,
                bairro: novoCliente.bairro,
                cidade: novoCliente.cidade,
                quadra: novoCliente.quadra,
                lote: novoCliente.lote,
                apto: novoCliente.apto,
                complemento: novoCliente.complemento,
                telefone: novoCliente.telefone,
                celular: novoCliente.celular,
                contato: novoCliente.contato,
                email: novoCliente.email || novoCliente.email_profissional,
                nomeContato: novoCliente.nome_contato || '-',
                razao: novoCliente.razao,
            };
        }

        const clienteExistente = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente);

        if (!clienteExistente) {
            return null;
        }

        return {
            rua: clienteExistente.endereco,
            numero: clienteExistente.numero,
            bairro: clienteExistente.bairro,
            cidade: clienteExistente.cidade,
            quadra: clienteExistente.quadra,
            lote: clienteExistente.lote,
            apto: clienteExistente.apto,
            complemento: clienteExistente.complemento,
            telefone: clienteExistente.telefone,
            celular: clienteExistente.celular,
            contato: clienteExistente.contato,
            email: clienteExistente.email || clienteExistente.email_profissional,
            nomeContato: clienteExistente.nome_contato || '-',
            razao: clienteExistente.razao,
        };
    };

    const clienteInfo = getClienteInfo();
    console.log("cliente info: ", clienteInfo)

    const enderecoParts = [clienteInfo.rua, clienteInfo.numero, clienteInfo.bairro, clienteInfo.cidade];
    const enderecoFiltered = enderecoParts.filter((part) => part);
    const enderecoCliente = enderecoFiltered.join(', ');

    const complementos = [
        clienteInfo.quadra ? `Quadra: ${clienteInfo.quadra}` : '',
        clienteInfo.lote ? `Lote: ${clienteInfo.lote}` : '',
        clienteInfo.apto ? `Apto: ${clienteInfo.apto !== null ? clienteInfo.apto : ''}` : '',
        clienteInfo.complemento ? `Complemento: ${clienteInfo.complemento}` : '',
    ];
    const complementosFiltered = complementos.filter((part) => part);
    const complementosCliente = complementosFiltered.join(', ');

    const contatoInfo = [clienteInfo.telefone, clienteInfo.celular, clienteInfo.contato];
    const contatoFiltered = contatoInfo.filter((part) => part);
    const contatoCliente = contatoFiltered.join(', ');

    const emailInfo = [clienteInfo.email];
    const emailFiltered = emailInfo.filter((part) => part);
    const email = emailFiltered.join(', ');


    const atendente = informacoesBasicas.id_funcionario ? funcionarios.find((funcionario) => funcionario.id === informacoesBasicas.id_funcionario).apelido || funcionarios.find((funcionario) => funcionario.id === informacoesBasicas.id_funcionario).nome || null : null;

    const nome = getNomeCliente(informacoesBasicas?.id_cliente);
    addSectionIfDataExists('', [
        nome != "Nome do cliente não encontrado" ? `${nome}\n` : null,
        clienteInfo.nomeContato !== '-' ? `${clienteInfo.nomeContato}\n` : null,
        clienteInfo.razao ? `${clienteInfo.razao}\n` : null,
        enderecoCliente ? `${enderecoCliente}\n` : null,
        complementosCliente ? `${complementosCliente}\n` : null,
        contatoCliente !== '' ? `${contatoCliente}\n` : null,
        email ? `${email}\n` : null,
    ]);

    docDefinition.content.push({
        text: [
            atendente ? { text: `\nAtendente: ${atendente}` } : null,
        ],
        margin: [0, 5, 0, 10]
    });

    const buscaAplicadores = (aplicadores) => {
        //iterar os ids dos aplicadores e construir uma string com os nomes dos funcionarios correspondentes
        let nomes = [];

        for (const id in aplicadores) {
            const nome = funcionarios.find(funcionario => funcionario.id == aplicadores[id]).apelido || funcionarios.find(funcionario => funcionario.id == aplicadores[id]).nome;
            nomes.push(nome);
        }

        const nomes_string = nomes.join(', ');
        return nomes_string;
    }

    // Tabela com vidros de os.vidros
    if (vidros.length > 0) {
        docDefinition.content.push({ text: "Vidros", style: "header" });

        const tableHeader = [
            { text: "Local", bold: true, alignment: "left" },
            { text: "Altura", bold: true, alignment: "left" },
            { text: "Largura", bold: true, alignment: "left" },
            { text: "Qtd", bold: true, alignment: "left" },
            { text: "Total m²", bold: true, alignment: "left" },
            { text: "Película", bold: true, alignment: "left" },
        ];

        const tableBody = [tableHeader];

        for (const vidro of vidros) {
            const tableRow = [
                vidro.local_aplicacao,
                vidro.altura,
                vidro.largura,
                vidro.quantidade,
                (vidro.altura * vidro.largura * vidro.quantidade).toFixed(2).replace(".", ","),
                getNomePelicula(vidro.id_pelicula),
            ];
            tableBody.push(tableRow);
        }

        const table = {
            table: {
                widths: ['*', 'auto', 'auto', 'auto', 'auto', '*'], // Ajuste de largura das colunas
                body: tableBody,
            },
            margin: [0, 5, 0, 10]
        };

        docDefinition.content.push(table);
    }

    const aplicadores = buscaAplicadores(informacoesBasicas.aplicadores || informacoesBasicas.aplicador);


    docDefinition.content.push({
        text: [
            aplicadores ? { text: `Aplicador(es):  ${aplicadores}` } : null,
        ],
        margin: [0, 5, 0, 10]
    });

    // docDefinition.content.push({ canvas: [{ type: 'line', x1 : 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 }] });




    if (informacoesBasicas.observacao) {
        docDefinition.content.push({
            text: [
                //margem superior
                { text: `\nObservação: ${informacoesBasicas.observacao}`, margin: [0, 5, 0, 0] },
            ],
        });
    }



    // Adicionar o valor total
    const valorTotal = data.informacoesBasicas.valor_total;
    // const desconto = data.informacoesBasicas.desconto ? parseFloat(data.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.')) : 0; // Valor padrão de desconto caso não esteja definido

    let desconto = 0.0 // Verifica se o desconto existe e converte para float, caso contrário, define como 0

    if (data.informacoesBasicas.desconto && data.informacoesBasicas.desconto != null && data.informacoesBasicas.desconto != undefined && data.informacoesBasicas.desconto[0] === 'R') {
        desconto = parseFloat(data.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.')) || 0 // Verifica se o desconto existe e converte para float, caso contrário, define como 0
    } else {
        desconto = parseFloat(data.informacoesBasicas.desconto) || 0
    }


    if (valorTotal) {
        const valorFinal = valorTotal - desconto;

        docDefinition.content.push(
            {
                text: "\n",
            },
            {
                canvas: [
                    { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
                ]
            },
            { text: `Valor Total: R$ ${parseFloat(valorFinal).toFixed(2).replace(".", ",")}`, alignment: "right", margin: [0, 10], bold: true }
        );
    }






    if (docDefinition.content.length > 0) {
        const newWindow = window.open("", "_blank", "width=800,height=900");
        pdfMake.createPdf(docDefinition).open({}, newWindow);
    } else {
        console.log("Não há dados para gerar o certificado.");
    }
};

export default generateCertificado;
