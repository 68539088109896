import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logoBlack from './nova-logo-black.png';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const printAgendamento = async (dataFull) => {

    let {
        id,
        titulo,
        data: dataAgendamento,
        descricao,
        horario,
    } = dataFull;

    if (!horario) { //caso em que o horario está jundo da data
        //deve tirar 3 horas da dataAgendamento
        let data = new Date(dataAgendamento);
        data.setHours(data.getHours());
        //dataAgendamento deve ter o formato de data e hora mas sem os segundos
        dataAgendamento = `${data.toLocaleDateString()}, ${data.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;



    } else {
        //aqui deve usar o campo horario (que ja vem a string correta) mas deve formatar o campo dataAgendamento como antes usando o horario dentro de horario
        let data = new Date(dataAgendamento);
        data.setHours(horario.split(":")[0]);
        data.setMinutes(horario.split(":")[1]);
        //dataAgendamento não deve ter segundos
        dataAgendamento = `${data.toLocaleDateString()}, ${data.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;





    }



    const docDefinition = {
        pageSize: "A4",
        pageOrientation: "portrait",
        content: [],
        styles: {
            header: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            logo: {
                alignment: "right",
                width: 100
            },
            title: {
                fontSize: 20,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subtitle: {
                fontSize: 14,
                margin: [0, 0, 0, 5]
            }
        },
        defaultStyle: {
            fontSize: 12
        }
    };

    docDefinition.content.push(
        { image: logoBlack, style: "logo", alignment: "right", width: 105, height: 54 },
        { text: "Agendamento", style: "title" },
        { text: "Alameda Paulista, 1278 - Vila Xavier, Araraquara-SP | (11) 99999-9999 | solar@solar.com", style: "subtitle", fontSize: 9 },
        {
            canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 }
            ]
        },
        { text: "Dados do Agendamento" + ` #${id}`, style: "header" },
        {
            columns: [
                {
                    width: "*",
                    text: [
                        { text: "Título: ", bold: true },
                        { text: titulo }
                    ]
                }
            ]
        },
        {
            columns: [
                {
                    width: "auto",
                    text: [
                        { text: "Data: ", bold: true },
                        { text: dataAgendamento }, //precisa dar um espaço entre a data e o horário
                        { text: " " }
                    ]
                }
            ]
        },
        { text: "Descrição:", style: "header" },
        { text: descricao }
    );

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.open();
};



export default printAgendamento;
