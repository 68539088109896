import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Api from '../../Api';
import Table from '../../Components/Table'
import { parse, isValid, format, parseISO, addMonths, subDays } from 'date-fns';
import CurrencyInput from '../../Components/CurrencyInput';


const ComissoesLista = () => {
    const [status, setStatus] = useState({ success: false, message: '' })

    const [comissoes, setComissoes] = useState([]);
    const [comissoesData, setComissoesData] = useState([]);
    const [funcionarios, setFuncionarios] = useState([]);
    const [resetTable, setResetTable] = useState(); //estado inicial da tabela
    const [dataShow, setDataShow] = useState([]);


    const [filterData, setFilterData] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [detailsData, setDetailsData] = useState([]);


    const filtrarLista = () => {
        const { inicio, fim } = filterData;

        const registrosFiltrados = [];

        //filtro de data
        comissoesData.forEach((comissao) => {
            const dataComissao = new Date(comissao.data); // Supondo que cada comissao tenha uma propriedade "data"
            const dataComissaoFormatada = format(dataComissao, 'yyyy-MM-dd');
            if ((!inicio || dataComissaoFormatada >= inicio) && (!fim || dataComissaoFormatada <= fim)) {
                registrosFiltrados.push(comissao);
            }
        });


        let registros = calcularQuantidadeTotal(registrosFiltrados); //constroi o objeto da lista



        //deixar em registros só os registros que tem o id do filtro
        if (filterData.id) {
            registros = registros.filter((registro) => {
                return registro.id_funcionario == filterData.id;
            });
        }


        setDataShow(registros);
    }

    const comissoesDetalhadas = (id) => {
        console.log("detalhes de: " + id);
        //deve filtrar a data antes de começar
        const { inicio, fim } = filterData;
        const registrosFiltrados = [];
        if (inicio || fim) {
            comissoesData.forEach((comissao) => {
                const dataComissao = new Date(comissao.data); // Supondo que cada comissao tenha uma propriedade "data"
                const dataComissaoFormatada = format(dataComissao, 'yyyy-MM-dd');
                if ((!inicio || dataComissaoFormatada >= inicio) && (!fim || dataComissaoFormatada <= fim)) {
                    registrosFiltrados.push(comissao);
                }
            });
        } else {
            console.log('entrou no else')
            registrosFiltrados.push(...comissoesData); // Use push para adicionar os elementos de comissoesData a registrosFiltrados
        }


        const comissoesFiltradas = registrosFiltrados.filter((comissao) => {
            const idsFuncionario = comissao.id_funcionario.split(',').map(id => parseInt(id)); // Converter para números

            return idsFuncionario.includes(parseInt(id));
        });

        console.log("funcionario: " + id, comissoesFiltradas);
        return (comissoesFiltradas);

    }

    const formatarValorMonetario = (valor) => {
        const valorNumerico = parseFloat(valor);

        if (isNaN(valorNumerico)) {
            return '';
        }

        const valorFormatado = valorNumerico.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return valorFormatado;
    }



    const getNome = (id, array) => {
        const item = array.find(item => item.id == id);
        return { nome: item.nome || null, apelido: item.apelido }
    }

    function calcularQuantidadeTotal(data) {
        const comissaoPorFuncionario = {}; // Objeto para armazenar comissão total por funcionário

        // Iterar sobre os dados
        for (const entrada of data) {
            const idFuncionarios = entrada.id_funcionario.split(','); // Separar IDs por vírgula
            const valorComissao = parseFloat(entrada.valor_comissao);

            // Para cada ID do funcionário, adicionar a comissão
            for (const id of idFuncionarios) {
                const idTrimmed = id.trim(); // Remover espaços em branco, se houver
                comissaoPorFuncionario[idTrimmed] = (comissaoPorFuncionario[idTrimmed] || 0) + valorComissao;
            }
        }

        // Converter o objeto em uma lista com as propriedades "id_funcionario" e "comissao_total"
        const resultado = Object.keys(comissaoPorFuncionario).map(id => ({
            id_funcionario: id,
            comissao_total: comissaoPorFuncionario[id]
        }));

        return resultado;
    }

    useEffect(() => {
        const getData = async () => {
            const relatoriosResponse = await Api.get('/Relatorios');
            const data = relatoriosResponse.data.data.comissoes;
            setComissoes(data);
            setComissoesData(data);
            setFuncionarios(relatoriosResponse.data.data.funcionarios);



            const registros = calcularQuantidadeTotal(data);
            console.log(registros)


            setDataShow(registros);
            setResetTable(registros);

        }
        getData();
    }, []);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });
        filtrarLista();
    }, [comissoes]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'idOS',
                columns: [
                    {
                        Header: "#",
                        accessor: row => row.id_funcionario ?? '-'
                    },
                    {
                        Header: "Nome Funcionário",
                        accessor: row => {
                            const nomeObj = getNome(row.id_funcionario, funcionarios);
                            return nomeObj && nomeObj.apelido ? nomeObj.apelido : (nomeObj && nomeObj.nome ? nomeObj.nome : '-');
                        }
                    },
                    {
                        Header: "Comissão",
                        accessor: row => `R$ ${row.comissao_total.toFixed(2).replace(".", ",")}` ?? '-'
                    },
                    {
                        Header: "Detalhes",
                        accessor: row => (
                            <div className="btn-group">
                                <button className="btn btn-primary" onClick={() => { setShowDetailsModal(true); setDetailsData(comissoesDetalhadas(row.id_funcionario)) }}>Ver</button>
                            </div>
                        )
                    },
                ]
            }
        ],
        [dataShow]
    );


    const handleChange = (id, value) => {
        console.log(id, value);
        //troca o valor_comissao no id correspondente ao id da comissao em comissoes

        const comissao = comissoes.find(comissao => comissao.id == id);
        comissao.valor_comissao = value.replace('R$', '').replace('.', '').replace(',', '.');

        const comissoesAtualizadas = comissoes.map(comissao => {
            if (comissao.id == id) {
                return comissao;
            }
            return comissao;
        }
        );

        setComissoes(comissoesAtualizadas);
    }

    const editComissao = async (comissao) => {
        console.log(comissao)
        //request to EditarComissao
        const response = await Api.put('/EditarComissao', comissao);
        const { success, message } = response.data;

        console.log(response)

    }

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Relatório de Comissões</h1>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={dataShow} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-label">ID</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="id"
                                    placeholder='#'
                                    min={0}
                                    value={filterData.id || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            id: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista(); }}>Confirmar</button>
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setDataShow(resetTable); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>

            </Modal >


            <Modal centered show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailsData.map((comissao, index) => (
                        <div key={index} className="mb-4">
                            <p className="font-weight-bold">ID OS: {comissao.idOS}</p>
                            <p className="font-weight-bold">Tipo: {comissao.tipo.charAt(0).toUpperCase() + comissao.tipo.slice(1)}</p>
                            {comissao.area && comissao.area !== 'null' && comissao.area !== 'undefined' && (
                                <p className="font-weight-bold">Área: {comissao.area} m²</p>
                            )}
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: -7 }}>
                                <label className="font-weight-bold" htmlFor="valorComissao" style={{ marginRight: '8px' }}>
                                    Valor Comissão:
                                </label>
                                <CurrencyInput
                                    className='form-control'
                                    type="text"
                                    defaultValue={formatarValorMonetario(comissao.valor_comissao)}
                                    onChange={(value) => handleChange(comissao.id, value)}
                                    style={{ width: '22%', marginRight: '8px' }}
                                />
                                <button
                                    className="btn btn-primary w-15"
                                    onClick={() => { editComissao(comissao) }}
                                >
                                    Confirmar Edição
                                </button>
                            </div>


                            <p className="font-weight-bold mt-2">Data: {format(new Date(comissao.data), 'dd/MM/yyyy')}</p>
                            <hr className="my-2" />
                        </div>
                    ))}
                </Modal.Body>








                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { setShowDetailsModal(false); setFilterData([]) }}>Fechar</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ComissoesLista;
