import React from 'react'
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Table({ columns, data, lenght = true, search = true, info = true }) {
    const props = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 20 }

        },
        useGlobalFilter,
        usePagination,

    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageSize, globalFilter }
        // state: { pageIndex, pageSize, globalFilter }
    } = props;
    React.useEffect(() => {
    }, [globalFilter]);

    const displaySearch = (search === 'false') ? 'd-none' : '';
    const displayLenght = (lenght === 'false') ? 'd-none' : '';
    const displayInfo = (info === 'false') ? 'd-none' : '';

    return (
        <>
            <form className="row g-3 align-items-center">
                <div className={`col-5 col-md-2 d-flex align-items-center ${displayLenght}`}>
                    <label className="col-form-label me-2">Exibir</label>
                    <select className="form-select" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                        {[20, 50, 100, 500, 1000].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={`col-7 col-md-4 ms-auto ms ${displaySearch}`}>
                    <input type="text" className="form-control" placeholder='Pesquisar' value={globalFilter || ""} onChange={e => setGlobalFilter(e.target.value)} />
                </div>
            </form>

            <div className="table-responsive">
                <table {...getTableProps()} className="table table-striped table-sm mb-5 mt-3">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return column.hideHeader === false ? null : (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td className='text-nowrap' {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>


            <div className='row g-3 align-items-center'>
                <div className={`col-auto d-flex align-items-center ${displayInfo}`}>
                    <p>Exibindo {pageSize < rows.length ? pageSize : rows.length} de {rows.length} registros</p>
                </div>
                <div className="col-auto ms-auto">

                    <nav>
                        <ul className="pagination justify-content-end">
                            <li className="page-item">
                                <Link onClick={() => previousPage()} className="page-link" to="#"><FontAwesomeIcon icon={['fas', 'caret-left']} /></Link>

                            </li>
                            <li className="page-item">
                                <Link onClick={() => nextPage()} className="page-link" to="#"><FontAwesomeIcon icon={['fas', 'caret-right']} /></Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>



        </>
    );
}

export default Table;