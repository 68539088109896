import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

const Sidebar = () => {
    const { token, nivel } = {
        token: localStorage.getItem('token'),
        nivel: localStorage.getItem('nivel')
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const menuItems = [
        { nome: 'Dashboard', link: '/home', icon: ['fas', 'tachometer-alt'], nivel: '1' },
        { nome: 'O.S Automotiva', link: '/listar-os', icon: ['fas', 'clipboard-list'], nivel: '1' },
        { nome: 'Orçamentos Arquitetura', link: '/listar-orcamentos', icon: ['fas', 'file-invoice-dollar'], nivel: '1' },
        { nome: 'O.S Arquitetura', link: '/listar-arquitetura', icon: ['fas', 'house'], nivel: '1' },
        { nome: 'Agendamentos', link: '/listar-agendamentos', icon: ['fas', 'calendar-alt'], nivel: '1' },
        { nome: 'Clientes', link: '/listar-clientes', icon: ['fas', 'users'], nivel: '1' },
        { nome: 'Funcionários', link: '/listar-funcionarios', icon: ['fas', 'user-tie'], nivel: '1' },
        { nome: 'Usuários', link: '/listar-usuarios', icon: ['fas', 'user'], nivel: '1' },
        { nome: 'Cargos', link: '/listar-cargos', icon: ['fas', 'briefcase'], nivel: '1' },
        { nome: 'Categorias de Produtos', link: '/listar-categorias', icon: ['fas', 'tags'], nivel: '1' },
        { nome: 'Fornecedores', link: '/listar-fornecedores', icon: ['fas', 'truck-loading'], nivel: '1' },
        { nome: 'Produtos', link: '/listar-produtos', icon: ['fas', 'box-open'], nivel: '1' },
        { nome: 'Películas', link: '/listar-peliculas', icon: ['fas', 'film'], nivel: '1' },
        { nome: 'Serviços', link: '/listar-servicos', icon: ['fas', 'wrench'], nivel: '1' },
        { nome: 'Veículo', link: '/listar-veiculos', icon: ['fas', 'car'], nivel: '1' },
        { nome: 'Caixa', link: '/listar-contas', icon: ['fas', 'cash-register'], nivel: '1' },
        { nome: 'Recebimentos', link: '/listar-pagamentos', icon: ['fas', 'hand-holding-usd'], nivel: '1' },
        { nome: 'Relatórios', link: '/relatorios', icon: ['fas', 'chart-bar'], nivel: '1' },
        { nome: 'Comissões', link: '/listar-comissoes', icon: ['fas', 'dollar-sign'], nivel: '1' },
        { nome: 'Perdas', link: '/listar-perdas', icon: ['fas', 'trash-alt'], nivel: '1' },

        { nome: 'Dashboard', link: '/home', icon: ['fas', 'tachometer-alt'], nivel: '2' },
        { nome: 'O.S Automotiva', link: '/listar-os', icon: ['fas', 'clipboard-list'], nivel: '2' },
        { nome: 'O.S Arquitetura', link: '/listar-arquitetura', icon: ['fas', 'house'], nivel: '2' },
        { nome: 'Agendamentos', link: '/listar-agendamentos', icon: ['fas', 'calendar-alt'], nivel: '2' },
        { nome: 'Clientes', link: '/listar-clientes', icon: ['fas', 'users'], nivel: '2' },
        { nome: 'Produtos', link: '/listar-produtos', icon: ['fas', 'box-open'], nivel: '2' },
        { nome: 'Películas', link: '/listar-peliculas', icon: ['fas', 'film'], nivel: '2' },
        { nome: 'Serviços', link: '/listar-servicos', icon: ['fas', 'wrench'], nivel: '2' },
        { nome: 'Veículo', link: '/listar-veiculos', icon: ['fas', 'car'], nivel: '2' },
    ];


    return (
        <div className="d-flex flex-column flex-shrink-0 ps-2 pb-2 bg-light sidebar-menu">

            <div className='d-flex flex-column align-items-center justify-content-center mb-4 container-width'>
                <img src="../logo-black.png" className="cursor-pointer w-75" />
            </div>

            <ul className="nav nav-pills flex-column mb-auto">
                {menuItems.map(item => (
                    nivel === item.nivel && (
                        <li className="nav-item nav-menu" key={item.nome}>
                            <Link to={item.link} className="nav-link active text-dark" aria-current="page">
                                <span className='menu-icone'><FontAwesomeIcon icon={item.icon} className="me-3" /></span>
                                <span className='menu-text'>{item.nome}</span>
                            </Link>
                        </li>
                    )
                ))}
            </ul>
        </div>
    );
}

export default Sidebar;
