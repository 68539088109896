import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function ListarCargos() {

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })

    const deleteRecord = async (data) => {
        try {
            const response = await Api.delete(`DeletarCargo/${data}`);
            setShowModalConfirm(false)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
            }
        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao apagar o cargo. Tente novamente mais tarde."
            })
        }

    };

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => row.id ?? '-'
                    },
                    {
                        Header: "Nome",
                        accessor: row => row.nome || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-cargo/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Excluir",
                        accessor: row =>
                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link className="fs-4" onClick={() => { setModalData({ nome: row.nome, id: row.id }); setShowModalConfirm(true); }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                            </div>
                    }
                ]
            }
        ],
        []
    );

    const [Cargos, setCargos] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarCargos');
                setCargos(response.data);
                console.log(response.data)
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [status]);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de Cargos</h1>
                    </div>
                    <div>
                        <Link to="/cadastrar-cargo" className='btn btn-primary fs-5'><FontAwesomeIcon icon="fa-solid fa-plus" /> | Novo Cargo</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={Cargos} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja deletar o registro de: <b>{modalData.nome}</b> ?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { deleteRecord(modalData.id) }}>Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => setShowModalConfirm(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>


    );

}

export default ListarCargos