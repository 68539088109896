import React from "react";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";
import CurrencyInput from "../../../Components/CurrencyInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormFields = ({
	os,
	onFieldChange,
	handleProdutoChange,
	handleRemoveProduto,
	handleAddProduto,
	alterarPrecoProduto,
	handleServicoChange,
	handleRemoveServico,
	handleAddServico,
	alterarPrecoServico }) => {


	const canAddProduto = () => {
		const ultimoProdutoSelecionado = os.produtosSelecionados[os.produtosSelecionados.length - 1];

		// Habilitar o botão se for para selecionar o primeiro produto ou se o último select não estiver vazio
		return os.produtosSelecionados.length === 0 || ultimoProdutoSelecionado.id !== "" || ultimoProdutoSelecionado.valor_venda !== "" || ultimoProdutoSelecionado.quantidade !== "";
	};

	const canAddServico = () => {
		const ultimoServicoSelecionado = os.servicosSelecionados[os.servicosSelecionados.length - 1];

		return os.servicosSelecionados.length === 0 || ultimoServicoSelecionado.id !== "" || ultimoServicoSelecionado.valor !== "";
	};

	const calculaSubtotalProduto = (idProduto) => {
		if (idProduto !== "") {
			const produto = os.produtosSelecionados.find((produto) => produto.id === idProduto);

			//o subtotal é calculado multiplicando o valor do produto pela quantidade e substraindo o desconto somente se houver
			const valorNum = parseFloat(produto.valor_venda.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));

			const subtotal = valorNum * produto.quantidade;

			if (produto.desconto !== null && produto.desconto !== undefined && produto.desconto !== "") {
				const desconto = parseFloat(produto.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));

				return (subtotal - desconto).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
			}

			return (subtotal).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		}
		return 0;
	};

	const calculaSubtotalServico = (idServico) => {
		if (idServico !== "") {
			const servico = os.servicosSelecionados.find((servico) => servico.id === idServico);

			//o subtotal é calculado multiplicando o valor do servico pela quantidade e substraindo o desconto somente se houver
			const valorNum = parseFloat(servico.valor.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));

			const subtotal = valorNum * servico.quantidade;

			if (servico.desconto !== null && servico.desconto !== undefined && servico.desconto !== "") {
				const desconto = parseFloat(servico.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));

				return (subtotal - desconto).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
			}

			return (subtotal).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		}
		return 0;
	};


	return (
		<div>
			{/* <div className="row">


				<div className="col-md-6 mb-3">
					<label className="form-label">Anexos</label>
					<input
						className="form-control"
						type="file"
						name="anexos"
						onChange={(e) => onFieldChange("anexos", e.target.files[0])}
					/>
				</div>

			</div> */}

			<hr />


			<div className="d-flex justify-content-between align-items-center mb-2">
				<h2 className="h4">Serviços</h2>

				<button className="btn btn-primary rounded-5" onClick={handleAddServico} disabled={!canAddServico()}>
					<FontAwesomeIcon icon="fa-solid fa-plus" />
				</button>
			</div>
			{os.servicosSelecionados.map((servico, index) => {
				const selectedOption = os.servicos.find((servicoOpcao) => servicoOpcao.id === servico.id);
				const nomeServicoSelecionado = selectedOption ? selectedOption.nome : "";

				// Filtrar a lista de serviços disponíveis para seleção
				const servicosDisponiveis = os.servicos.filter((servicoOpcao) => {
					return !os.servicosSelecionados.some((servicoSelecionado) => servicoSelecionado.id === servicoOpcao.id);
				});

				return (
					<div className="row bg-light mb-2 py-2" key={servico.id}>
						<div className="col-md-3 mb-3">
							<label className="form-label">Nome do Serviço</label>
							<Select
								placeholder="Selecione"
								options={servicosDisponiveis.map((servicoOpcao) => {
									const nome = servicoOpcao.nome || "";
									return {
										value: servicoOpcao.id,
										label: nome,
										servico: servicoOpcao,
									};
								})}
								value={{ value: servico.id, label: nomeServicoSelecionado }}
								onChange={(selectedOption) => {
									handleServicoChange(servico.id, 'id', selectedOption?.value, selectedOption?.servico);
									handleServicoChange(servico.id, 'quantidade', 1);
								}}
							/>
						</div>

						<div className="col-md-2 mb-3">
							<label className="form-label">Preço</label>
							<CurrencyInput
								defaultValue={servico.valor || ""}
								onChange={(event, value) =>
									alterarPrecoServico(servico.id, event)
								}
							/>
						</div>

						<div className="col-md-5 mb-3">
							<label className="form-label">Funcionário</label>
							<Select
								placeholder="Selecione"
								options={os.funcionarios
									.map((funcionario) => ({
										value: funcionario.id,
										label: funcionario.apelido || funcionario.nome || "",
									}))
									.sort((a, b) => a.label.localeCompare(b.label)) // Ordenar alfabeticamente pelo rótulo
								}
								isMulti
								onChange={(selectedOptions) => {
									const selectedFuncionarios = selectedOptions.map(option => option.value);
									handleServicoChange(servico.id, 'funcionarios', selectedFuncionarios);
								}}
							/>


						</div>

						<div className="col-md-1 mb-3">
							<label className="form-label">Quantidade</label>
							<input
								className="form-control"
								type="number"
								onChange={(e) =>
									handleServicoChange(servico.id, "quantidade", e.target.value)
								}
								defaultValue={servico.quantidade}

							/>
						</div>

						{index >= 0 && (
							<div className="col-md-1 mb-3 align-self-end">
								<button
									className="w-100 btn btn-danger btn-remove"
									onClick={() => handleRemoveServico(servico.id)}
								>
									<FontAwesomeIcon icon="fa-regular fa-trash-can" />
								</button>
							</div>
						)}

						<div className="col-md-5">
							<TextareaAutosize
								className="form-control"
								name="descricao_produto"
								minRows={1}
								maxRows={7}
								placeholder="Descrição"
								onChange={(e) =>
									handleServicoChange(servico.id, "descricao", e.target.value)
								}
							/>
						</div>

						<div className="col-md-3">
							<div className="input-group">
								<span className="input-group-text" id="basic-addon3">Desconto</span>
								<CurrencyInput
									defaultValue={servico.desconto || ""}
									onChange={(event, value) =>
										handleServicoChange(servico.id, "desconto", event)
									}
								/>
							</div>

						</div>
						<div className="col-md-4">
							<div className="input-group mb-3">
								<span className="input-group-text" id="basic-addon3">SubTotal</span>
								<input
									type="text"
									className="form-control"
									id="basic-url"
									aria-describedby="basic-addon3"
									value={'R$ ' + calculaSubtotalServico(servico.id)}
									readOnly />
							</div>
						</div>

					</div>
				);
			})}

			<hr />

			<div className="d-flex justify-content-between align-items-center mb-2">
				<h2 className="h4">Produtos</h2>

				<button className="btn btn-primary rounded-5" onClick={handleAddProduto} disabled={!canAddProduto()}>
					<FontAwesomeIcon icon="fa-solid fa-plus" />
				</button>
			</div>

			{os.produtosSelecionados.map((produto, index) => {
				const selectedOption = os.produtos.find((produtoOpcao) => produtoOpcao.id === produto.id);
				const nomeProdutoSelecionado = selectedOption ? selectedOption.nome : "";

				// Filtrar a lista de produtos disponíveis para seleção
				const produtosDisponiveis = os.produtos.filter((produtoOpcao) => {
					return !os.produtosSelecionados.some((produtoSelecionado) => produtoSelecionado.id === produtoOpcao.id);
				});

				return (
					<div className="row bg-light mb-2 py-2" key={produto.id}>

						<div className="col-md-5 mb-3">
							<label className="form-label">Nome do Produto</label>
							<Select
								placeholder="Selecione"
								options={produtosDisponiveis.map((produtoOpcao) => {
									const nome = produtoOpcao.nome || "";
									return {
										value: produtoOpcao.id,
										label: nome,
										produto: produtoOpcao,
									};
								})}
								value={{ value: produto.id, label: nomeProdutoSelecionado }}
								onChange={(selectedOption) => {
									handleProdutoChange(produto.id, 'id', selectedOption?.value, selectedOption?.produto);
									handleProdutoChange(produto.id, 'quantidade', 1);
								}}
							/>
						</div>

						<div className="col-md-3">
							<label className="form-label">Preço</label>
							<CurrencyInput
								defaultValue={produto.valor_venda || ""}
								onChange={(event, value) =>
									alterarPrecoProduto(produto.id, event)
								}
							/>
						</div>

						<div className="col-md-3">
							<label className="form-label">Quantidade</label>
							<input
								className="form-control"
								type="number"
								onChange={(e) =>
									handleProdutoChange(produto.id, "quantidade", e.target.value)
								}
								defaultValue={produto.quantidade}
							/>
						</div>

						{index >= 0 && (
							<div className="col-md-1 mb-3 align-self-end">
								<button
									className="w-100 btn btn-danger btn-remove "
									onClick={() => handleRemoveProduto(produto.id)}
								>
									<FontAwesomeIcon icon="fa-regular fa-trash-can" />
								</button>
							</div>
						)}


						<div className="col-md-5">
							<TextareaAutosize
								className="form-control"
								name="descricao_produto"
								minRows={1}
								maxRows={7}
								placeholder="Descrição"
								onChange={(e) =>
									handleProdutoChange(produto.id, "descricao", e.target.value)
								}
							/>
						</div>

						<div className="col-md-3">
							<div className="input-group">
								<span className="input-group-text" id="basic-addon3">Desconto</span>
								<CurrencyInput
									defaultValue={produto.desconto || ""}
									onChange={(event, value) =>
										handleProdutoChange(produto.id, "desconto", event)
									}
								/>
							</div>

						</div>
						<div className="col-md-4">
							<div className="input-group mb-3">
								<span className="input-group-text" id="basic-addon3">SubTotal</span>
								<input
									type="text"
									className="form-control"
									id="basic-url"
									aria-describedby="basic-addon3"
									value={'R$ ' + calculaSubtotalProduto(produto.id)}
									readOnly />
							</div>
						</div>

					</div>
				);
			})}




		</div>


	);
};


export default FormFields;
