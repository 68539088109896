import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { format, parseISO } from 'date-fns';
import printAgendamento from '../../Components/pdf-agendamento';
import DatePicker from 'react-datepicker';
import { TextareaAutosize } from '@mui/material';
import { utcToZonedTime, } from 'date-fns-tz';


function ListarAgendamentos() {

    const [Agendamentos, setAgendamentos] = useState([]);
    const [AgendamentosFiltered, setAgendamentosFiltered] = useState([]);

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })

    const [modalMode, setModalMode] = useState('');
    const [maxId, setMaxId] = useState(0);

    const [dateValue, setDateValue] = useState();


    const formatDate = (isoDate) => {
        const dateObj = new Date(isoDate);
        return format(dateObj, 'dd/MM/yyyy - HH:mm');
    };

    const deleteAgendamento = async (data) => {
        console.log(data)
        try {
            const response = await Api.delete(`DeletarAgendamento/${data}`);

            if (response.data.success === true) {
                // remover o agendamento da lista local também
                const newAgendamentos = Agendamentos.filter(agendamento => agendamento.id !== data);
                setAgendamentos(newAgendamentos);
                setAgendamentosFiltered(newAgendamentos);
                setDateValue(null);
            }
        } catch (error) {
            console.log(error)
        }

    };

    const NovoAgendamento = async (data) => {
        try {
            console.log(data)
            // Convertendo a data para o formato correto
            const timeZone = 'America/Sao_Paulo';
            const utcDate = utcToZonedTime(data.data, timeZone);

            const formattedDateTime = format(utcDate, 'yyyy-MM-dd HH:mm:ss', { timeZone }); //esse vai pro backend
            console.log("formattedDateTime", formattedDateTime);

            let horario_local = formattedDateTime.replace(' ', 'T').replace('00:00:00', data.horario + ':00.000Z');
            //deve somar 3 horas
            horario_local = new Date(horario_local);
            horario_local.setHours(horario_local.getHours() + 3);
            horario_local = horario_local.toISOString();

            console.log("horario_local?", horario_local);

            const agendamentoData = {
                ...data,
                data: formattedDateTime,
            };



            const response = await Api.post('NovoAgendamento', JSON.stringify(agendamentoData), {
                headers: { 'Content-Type': 'application/json' } // Definindo o formato como JSON para o backend receber corretamente
            });
            setMaxId(response.data.insertedId);

            setAgendamentos([...Agendamentos, { ...agendamentoData, id: response.data.insertedId, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);
            setAgendamentosFiltered([...Agendamentos, { ...agendamentoData, id: response.data.insertedId, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);

            setDateValue(null); //resetando o calendário


            console.log('Agendamento salvo com sucesso!');
        } catch (error) {
            console.log(error);
        }
    };

    const EditarAgendamento = async (data) => {
        console.log(data);
        try {
            // Convertendo a data para o formato correto
            const timeZone = 'America/Sao_Paulo';
            const utcDate = utcToZonedTime(data.data, timeZone);

            const formattedDateTime = format(utcDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });

            const agendamentoData = {
                ...data,
                data: formattedDateTime,
            };

            // console.log(formattedDateTime)
            let horario_local = formattedDateTime.split(' ')[0] + ('T' + data.horario + ':00.000Z');
            //deve somar 3 horas
            horario_local = new Date(horario_local);
            horario_local.setHours(horario_local.getHours() + 3);
            horario_local = horario_local.toISOString();

            console.log("horario_local?", horario_local);

            //atualiza o objeto local para atualizar a tabela na UI
            console.log(agendamentoData.data)

            const response = await Api.put('EditarAgendamento', JSON.stringify(agendamentoData), {
                headers: { 'Content-Type': 'application/json' } // Definindo o formato como JSON para o backend receber corretamente
            });

            setAgendamentos([...Agendamentos.filter(agendamento => agendamento.id !== data.id), { ...agendamentoData, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);
            setAgendamentosFiltered([...Agendamentos.filter(agendamento => agendamento.id !== data.id), { ...agendamentoData, titulo: agendamentoData.title, descricao: agendamentoData.descricao, data: horario_local }]);
            console.log('Agendamento salvo com sucesso!');
        } catch (error) {
            console.log(error);
        }
    };


    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState({});


    const isDateInRange = (date, startDate, endDate) =>
        (!startDate || date >= startDate) && (!endDate || date <= endDate);

    const filtrarLista = () => {
        console.log(filterData);
        const { inicio, fim } = filterData;

        const selectedStartDate = inicio ? parseISO(inicio) : null;
        const selectedEndDate = fim ? parseISO(fim) : null;

        const filteredData = Agendamentos.filter(agendamento => {
            const osStartDate = parseISO(agendamento.data);

            const isDateInRangeCondition =
                isDateInRange(osStartDate, selectedStartDate, selectedEndDate);



            return (
                isDateInRangeCondition
            );
        });

        setAgendamentosFiltered(filteredData);
    };




    const columnsAgendamento = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <Link to={`/editar-fornecedor/${row.id}`}>{row.id ?? '-'}</Link>
                    },
                    {
                        Header: "Título",
                        accessor: "titulo",
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div
                                onClick={() => {
                                    const brazilTimeZone = 'America/Sao_Paulo'; // Fuso horário do Brasil
                                    console.log(original.data)


                                    const originalDate = parseISO(original.data);

                                    const formattedTime = format(originalDate, 'HH:mm', { timeZone: brazilTimeZone });

                                    setModalData({
                                        id: original.id,
                                        title: original.titulo,
                                        data: originalDate, // Salva o objeto de data do JavaScript
                                        horario: formattedTime, // Horário formatado no padrão "HH:mm"
                                        descricao: original.descricao,
                                    });
                                    setModalMode('editar');
                                    setShowModalConfirm(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {value || "-"}
                            </div >
                        ),
                    },
                    {
                        Header: "Data",
                        accessor: row => format(new Date(row.data), 'dd/MM/yyyy - HH:mm'),
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div
                                onClick={() => {
                                    const brazilTimeZone = 'America/Sao_Paulo'; // Fuso horário do Brasil
                                    console.log(original.data)


                                    const originalDate = parseISO(original.data);

                                    const formattedTime = format(originalDate, 'HH:mm', { timeZone: brazilTimeZone });

                                    console.log(originalDate, '/ ', formattedTime)

                                    setModalData({
                                        id: original.id,
                                        title: original.titulo,
                                        data: originalDate, // Salva o objeto de data do JavaScript
                                        horario: formattedTime, // Horário formatado no padrão "HH:mm"
                                        descricao: original.descricao,
                                    });
                                    setModalMode('editar');
                                    setShowModalConfirm(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            >

                                {value ? value : '-'}
                            </div >
                        )
                    },
                    {
                        Header: "Descrição",
                        accessor: row => (
                            <div
                                onClick={() => {
                                    const brazilTimeZone = 'America/Sao_Paulo'; // Fuso horário do Brasil
                                    console.log(row.data)


                                    const originalDate = parseISO(row.data);

                                    const formattedTime = format(originalDate, 'HH:mm', { timeZone: brazilTimeZone });

                                    setModalData({
                                        id: row.id,
                                        title: row.titulo,
                                        data: originalDate, // Salva o objeto de data do JavaScript
                                        horario: formattedTime, // Horário formatado no padrão "HH:mm"
                                        descricao: row.descricao,
                                    });
                                    setModalMode('editar');
                                    setShowModalConfirm(true);
                                }}
                                style={{ cursor: 'pointer' }}
                            >

                                {row.descricao ? row.descricao.slice(0, 30) : '-'}
                                {row.descricao && row.descricao.length > 30 ? '...' : ''}
                            </div >
                        ),
                    },
                    {
                        Header: "Ações",
                        accessor: row =>
                            <>
                                <Link className="fs-4 me-3" onClick={() => { deleteAgendamento(row.id); }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                                <Link className="fs-4" onClick={() => { console.log(row); printAgendamento(row) }}>
                                    <i className="bi bi-printer-fill"></i>
                                </Link>

                            </>
                    }
                ]
            }
        ],
        [Agendamentos, AgendamentosFiltered]
    );


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarAgendamentos');
                setAgendamentos(response.data.data);
                setAgendamentosFiltered(response.data.data.sort((a, b) => new Date(a.data) - new Date(b.data)));
                console.log(response.data.data);

                //encontrar o último id
                const maxId = Math.max.apply(Math, response.data.data.map(function (o) { return o.id; }))
                setMaxId(maxId);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [status]);

    useEffect(() => {
        const today = new Date();
        const todayString = today.toISOString().split('T')[0];
        setFilterData({ inicio: todayString });
        filtrarLista();
    }, [Agendamentos])

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de Agendamentos</h1>
                    </div>
                    <div className="d-flex align-items-end">
                        <div>
                            <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>
                        </div>
                        <button
                            className='btn btn-primary' //precisa estar alinhado a direita
                            style={{ float: 'right', marginLeft: '10px' }}
                            // disabled={dateTouched ? false : true}
                            onClick={() => { setShowModalConfirm(true); setModalMode('nova') }}
                        >

                            <FontAwesomeIcon icon="fa-solid fa-plus" /> | Novo Agendamento </button>
                    </div>


                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Agendamentos</h5>
                                {/* <Link to="/listar-agendamentos" className="btn btn-primary btn-sm float-end">
                                    Ver todos
                                </Link> */}
                            </div>
                            <div className="card-body">


                                <Table columns={columnsAgendamento} data={AgendamentosFiltered} search='true' lenght='false' />


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showModalConfirm} onHide={() => { setShowModalConfirm(false); setModalMode(''); setModalData({}); }}>
                <Modal.Header closeButton>
                    {modalMode === 'nova' ? (
                        <Modal.Title>Novo Agendamento</Modal.Title>
                    ) : (
                        <Modal.Title>Editar Agendamento</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Título"
                                style={{ fontWeight: 'bold' }}
                                defaultValue={modalData.title}
                                onChange={e => setModalData({ ...modalData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-3">
                            <DatePicker
                                selected={modalData.data || null}
                                onChange={date => {
                                    setModalData({ ...modalData, data: date });
                                }}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecione a data"
                                className="form-control"
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="time"
                                defaultValue={modalData.horario || ''}
                                onChange={time => {
                                    setModalData({ ...modalData, horario: time.target.value });
                                }}
                                className="form-control"
                            />

                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <TextareaAutosize
                                type="text"
                                className="form-control"
                                placeholder="Descrição"
                                defaultValue={modalData.descricao}
                                maxLength={1000}
                                onChange={e => setModalData({ ...modalData, descricao: e.target.value })}
                            />
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    {modalMode == "nova" ? (
                        <button className="btn btn-primary" onClick={() => { NovoAgendamento(modalData); setModalData({}); setShowModalConfirm(false); setModalMode('') }}
                            disabled={modalData.title && modalData.data && modalData.horario && modalData.descricao ? false : true}
                        >Confirmar</button>
                    ) : (
                        <button className="btn btn-primary" onClick={() => { EditarAgendamento(modalData); setModalData({}); setShowModalConfirm(false); setModalMode('') }}>Editar</button>
                    )}
                    <button className="btn btn-secondary" onClick={() => { console.log(modalData); setModalData({}); setShowModalConfirm(false); setModalMode('') }}>Cancelar</button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })

                                        // console.log(filterData)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })

                                    }}
                                />
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista() }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setAgendamentosFiltered(Agendamentos); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>
            </Modal >

        </>





    );

}

export default ListarAgendamentos