import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { set, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Api from '../../Api';
import Table from '../../Components/Table'
import { parse, isValid, format, parseISO, addMonths, subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const PeliculasLista = () => {
    const [status, setStatus] = useState({ success: false, message: '' })



    const [produtos, setProdutos] = useState([]);
    const [peliculasData, setPeliculasData] = useState([]); //objeto com compras e vendas
    const [resetTable, setResetTable] = useState(); //estado inicial da tabela
    const [dataShow, setDataShow] = useState([]);



    const [filterData, setFilterData] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);

    const filtrarLista = () => {
        const { inicio, fim } = filterData;
        // Filtrar registros dentro do range
        const registrosFiltrados = {
            compras: [],
            vendas: []
        };

        // console.log("filtrando", peliculasData)
        peliculasData.compras.forEach((compra) => {
            const dataCompra = new Date(compra.data);
            dataCompra.setDate(dataCompra.getDate() + 1);  // Adiciona um dia para compensar o fuso horário
            const dataCompraFormatada = format(dataCompra, 'yyyy-MM-dd');
            // console.log(`filtrando compra do produto ${compra.produto} do dia ${dataCompraFormatada}. foi entre ${inicio} e ${fim}?`);
            if ((!inicio || dataCompraFormatada >= inicio) && (!fim || dataCompraFormatada <= fim)) {
                // console.log("sim")
                registrosFiltrados.compras.push(compra);
            }
        });

        peliculasData.vendas.forEach((venda) => {
            const dataVenda = new Date(venda.data_fim); // Supondo que cada venda tenha uma propriedade "data"
            const dataVendaFormatada = format(dataVenda, 'yyyy-MM-dd');
            // console.log(`filtrando venda do produto ${venda.id_material} do dia ${dataVendaFormatada}. foi entre ${inicio} e ${fim}?`)
            if ((!inicio || dataVendaFormatada >= inicio) && (!fim || dataVendaFormatada <= fim)) {
                // console.log('sim')
                registrosFiltrados.vendas.push(venda);
            }
        });

        const registros = calcularQuantidadeTotal(registrosFiltrados);


        if (filterData.id) { //filtro por ID é absoluto (só pode ter um ID por vez)
            const registrosFiltradosPorId = registros.filter((registro) => registro.id_produto == filterData.id);
            setDataShow(registrosFiltradosPorId);
            return;
        }

        setDataShow(registros); //só a data

    }



    const getNome = (id, array) => {
        const item = array.find(item => item.id == id);
        // console.log(id)
        return item.nome;
    }

    function calcularQuantidadeTotal(comprasEvendas) {
        // Criar um objeto para armazenar a quantidade total de compras e vendas por id_produto
        const quantidadeTotalPorId = {};

        // Iterar sobre as compras e calcular a quantidade total para cada id_produto
        comprasEvendas.compras.forEach((item) => {
            const idProduto = item.produto;
            const quantidade = parseFloat(item.quantidade);

            if (!quantidadeTotalPorId[idProduto]) {
                quantidadeTotalPorId[idProduto] = { compras: 0, vendas: 0 };
            }

            quantidadeTotalPorId[idProduto].compras += quantidade;
        });

        // Iterar sobre as vendas e calcular a quantidade total para cada id_produto
        comprasEvendas.vendas.forEach((item) => {
            const idProduto = item.id_material;
            const quantidade = parseFloat(item.quantidade);

            if (!quantidadeTotalPorId[idProduto]) {
                quantidadeTotalPorId[idProduto] = { compras: 0, vendas: 0 };
            }

            quantidadeTotalPorId[idProduto].vendas += quantidade;
        });

        // Criar uma lista de registros com a quantidade total para cada id_produto
        const registros = Object.keys(quantidadeTotalPorId).map((idProduto) => {
            return {
                id_produto: idProduto,
                quantidade_compras: quantidadeTotalPorId[idProduto].compras,
                quantidade_vendas: quantidadeTotalPorId[idProduto].vendas
            };
        });

        return registros;
    }

    useEffect(() => {
        const getData = async () => {
            const relatoriosResponse = await Api.get('/Relatorios');
            setProdutos(relatoriosResponse.data.data.produtos);
            const comprasEvendas = relatoriosResponse.data.data.peliculas;
            setPeliculasData(comprasEvendas);

            const registros = calcularQuantidadeTotal(comprasEvendas);
            console.log(registros)

            setDataShow(registros);
            setResetTable(registros);
        }
        getData();
    }, []);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });

        //verificar se peliculasData já foi carregado
        if (Object.keys(peliculasData).length > 0) {
            filtrarLista();
        }


    }, [peliculasData]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'idOS',
                columns: [
                    {
                        Header: "#",
                        accessor: row => row.id_produto ?? '-'
                    },
                    {
                        Header: "Película",
                        accessor: row => getNome(row.id_produto, produtos) ?? '-'
                    },
                    {
                        Header: "Vendas",
                        accessor: row => row.quantidade_vendas.toFixed(2) ?? '-'
                    },
                    {
                        Header: "Compras",
                        accessor: row => row.quantidade_compras ?? '-'
                    },
                ]
            }
        ],
        [dataShow]
    );



    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Relatório de Películas</h1>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={dataShow} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-label">ID</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="id"
                                    placeholder='#'
                                    min={0}
                                    value={filterData.id || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            id: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista(); setShowFilterModal(false) }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setDataShow(resetTable); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>

            </Modal>
        </>
    );
}

export default PeliculasLista;
