import React, { useState, useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from '../../Api';
import { Modal } from 'react-bootstrap';

import CurrencyInput from '../../Components/CurrencyInput';
import TextareaAutosize from "react-textarea-autosize";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { IMaskInput } from "react-imask";
import axios from 'axios';
import Certificado from './pdf';

function NovoOrcamento() {
    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })
    const [endereco, setEndereco] = useState({ cep: '', endereco: '', bairro: '', cidade: '', complemento: '' })

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});

    const [buttonClicked, setButtonClicked] = useState(false);
    const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
    const handleButtonClick = async () => {
        setButtonClicked(true);

        try {
            const response = await Api.post("EnviarEmail", modalData, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data.success) {
                setIsRequestSuccessful(true);
            }
            console.log('Email enviado: ', response);

        } catch (error) {
            setIsRequestSuccessful(false);
        } finally {
            // Reset the button state after the request is complete
            setTimeout(() => {
                setButtonClicked(false);
                setIsRequestSuccessful(false);
            }, 3000); // Reset the button state after 5 seconds (adjust as needed)
        }
    };

    const [showModalCliente, setShowModalCliente] = useState(false);
    const [NovoClienteAdicionado, setNovoClienteAdicionado] = useState(false);

    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }
    const [os, setOs] = useState({
        vidros: [],
        informacoesBasicas: {
            id_cliente: null,
            id_funcionario: null,
            data_inicio: null,
            observacao: null,
            valor_total: null,
            desconto: null,
            acrescimo: null,
        },
    });

    const telefone = useRef(null);
    const celular = useRef(null);
    const contato = useRef(null);
    const cep = useRef(null);

    const handleTelefoneChange = (event) => {
        setValue('telefone', event.target.value)
    }
    const handleCelularChange = (event) => {
        setValue('celular', event.target.value)
    }
    const handleContatoChange = (event) => {
        setValue('contato', event.target.value)
    }
    const handleTelefoneFocus = (e) => {
        const input = telefone.current;
        if (input) {
            input.setSelectionRange(4, 4);
        }
    };
    const handleCelularFocus = (e) => {
        const input = celular.current;
        if (input) {
            input.setSelectionRange(4, 4);
        }
    };
    const handleContatoFocus = (e) => {
        const input = contato.current;
        if (input) {
            input.setSelectionRange(4, 4);
        }
    };
    const handleCepChange = async (event) => {
        const cepValue = event.target.value.replace(/\D/g, '')
        if (cepValue.length !== 8) {
            return;
        }

        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`)
            setEndereco({
                cep: response.data.cep,
                endereco: response.data.logradouro,
                bairro: response.data.bairro,
                cidade: response.data.localidade
            });
            // Atualiza os valores
            setValue('cidade', response.data.localidade);
            setValue('bairro', response.data.bairro);
            setValue('endereco', response.data.logradouro);
        } catch (error) {
            console.log(error)
        }

    };
    const handleAddVidro = () => {
        const novoVidro = {
            id: Date.now(), // Usaremos um timestamp como ID único
            altura: '',
            largura: '',
            quantidade: '',
            local_aplicacao: '',
            id_pelicula: '',
        };

        const updatedVidros = [...os.vidros, novoVidro];
        setOs({ ...os, vidros: updatedVidros });
    };

    const handleRemoveVidro = (id) => {
        const updatedVidros = os.vidros.filter((vidro) => vidro.id !== id);
        setOs({ ...os, vidros: updatedVidros });
    };

    const handleVidroChange = (id, fieldName, value) => {
        const updatedVidros = os.vidros.map((vidro) => {
            if (vidro.id === id) {
                return {
                    ...vidro,
                    [fieldName]: value,
                };
            }
            return vidro;
        });

        setOs({ ...os, vidros: updatedVidros });
    };

    const calculaSubtotalVidro = (idVidro) => {
        // Verifique se o vidro com o ID especificado existe em os.peliculas
        const vidro = os.peliculas.find((vidro) => vidro.id == os.vidros.find((vidro) => vidro.id == idVidro).id_pelicula);

        if (vidro) {
            //o vidro.valor_venda deve multiplicar pela quantidade altura e largura do idVidro
            const subtotal = vidro.valor_venda * os.vidros.find((vidro) => vidro.id == idVidro).quantidade.replace(",", ".") * os.vidros.find((vidro) => vidro.id == idVidro).altura.replace(",", ".") * os.vidros.find((vidro) => vidro.id == idVidro).largura.replace(",", ".");

            console.log(subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'));
            return subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        }

        return 0;
    };



    useEffect(() => { //buscando informações dos clientes e funcionários
        const fetchPeliculas = async () => {
            try {
                const response = await Api.get("ListarProdutos?modo=OS")
                const filter = await response.data.filter((produto) => produto.tipo === "material")
                setOs((prevOs) => ({
                    ...prevOs,
                    peliculas: filter,
                }));
            } catch (error) {
                console.log(error);
            }

            try {
                const funcionariosResponse = await Api.get("ListarFuncionarios");
                setOs((prevOs) => ({
                    ...prevOs,
                    funcionarios: funcionariosResponse.data,
                }));
            } catch (error) {
                console.log(error);
            }

            try {
                const clientesResponse = await Api.get("ListarClientes");
                setOs((prevOs) => ({
                    ...prevOs,
                    clientes: clientesResponse.data,
                }));
            } catch (error) {
                console.log(error);
            }
        }
        fetchPeliculas();
        console.log(os)

    }, []);

    //informacoes basicas
    const handleFieldChange = (fieldName, value) => { //interface para a classe OS
        setOs((prevOs) => ({
            ...prevOs,
            informacoesBasicas: {
                ...prevOs.informacoesBasicas,
                [fieldName]: value,
            },
        }));
    };


    const handleClienteChange = (selectedOption) => {
        handleFieldChange("id_cliente", selectedOption.value);
    };

    const handleFuncionarioChange = (selectedOption) => {
        handleFieldChange("id_funcionario", selectedOption.value);
    };

    const submitOS = async () => {
        if ((watch('nome_contato') == null || watch('nome_contato') == "" || watch('nome_contato') == undefined) && !os.informacoesBasicas) {
            setStatus({
                success: false,
                message: "Selecione ou adicione um nome de contato"
            })
        }
        // console.log(cepValue)
        if (watch('nome_contato') != null && watch('nome_contato') != "" && watch('nome_contato') != undefined) {
            const novoCliente = {
                nome_contato: watch('nome_contato'),
                telefone: watch('telefone').length < 6 ? null : watch('telefone'),
                celular: watch('celular').length < 6 ? null : watch('celular'),
                contato: watch('contato').length < 6 ? null : watch('contato'),
                email: watch('email'),
                email_profissional: watch('email_profissional'),
                cep: cepValue,
                cidade: watch('cidade'),
                endereco: watch('endereco'),
                numero: watch('numero'),
                bairro: watch('bairro'),
                complemento: watch('complemento'),
                quadra: watch('quadra'),
                lote: watch('lote'),
                sala: watch('sala'),
                apto: watch('apto'),
            }
            os.novoCliente = novoCliente;
        }
        // console.log(os)


        window.scrollTo(0, 0);

        // //se tiver algum campo de quantidade nulo ou indefinido, deve enviar alerta e não permitir o envio da OS
        if (os.vidros.some((vidro) => !vidro.quantidade)) {
            alert("Preencha a quantidade de todos os produtos ou serviços selecionados");
            return;
        }

        const data = {
            informacoesBasicas: os.informacoesBasicas,
            vidros: os.vidros,
            aplicadores: os.aplicadores,
            novoCliente: os.novoCliente,
            funcionarios: os.funcionarios,
            clientes: os.clientes,
            peliculas: os.peliculas,
        };

        const calcularValorTotal = () => {

            let acrescimo = 0; //setar para watch('acrescimo') somente se for numérico, nao nulo, nao indefinido e nao vazio
            if (watch('acrescimo') !== undefined && watch('acrescimo') !== null && watch('acrescimo') !== '') {
                acrescimo = parseFloat(watch('acrescimo'));
            }


            const valorPeliculas = calcularValorPeliculas();
            const valorTotal = parseFloat(valorPeliculas) + acrescimo;


            return valorTotal.toFixed(2);
        };

        const total = calcularValorTotal();
        data.informacoesBasicas.valor_total = total;



        try {
            const response = await Api.post("NovaArquitetura", data, {
                headers: { 'Content-Type': 'application/json' }
            });

            data.informacoesBasicas.idOS = response.data.insertID;

            console.log(response)

            setStatus({
                success: true,
                message: "Orçamento cadastrado com sucesso."
            })
        } catch (error) {
            setStatus({
                success: false,
                message: error.response.data.error == 'Quantidade não informada' ? "Preencha a quantidade das películas selecionadas" : "Erro ao cadastrar Orçamento."
            })
            console.log(error);
            return;
        }





        let email = null;
        if (os.novoCliente) {
            email = os.novoCliente.email || os.novoCliente.email_profissional || null;
        } else {
            email = os.clientes.find((cliente) => cliente.id == os.informacoesBasicas.id_cliente).email || os.clientes.find((cliente) => cliente.id == os.informacoesBasicas.id_cliente).email_profissional || null;
        }
        setModalData({
            informacoesBasicas: os.informacoesBasicas,
            vidros: os.vidros,
            aplicadores: os.aplicadores,
            novoCliente: os.novoCliente,
            funcionarios: os.funcionarios,
            clientes: os.clientes,
            peliculas: os.peliculas,
            email: email,
        })
        setShowModalConfirm(true);



    }




    useEffect(() => {
        setValue('telefone', '16 ')
        setValue('celular', '16 ')
        setValue('contato', '16 ')

    }, [setValue])

    const [cepValue, setCepValue] = useState('');
    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }

    const calculaValor_final = () => {
        const subtotal = calcularValorTotal();
        let desconto = 0;

        if (os.informacoesBasicas && os.informacoesBasicas.desconto != undefined) {
            if (os.informacoesBasicas.desconto !== undefined && os.informacoesBasicas.desconto !== null && os.informacoesBasicas.desconto !== '') { //se tiver um desconto
                desconto = parseFloat(os.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                return (subtotal - desconto).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            }
        }

        return subtotal.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    const calcularValorTotal = () => {

        let acrescimo = 0; //setar para watch('acrescimo') somente se for numérico, nao nulo, nao indefinido e nao vazio
        if (watch('acrescimo') !== undefined && watch('acrescimo') !== null && watch('acrescimo') !== '') {
            acrescimo = parseFloat(watch('acrescimo'));
        }

        const valorPeliculas = calcularValorPeliculas();
        const valorTotal = parseFloat(valorPeliculas) + acrescimo;
        console.log(valorTotal)

        // console.log(valorProdutos);

        return valorTotal.toFixed(2);
    };

    const calcularValorPeliculas = () => {
        console.log(os)
        //para cada os.vidros pegar o valor de altura, largura e quantidade e multiplicar pelo preço obtido do id_pelicula no objeto peliculas
        let valorTotal = 0;
        //se existir uma os.vidros e não for vazio
        if (os.vidros && os.vidros.length > 0) {
            os.vidros.forEach((vidro) => {
                //se tiver altura largura e quantidade
                if (vidro.altura !== undefined && vidro.altura !== null && vidro.altura !== '' &&
                    vidro.largura !== undefined && vidro.largura !== null && vidro.largura !== '' &&
                    vidro.quantidade !== undefined && vidro.quantidade !== null && vidro.quantidade !== '' &&
                    vidro.id_pelicula !== undefined && vidro.id_pelicula !== null && vidro.id_pelicula !== '') {

                    const valor_m_vidro = os.peliculas.find((pelicula) => pelicula.id == vidro.id_pelicula).valor_venda;
                    const valor = parseFloat(vidro.altura.replace(",", ".")) * parseFloat(vidro.largura.replace(",", ".")) * parseFloat(vidro.quantidade.replace(",", ".")) * parseFloat(valor_m_vidro);
                    valorTotal += valor;
                }
            });
            return valorTotal.toFixed(2);
        }
        else {
            return 0.00;
        }

    };


    return (
        <div className='page-full'>
            <form className='bg-white'>

                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-1 d-none d-xl-block'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
                            </span>
                        </div>
                        <div className="col-xl-8 col-12 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Orçamento Arquitetura</h1>
                            <div className="mb-3">
                                {os.funcionarios && os.clientes ?
                                    <div>
                                        {status.message && (
                                            <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                <div className="alert-icon">
                                                    <i className="far fa-fw fa-bell"></i>
                                                </div>
                                                <div className="alert-message">
                                                    {status.message}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">

                                            <div className="col-xl-4 col-10 mb-3">
                                                <label className="form-label">Nome do Cliente</label>
                                                <Select
                                                    placeholder="Selecione"
                                                    options={os.clientes.map((cliente) => {
                                                        const nome = cliente.nome || cliente.nome_fantasia || "Nome não disponível";
                                                        return {
                                                            value: cliente.id,
                                                            label: `${nome}`,
                                                        };
                                                    }).sort((a, b) => a.label.localeCompare(b.label))}
                                                    onChange={handleClienteChange}
                                                    value={NovoClienteAdicionado ? { label: watch("nome_contato") || undefined } : undefined}
                                                    isDisabled={NovoClienteAdicionado}
                                                />
                                            </div>

                                            <div className='col-xl-1 col-2 mb-3'>
                                                <label className="form-label">&nbsp;</label>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary fw-bold w-100 py-2"
                                                    onClick={() => setShowModalCliente(true)}
                                                    disabled={os.informacoesBasicas ? os.informacoesBasicas.id_cliente != null : false}
                                                >
                                                    <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                </button>
                                            </div>


                                            <div className="col-xl-4 col-6 mb-3">
                                                <label className="form-label">Nome do Vendedor</label>
                                                <Select
                                                    placeholder="Selecione"
                                                    options={os.funcionarios
                                                        .map((funcionario) => {
                                                            const nome = funcionario.apelido || funcionario.nome;
                                                            return {
                                                                value: funcionario.id,
                                                                label: `${nome}`,
                                                            };
                                                        })
                                                        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar alfabeticamente pelo rótulo
                                                    }
                                                    onChange={handleFuncionarioChange}
                                                    isClearable
                                                />

                                            </div>

                                            <div className="col-xl-3 col-6 mb-3">
                                                <label className="form-label">Data de Início</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    name="data_inicio"
                                                    onChange={(e) => handleFieldChange("data_inicio", e.target.value)}
                                                />
                                            </div>

                                        </div>
                                    </div> : null}
                                <hr />

                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h2 className="h4">Vidros</h2>

                                    <span className="btn btn-primary rounded-5" onClick={handleAddVidro}>
                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                    </span>
                                </div>
                                {os.vidros.map((vidro) => (
                                    <div className="row bg-light mb-2 py-2" key={vidro.id}>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Altura</label>
                                            <input
                                                type="number" step="any" min="0"
                                                className="form-control"
                                                name={`altura_${vidro.id}`}
                                                value={vidro.altura}
                                                onChange={(e) => handleVidroChange(vidro.id, 'altura', e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Largura</label>
                                            <input
                                                type="number" step="any" min="0"
                                                className="form-control"
                                                name={`largura_${vidro.id}`}
                                                value={vidro.largura}
                                                onChange={(e) => handleVidroChange(vidro.id, 'largura', e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label className="form-label">Quantidade</label>
                                            <input
                                                type="number" min="0"
                                                className="form-control"
                                                name={`quantidade_${vidro.id}`}
                                                value={vidro.quantidade}
                                                onChange={(e) => handleVidroChange(vidro.id, 'quantidade', e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Pelicula</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={os.peliculas.map((pelicula) => {
                                                    return {
                                                        value: pelicula.id,
                                                        label: `${pelicula.nome}`,
                                                    };
                                                }
                                                )}
                                                onChange={(selectedOption) => handleVidroChange(vidro.id, 'id_pelicula', selectedOption.value)}
                                            />
                                        </div>
                                        <div className="col-md-8 mb-3">
                                            <label className="form-label">Local de Aplicação</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={`local_aplicacao_${vidro.id}`}
                                                value={vidro.local_aplicacao}
                                                onChange={(e) => handleVidroChange(vidro.id, 'local_aplicacao', e.target.value)}
                                            />
                                        </div>


                                        <div className="col-md-3 mb-3">
                                            <label className="form-label">Valor</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="basic-url"
                                                aria-describedby="basic-addon3"
                                                value={'R$ ' + calculaSubtotalVidro(vidro.id)}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-1 mb-3 align-self-end">
                                            <button
                                                className="btn btn-danger btn-remove"
                                                onClick={() => handleRemoveVidro(vidro.id)}
                                            >
                                                <FontAwesomeIcon icon="fa-regular fa-trash-can" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <hr />
                                {os.funcionarios && os.clientes ?
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Aplicador(es)</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={os.funcionarios
                                                    .map((funcionario) => {
                                                        const nome = funcionario.apelido || funcionario.nome;
                                                        return {
                                                            value: funcionario.id,
                                                            label: `${nome}`,
                                                        };
                                                    }
                                                    )
                                                    .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar alfabeticamente pelo rótulo
                                                }
                                                isMulti
                                                onChange={(selectedOption) => handleFieldChange("aplicadores", selectedOption.map((option) => option.value))}
                                            />
                                        </div>
                                    </div> : null}
                                <hr />

                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Observações</label>
                                        <TextareaAutosize
                                            className="form-control"
                                            minRows={1}
                                            onChange={(e) => handleFieldChange("observacao", e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row justify-content-end">
                                    <div className="col-md-3 mb-3 mt-4">
                                        <label>Desconto</label>
                                        <div className='input-group'>
                                            <CurrencyInput
                                                defaultValue={""}
                                                onChange={(event, value) =>
                                                    handleFieldChange('desconto', event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3 mt-4">
                                        <label>Acréscimo</label>
                                        <div className='input-group'>
                                            <CurrencyInput
                                                defaultValue={""}
                                                onChange={(event, value) => {
                                                    setValue('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                    handleFieldChange('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                    console.log(event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 mt-4">
                                        <label>Subtotal</label>
                                        <div className='input-group'>
                                            <span className="input-group-text">R$</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={Number(calcularValorTotal()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }).replace("R$", "")}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-md-4 mb-3">
                                        <label><b>Total</b></label>
                                        <div className='input-group'>
                                            <span className="input-group-text">R$</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={calculaValor_final()}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button type="button" className="btn btn-light button-large fw-bold" onClick={() => submitOS()}> CADASTRAR ORÇAMENTO </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>

                <Modal size='lg' centered show={showModalCliente} onHide={() => { setShowModalCliente(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Informações do contato</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Nome Contato</label>
                                <input type="text text-uppercase" onKeyUp={handleKeyUp} className="form-control" {...register("nome_contato")} />
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="form-label">Telefone</label>
                                <IMaskInput mask="(00) 0000-0000" type="text" inputRef={telefone} onChange={handleTelefoneChange} onFocus={handleTelefoneFocus} className="form-control" value={watch('telefone')} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="form-label">Celular</label>
                                <IMaskInput mask="(00) 00000-0000" type="text" inputRef={celular} onChange={handleCelularChange} onFocus={handleCelularFocus} className="form-control" value={watch('celular')} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="form-label">Telefone Contato</label>
                                <IMaskInput mask="(00) 00000-0000" type="text" inputRef={contato} onChange={handleContatoChange} onFocus={handleContatoFocus} className="form-control" value={watch('contato')} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="form-label">Cep</label>
                                <IMaskInput mask="00.000-000" type="text" inputRef={cep} onChange={(e) => { setCepValue(e.target.value) }} className="form-control" onBlur={handleCepChange} value={cepValue} />
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="form-label">Cidade</label>
                                <input type="text" className="form-control" {...register("cidade")} value={endereco.cidade} onChange={(event) => setEndereco({ ...endereco, cidade: event.target.value })} />
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="form-label">Endereço</label>
                                <input type="text" className="form-control" {...register("endereco")} value={endereco.endereco} onChange={(event) => setEndereco({ ...endereco, endereco: event.target.value })} />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label className="form-label">Número</label>
                                <input type="text" className="form-control" {...register("numero")} value={endereco.numero} />
                            </div>
                            <div className="col-md-3 mb-3">
                                <label className="form-label">Bairro</label>
                                <input type="text" className="form-control" {...register("bairro")} value={endereco.bairro} onChange={(event) => setEndereco({ ...endereco, bairro: event.target.value })} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">Complemento</label>
                                <input type="text" className="form-control" {...register("complemento")} value={endereco.complemento} onChange={(event) => setEndereco({ ...endereco, complemento: event.target.value })} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label className="form-label">Quadra</label>
                                <input type="text" className="form-control" {...register("quadra")} onKeyUp={handleKeyUp} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="form-label">Lote</label>
                                <input type="text" className="form-control" {...register("lote")} />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label className="form-label">Sala</label>
                                <input type="text" className="form-control" {...register("sala")} />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label className="form-label">Apto.</label>
                                <input type="text" className="form-control" {...register("apto")} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">E-mail</label>
                                <input type="email" className="form-control" {...register("email")} value={watch('email')} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label">E-mail profissional</label>
                                <input type="email" className="form-control" {...register("email_profissional")} />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => {
                            setShowModalCliente(false); setNovoClienteAdicionado(true); console.log(watch("cpf"))
                        }}>Salvar</button>
                        <button className="btn btn-secondary" onClick={() => { setShowModalCliente(false); }}>Fechar</button>
                    </Modal.Footer>
                </Modal>

                <Modal centered show={showModalConfirm} onHide={() => { setShowModalConfirm(false); { window.location.href = "/listar-arquitetura" } }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="form-label">Orçamento criado com sucesso, deseja gerar o PDF ou enviar por email ?</label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => { Certificado(modalData); setShowModalConfirm(true); window.location.href = "/listar-arquitetura" }}>Gerar PDF</button>
                        {modalData.email && <button className={`btn ${isRequestSuccessful ? 'btn-success disabled' : buttonClicked ? 'btn-primary disabled' : 'btn-primary'}`} onClick={handleButtonClick}>{buttonClicked ? (isRequestSuccessful ? 'Enviado' : 'Enviando...') : 'Enviar Email'}</button>}
                        <button className="btn btn-secondary" onClick={() => { setShowModalConfirm(false); window.location.href = "/listar-arquitetura" }}>Concluir</button>
                    </Modal.Footer>
                </Modal>
            </form>
        </div >
    )
}

export default NovoOrcamento;