import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { set, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Api from '../../Api';
import Table from '../../Components/Table'
import { parse, isValid, format, parseISO, addMonths, subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ProdutosLista = () => {
    const [status, setStatus] = useState({ success: false, message: '' })


    const [produtos, setProdutos] = useState([]);
    const [produtosVendidos, setProdutosVendidos] = useState();
    const [produtosShow, setProdutosShow] = useState([]);
    const [todasVendas, setTodasVendas] = useState();

    const [compras, setCompras] = useState();


    const [OS, setOS] = useState();

    const [filterData, setFilterData] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);


    const filtrarLista = () => {
        if (filterData.id) {
            console.log('filtrando id')
            setProdutosShow(produtosVendidos.filter(item => item.id_produto == filterData.id));
            return;
        }


        if (filterData.inicio || filterData.fim) {
            let dataInicio = filterData.inicio ? new Date(filterData.inicio) : null;
            let dataFim = filterData.fim ? new Date(filterData.fim) : null;

            // Se dataFim estiver presente, ajusta para incluir o dia seguinte
            if (dataFim) {
                dataFim.setDate(dataFim.getDate() + 1);
            }

            const produtosVendidosAgrupados = {};
            todasVendas.forEach(item => {
                const data = new Date(item.data);
                const isDataValida = (!dataInicio || data >= dataInicio) && (!dataFim || data <= dataFim);

                if (isDataValida) {
                    const id_produto = item.id_produto;
                    const qtd_vendida = parseInt(item.quantidade);

                    if (produtosVendidosAgrupados[id_produto]) {
                        produtosVendidosAgrupados[id_produto].qtd_vendida += qtd_vendida;
                    } else {
                        produtosVendidosAgrupados[id_produto] = {
                            id_produto: id_produto,
                            nome_produto: getNome(id_produto, produtos),
                            qtd_vendida: qtd_vendida,
                            qtd_comprada: 0  // Inicializamos com 0, será recalculado
                        };
                    }
                }
            });

            // Recalcular qtd_comprada com base nas compras no intervalo de datas
            compras.forEach(compra => {
                const compraData = new Date(compra.data);
                const id_produto = parseInt(compra.produto);

                // Verifica se o produto tem o tipo 'unit'
                const produto = produtos.find(item => item.id === id_produto);
                const tipoUnit = produto && produto.tipo === 'unit';

                if ((!dataInicio || compraData >= dataInicio) && (!dataFim || compraData <= dataFim) && tipoUnit) {
                    if (!produtosVendidosAgrupados[id_produto]) {
                        // Se o produto não foi incluído devido ao filtro de vendas, adiciona com qtd_vendida 0
                        produtosVendidosAgrupados[id_produto] = {
                            id_produto: id_produto,
                            nome_produto: getNome(id_produto, produtos),
                            qtd_vendida: 0,
                            qtd_comprada: parseInt(compra.quantidade)
                        };
                    } else {
                        console.log('produto: ', id_produto, 'qtd_comprada: ', parseInt(compra.quantidade), 'OS');
                        produtosVendidosAgrupados[id_produto].qtd_comprada += parseInt(compra.quantidade);
                    }
                }
            });

            // Convertendo o objeto agrupado em um array
            const produtosVendidosArray = Object.values(produtosVendidosAgrupados);

            // Exibindo o array de produtos vendidos
            console.log(produtosVendidosArray);
            setProdutosShow(produtosVendidosArray);
        }



    }



    const getNome = (id, array) => {
        console.log(id, array)
        const item = array.find(item => item.id == id);
        return item.nome;
    }

    const calculaCompra = (id, array) => {
        const item = array.filter(item => item.produto == id);
        let total = 0;
        item.forEach(element => {
            total += parseInt(element.quantidade);
        });
        return total;
    }

    useEffect(() => {
        const getData = async () => {
            const relatoriosResponse = await Api.get('/Relatorios');
            const produtosVendidos = relatoriosResponse.data.data.produtosVendidos;
            setTodasVendas(produtosVendidos);
            const compras = relatoriosResponse.data.data.compras;
            const OS = relatoriosResponse.data.data.OS;
            const produtos = relatoriosResponse.data.data.produtos;
            setProdutos(produtos);
            setCompras(compras);
            setOS(OS);

            const produtosVendidosAgrupados = {};

            produtosVendidos.forEach(item => {
                const id_produto = item.id_produto;
                const qtd_vendida = parseInt(item.quantidade);

                if (produtosVendidosAgrupados[id_produto]) {
                    produtosVendidosAgrupados[id_produto].qtd_vendida += qtd_vendida;
                } else {
                    produtosVendidosAgrupados[id_produto] = {
                        id_produto: id_produto,
                        nome_produto: getNome(id_produto, relatoriosResponse.data.data.produtos),
                        qtd_vendida: qtd_vendida,
                        qtd_comprada: calculaCompra(id_produto, compras)
                    };
                }
            });

            compras.forEach(item => {
                const id_produto = item.produto;
                const qtd_comprada = parseInt(item.quantidade);

                if (!produtosVendidosAgrupados[id_produto]) {
                    if (produtos.find(item => item.id == id_produto).tipo == "unit") {
                        produtosVendidosAgrupados[id_produto] = {
                            id_produto: id_produto,
                            nome_produto: getNome(id_produto, produtos),
                            qtd_vendida: 0,
                            qtd_comprada: qtd_comprada
                        };
                        console.log("produto comprado mas não vendido")
                    }
                }
            });

            const produtosVendidosArray = Object.values(produtosVendidosAgrupados); //formatação da lista
            console.log(produtosVendidosArray)

            setProdutosVendidos(produtosVendidosArray);
            setProdutosShow(produtosVendidosArray);

        }
        getData();
    }, []);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });
        filtrarLista();
    }, [produtos]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'idOS',
                columns: [
                    {
                        Header: "#",
                        accessor: row => row.id_produto ?? '-'
                    },
                    {
                        Header: "Produto",
                        accessor: row => row.nome_produto ?? '-'
                    },
                    {
                        Header: "Vendas",
                        accessor: row => row.qtd_vendida ?? '-'
                    },
                    {
                        Header: "Compras",
                        accessor: row => row.qtd_comprada ?? '-'
                    },
                ]
            }
        ],
        [produtosShow]
    );



    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Relatório de Produtos</h1>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={produtosShow} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-label">ID</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="id"
                                    placeholder='#'
                                    min={0}
                                    value={filterData.id || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            id: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista(); setShowFilterModal(false) }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setProdutosShow(produtosVendidos); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>

            </Modal>
        </>
    );
}

export default ProdutosLista;
