import React, { useState, useEffect } from "react";

function CurrencyInput({ defaultValue, onChange, ...props }) {
  const [formattedValue, setFormattedValue] = useState(defaultValue);

  useEffect(() => {
    const inputValue = defaultValue.replace(/[^\d]/g, "");
    let newValue = "";

    if (inputValue.length > 0) {
      newValue = `R$ ${(parseFloat(inputValue) / 100).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }

    setFormattedValue(newValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/[^\d]/g, "");
    let newValue = "";

    if (inputValue.length > 0) {
      newValue = `R$ ${(parseFloat(inputValue) / 100).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }

    setFormattedValue(newValue);
    onChange(newValue);
  };

  return (
    <input
      className="form-control"
      type="text"
      {...props}
      value={formattedValue}
      onChange={handleChange}
      placeholder="R$ 0,00"
    />
  );
}


export default CurrencyInput;
