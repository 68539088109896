import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import TextareaAutosize from 'react-textarea-autosize';
import CurrencyInput from '../../Components/CurrencyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';


function EditarServico() {
    const { id } = useParams(); // Obtém o id do produto a partir da URL
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });

    const [comissoes, setComissoes] = useState([]);
    useEffect(() => {
        const buscarServico = async () => {
            try {
                const response = await Api.get(`BuscarServico?id=${id}`);
                // Define os valores iniciais dos campos
                const { nome, valor, descricao, comissao } = response.data.data[0];
                setValue('nome', nome);
                setValue('valor', valor);
                setValue('descricao', descricao);
                setValue('comissao', comissao)

                const formattedValor = `R$ ${parseFloat(valor).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}`;


                const comissoesResponse = await Api.get(`ListarComissoes`);
                setComissoes(comissoesResponse.data.filter(comissao => comissao.id !== 1))

                setformattedValor(formattedValor); // adicionando o valor formatado ao estado local
            } catch (error) {
                console.log(error);
            }
        };

        buscarServico();
    }, [id, setValue]);

    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {
            data.id = id;

            data.valor = data.valor.replace(/[^0-9]/g, "");
            data.valor = data.valor.slice(0, -2) + "." + data.valor.slice(-2);

            console.log(data.valor)

            const response = await Api.put('EditarServico', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-servicos'
            }

        } catch (error) {
            console.log(error);
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o serviço. Por favor, tente novamente mais tarde." + error.response.data.error
            });
        }
    };

    const handleValorChange = (value) => {
        setValue("valor", value);
    };
    const [formattedValor, setformattedValor] = useState('');


    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Serviço</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Nome do produto*</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Descrição</label>
                                    <TextareaAutosize className="form-control" {...register("descricao")} minRows={1} maxRows={7} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Valor</label>
                                    <CurrencyInput
                                        defaultValue={formattedValor}
                                        onChange={handleValorChange}
                                    />
                                </div>
                                <div className='col-md-2 mb-3'>
                                    <label className='form-label'>Comissão</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={comissoes.map(comissao => ({
                                            value: comissao.id,
                                            label: comissao.nome
                                        }))}
                                        value={{ value: watch("comissao"), label: comissoes.find(a => a.id == watch("comissao"))?.nome }}
                                        onChange={(selectedOption) => {
                                            setValue("comissao", selectedOption.value);
                                            console.log(selectedOption?.value)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarServico;
