import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Api from '../../Api'

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatarCelular, formatarTelefone } from '../../Components/formataTelefones';


function EditarCliente({ cliente, setShowModalEdicaoCliente }) {
    const id = cliente;
    console.log("id: ", id)


    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });

    const tipo = watch("tipo");


    const telefone = useRef(null);
    const rg = useRef(null);
    const cep = useRef(null);
    const celular = useRef(null);
    const contato = useRef(null);
    const telefone_contato = useRef(null);
    const cpfRef = useRef(null);
    const ie = useRef(null);
    const cnpj = useRef(null);



    //campos com mask não são inseridos com o reacthookform
    const [rgCliente, setRgCliente] = useState('');
    const [cpfCliente, setcpfCLiente] = useState('');
    const [telefoneCliente, setTelefoneCliente] = useState('');
    const [celularCliente, setCelularCliente] = useState('');
    const [cepCliente, setCepCliente] = useState('');
    const [cidade, setCidade] = useState('');
    const [telefoneContato, setTelefoneContato] = useState('');

    const [cnpjCliente, setCnpjCliente] = useState('');
    const [ieCliente, setIeCliente] = useState('');

    const [endereco, setEndereco] = useState({
        cep: '',
        endereco: '',
        bairro: '',
        cidade: ''
    });

    useEffect(() => {
        const buscarCliente = async () => {
            try {
                const response = await Api.get(`BuscarCliente?id=${id}`);
                // Define os valores iniciais dos campos
                const { tipo, nome, contato, nome_contato, telefone: tel, cpf: cpfCliente, rg: rgCliente, cep: cepCliente, endereco, numero, bairro, cidade, data_nascimento, celular, cnpj, complemento, email, email_profissional, ie, razao, nome_fantasia, quadra, lote, sala, apto } = response.data.data[0];

                console.log(cepCliente, endereco, bairro, cidade)
                setValue('tipo', tipo);

                setValue('nome', nome);
                setValue('nome_contato', nome_contato);

                setValue('data_nascimento', data_nascimento);

                //isValidCpf = true; //assumindo que todos os cpfs cadastrados são validos //talvez nao precise
                setcpfCLiente(cpfCliente);
                setRgCliente(rgCliente);
                setCnpjCliente(cnpj);
                setCepCliente(cepCliente);
                setValue('cep', cepCliente)
                setCidade(cidade);
                setValue('rua', endereco);
                setValue('numero', numero);
                setValue('bairro', bairro);
                setValue('cidade', cidade);

                setValue('complemento', complemento);
                setValue('email', email);
                setValue('email_profissional', email_profissional);

                setValue('quadra', quadra);
                setValue('lote', lote);
                setValue('sala', sala);
                setValue('apto', apto);
                setEndereco({
                    cep: cepCliente,
                    endereco: endereco,
                    bairro: bairro,
                    cidade: cidade
                });




                setValue("razao", razao);
                setValue("nome_fantasia", nome_fantasia);
                setIeCliente(ie);

                if (tel) {
                    setTelefoneCliente(tel);
                    setValue('telefone', tel);
                }

                if (celular) {
                    setCelularCliente(celular);
                    setValue('celular', celular);
                }

                if (contato) {
                    setTelefoneContato(contato);
                    setValue('contato', contato);
                }
            } catch (error) {
                console.log(error);
            }
        };



        buscarCliente();
    }, [id, setValue]);

    const onSubmit = async (data) => {

        if (tipo === "pf") {
            //zera os campos de pj e seta os de pf que tem mask
            data.cnpj = null;
            // data.razao = null;
            data.nome_fantasia = null;
            data.ie = null;

            data.cpf = cpfRef.current.value;
            data.rg = rg.current.value;

        } else {
            //zera os campos de pf e seta os de pj que tem mask
            data.cpf = null;
            data.rg = null;
            data.data_nascimento = null;
            data.nome = null;

            data.cnpj = cnpj.current.value;
            data.ie = ie.current.value;
        }

        //os campos com mask gerais
        console.log(watch("telefone"))
        if (watch("telefone") ? watch("telefone").length > 6 : false) {
            data.telefone = watch("telefone")
        } else {
            data.telefone = null;
        }
        if (watch("celular") ? watch("celular").length > 6 : false) {
            data.celular = watch("celular")
        } else {
            data.celular = null;
        }
        if (watch("contato") ? watch("contato").length > 6 : false) {
            data.contato = watch("contato")
        } else {
            data.contato = null;
        }


        data.cep = cep.current.value



        try {
            data.id = id; //precisa mandar o id para inserção no banco (no caso no próprio body)

            const response = await Api.put(`EditarCliente`, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });


            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                setShowModalEdicaoCliente(false)
            }

        } catch (error) {
            console.log(error);
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o cliente. Por favor, tente novamente mais tarde." + error.response.data.error
            });
        }
    };

    const handleCepChange = async (event) => {
        console.log(event.target.value);
        const cepValue = event.target.value.replace(/\D/g, '');

        if (cepValue.length !== 8) {
            return;
        }

        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`);
            console.log(response.data)
            if (response.data.erro !== true) {
                setEndereco({
                    cep: response.data.cep,
                    endereco: response.data.logradouro,
                    bairro: response.data.bairro,
                    cidade: response.data.localidade
                });
                setValue('rua', response.data.logradouro);
                setValue('bairro', response.data.bairro);
                setValue('cidade', response.data.localidade);

            }

        } catch (error) {
            console.log(error);
        }
    };

    const handle_busca_cpf = async () => {
        try {
            console.log(cpfRef.current.value, watch('data_nascimento'))
            const data_de_nascimento_formato_pt_br = '10/10/2010';
            const cpf = cpfRef.current.value.replace(/\D/g, '');
            const response = await Api.get(`https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${data_de_nascimento_formato_pt_br}&token=${process.env.REACT_APP_TOKEN}`);

            setValue('nome', response.data.result.nome_da_pf)

            const parts = response.data.result.data_nascimento.split('/');
            const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            setValue('data_nascimento', reformattedDate)


        } catch (error) {
            console.log(error)
        }
    }


    const [isValidCpf, setIsValidCpf] = useState(false);
    const [isCpfTouched, setIsCpfTouched] = useState(false);
    const [isCpfEmpty, setIsCpfEmpty] = useState(true);

    const validateCpf = (cpf) => {
        cpf = cpf.replace(/[^\d]/g, ""); // remove non-digits
        if (cpf.length !== 11) return false; // CPF must have 11 digits

        // calculate first digit verifier
        let sum = 0;
        for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i);
        let mod = sum % 11;
        let dv1 = mod < 2 ? 0 : 11 - mod;

        // calculate second digit verifier
        sum = 0;
        for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i);
        mod = sum % 11;
        let dv2 = mod < 2 ? 0 : 11 - mod;

        return cpf.charAt(9) == dv1 && cpf.charAt(10) == dv2;
    };

    const handleChangeCpf = () => {
        const cpf = cpfRef.current.value.replace(/[^\d]/g, "");
        const isValid = cpf ? validateCpf(cpf) : false;
        setIsValidCpf(isValid);
        setIsCpfTouched(true);
        setIsCpfEmpty(cpf === "");

        if (isValid && watch('data_nascimento') && watch('data_nascimento').length === 10) {
            handle_busca_cpf();
        }
    };

    const handleTelefoneChange = (event) => {
        const rawValue = event.target.value;
        const formattedValue = formatarTelefone(rawValue);
        setTelefoneCliente(formattedValue)
        setValue('telefone', event.target.value)
    }
    const handleCelularChange = (event) => {
        const rawValue = event.target.value;
        const formattedValue = formatarCelular(rawValue);

        setCelularCliente(formattedValue);
        setValue('celular', formattedValue)
    }
    const handleContatoChange = (event) => {
        const rawValue = event.target.value;
        const formattedValue = formatarCelular(rawValue);

        setTelefoneContato(formattedValue)
        setValue('contato', event.target.value)
    }

    // Anti uso moderno
    const handleTelefoneFocus = (e) => {
        const input = e.target;
        if (input) {
            input.setSelectionRange(input.value.length, input.value.length);
        }
    };
    const handleCelularFocus = (e) => {
        const input = e.target;

        if (input) {
            input.setSelectionRange(input.value.length, input.value.length);
        }
    };
    const handleContatoFocus = (e) => {
        const input = e.target;

        if (input) {
            input.setSelectionRange(input.value.length, input.value.length);
        }
    };

    const handleNascimentoChange = (event) => {
        setValue('data_nascimento', event.target.value)
        if (event.target.value.length === 10 && cpfRef.current.value.length === 14)
            handle_busca_cpf();
    }

    const handleCepValueChange = (event) => {
        console.log(event.target.value)
        setValue('cep', event.target.value)
    }

    const handleCNPJChange = async (event) => {
        const cnpjValue = event.target.value.replace(/\D/g, '')
        if (cnpjValue.length !== 14) {
            return;
        }

        try {
            const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpjValue}`)
            console.log(response.data)
            setEndereco({
                cep: response.data.cep,
                endereco: `${response.data.descricao_tipo_de_logradouro} ${response.data.logradouro}`,
                bairro: response.data.bairro,
                cidade: response.data.municipio,
                numero: response.data.numero,
            });

            const formatedEndereco = response.data.descricao_tipo_de_logradouro + ' ' + response.data.logradouro;
            // Atualiza os valores
            setValue('cidade', response.data.municipio);
            setValue('bairro', response.data.bairro);
            setValue('endereco', formatedEndereco);
            setValue('razao', response.data.razao_social);
            setValue('nome_fantasia', response.data.nome_fantasia);
            setValue('ie', response.data.inscricao_estadual);
            setValue('numero', response.data.numero);
            setValue('telefone', response.data.ddd_telefone_1);
            setValue('email', response.data.email);
            setValue('complemento', response.data.complemento);
            setValue('cep', response.data.cep);
        } catch (error) {
            console.log(error)
        }

    };

    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }

    return (
        <div className='page-full-1'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="m-1">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className="col-md-12 mb-5">
                            <div>
                                <label className="form-label">
                                    Tipo de cliente:
                                    <RadioGroup className='d-flex flex-row'>
                                        <FormControlLabel
                                            {...register("tipo")}
                                            value="pf"
                                            control={<Radio />}
                                            label="Pessoa física"
                                            className='mr-3'
                                            checked={tipo === "pf"}
                                        />
                                        <FormControlLabel
                                            {...register("tipo")}
                                            value="pj"
                                            control={<Radio />}
                                            label="Pessoa jurídica"
                                            className='mr-3'
                                            checked={tipo === "pj"}
                                        />
                                    </RadioGroup>
                                </label>
                            </div>

                            {tipo === "pf" && (
                                <div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">CPF*</label>
                                            <IMaskInput
                                                mask="000.000.000-00"
                                                type="text"
                                                inputRef={cpfRef}
                                                {...register("cpf")}
                                                defaultValue={cpfCliente}
                                                onAccept={handleChangeCpf}
                                                className={`form-control ${!isCpfEmpty && isCpfTouched ? (isValidCpf ? "is-valid" : "is-invalid") : ""
                                                    }`}
                                            />
                                            {isCpfTouched && !isValidCpf && !isCpfEmpty ? (
                                                <div className="invalid-feedback">CPF inválido.</div>
                                            ) : null}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Data de Nascimento</label>
                                            <input type="date" className="form-control" onChange={handleNascimentoChange} value={watch('data_nascimento')} />
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Nome completo*</label>
                                            <input type="text" onKeyUp={handleKeyUp} className="form-control" {...register("nome", { required: true })} value={watch('nome')} />
                                            {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <label className="form-label">RG</label>
                                            <IMaskInput
                                                mask="00.000.000-W"
                                                type="text"
                                                inputRef={rg}
                                                {...register("rg")}
                                                definitions={{
                                                    W: {
                                                        validate(char) {
                                                            return /[0-9a-zA-Z]/.test(char);
                                                        },
                                                    },
                                                }}
                                                defaultValue={rgCliente}
                                                //value={rgCliente}
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <label className="form-label">Apelido</label>
                                            <input type="text" className="form-control text-uppercase" {...register("razao")} onKeyUp={handleKeyUp} />
                                            {errors.razao && <span className="text-danger">Este campo é obrigatório</span>}
                                        </div>
                                    </div>

                                </div>
                            )}

                            {tipo === "pj" && (
                                <div>
                                    <div className='row'>

                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">CNPJ*</label>
                                            <IMaskInput
                                                mask="00.000.000/0000-00"
                                                type="text"
                                                inputRef={cnpj}
                                                {...register("cnpj")}
                                                defaultValue={cnpjCliente}
                                                className="form-control"
                                                onBlur={handleCNPJChange} />
                                            {errors.cnpj && <span className="text-danger">Este campo é obrigatório</span>}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Nome fantasia</label>
                                            <input type="text" onKeyUp={handleKeyUp} className="form-control" {...register("nome_fantasia")} />
                                            {errors.nome_fantasia && <span className="text-danger">Este campo é obrigatório</span>}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Razão Social</label>
                                            <input type="text" onKeyUp={handleKeyUp} className="form-control" {...register("razao")} />
                                            {errors.razao && <span className="text-danger">Este campo é obrigatório</span>}
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">IE</label>
                                            <IMaskInput
                                                mask="000.000.000.000"
                                                type="text"
                                                inputRef={ie}
                                                {...register("ie")}
                                                defaultValue={ieCliente}
                                                className="form-control" />
                                        </div>

                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Telefone</label>
                                    <input
                                        mask="(00) 0000-0000"
                                        type="text"
                                        inputRef={telefone}
                                        onChange={handleTelefoneChange}
                                        onFocus={handleTelefoneFocus}
                                        className="form-control"
                                        value={telefoneCliente || "(16)"}
                                        maxLength={14}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Celular</label>
                                    <input
                                        mask="(00) 00000-0000"
                                        type="text"
                                        inputRef={celular}
                                        onChange={handleCelularChange}
                                        onFocus={handleCelularFocus}
                                        className="form-control"
                                        value={celularCliente || "(16)"}
                                        maxLength={15}
                                    />
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <label className="form-label">Telefone Contato</label>
                                    <input
                                        mask="(00) 00000-0000"
                                        type="text"
                                        inputRef={telefone_contato}
                                        onChange={handleContatoChange}
                                        onFocus={handleContatoFocus}
                                        className="form-control"
                                        value={telefoneContato || "(16)"}
                                        maxLength={15}
                                    />

                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Nome contato</label>
                                    <input type="text" className="form-control text-uppercase" {...register("nome_contato")} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4 mb-3'>
                                    <label className="form-label">Cep</label>
                                    <IMaskInput
                                        mask="00.000-000"
                                        type="text"
                                        inputRef={cep}
                                        onChange={handleCepValueChange}
                                        className="form-control"
                                        // value={watch('cep')}
                                        defaultValue={watch('cep')}
                                        onBlur={handleCepChange}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Cidade</label>
                                    <input type="text" className="form-control" {...register("cidade")} defaultValue={cidade} onChange={(event) => setEndereco({ ...endereco, cidade: event.target.value })} />

                                </div>

                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Endereço</label>
                                    <input type="text" className="form-control" {...register("rua")} value={endereco.endereco} onChange={(event) => setEndereco({ ...endereco, endereco: event.target.value })} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Número</label>
                                    <input type="text" className="form-control" {...register("numero")} />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Bairro</label>
                                    <input type="text" className="form-control" {...register("bairro")} value={endereco.bairro} onChange={(event) => setEndereco({ ...endereco, bairro: event.target.value })} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Complemento</label>
                                    <input type="text" className="form-control" {...register("complemento")} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Quadra</label>
                                    <input type="text" className="form-control" {...register("quadra")} onKeyUp={handleKeyUp} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Lote</label>
                                    <input type="text" className="form-control" {...register("lote")} />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label className="form-label">Sala</label>
                                    <input type="text" className="form-control" {...register("sala")} />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label className="form-label">Apto.</label>
                                    <input type="text" className="form-control" {...register("apto")} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">E-mail</label>
                                    <input type="email" className="form-control" {...register("email")} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">E-mail profissional</label>
                                    <input type="email" className="form-control" {...register("email_profissional")} />
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                {status.message && (
                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        <div className="alert-icon">
                            <i className="far fa-fw fa-bell"></i>
                        </div>
                        <div className="alert-message">
                            {status.message}
                        </div>
                    </div>
                )}
                <div className="footer-page-full-os">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarCliente;
