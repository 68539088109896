import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function ListarClientes() {

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })

    const deleteRecord = async (data) => {
        try {
            const response = await Api.delete(`DeletarCliente/${data}`);
            setShowModalConfirm(false)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
            }
        } catch (error) {
            console.log(error)
            setShowModalConfirm(false)
            setStatus({
                success: false,
                message: "Ocorreu um erro ao apagar cliente. Tente novamente mais tarde."
            })
        }

    };

    function getInitials(name) {
        if (!name) {
            return '';
        }

        const splitName = name.toUpperCase().split(' ');

        if (splitName.length === 1) {
            return splitName[0] ? splitName[0].charAt(0) : '';
        } else {
            return splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0);
        }
    }

    const colors = ['rgb(255, 226, 226)', 'rgb(255, 234, 209)', 'rgb(247, 247, 176)', 'rgb(204, 255, 210)', 'rgb(216, 255, 252)', 'rgb(224, 236, 255)'];
    function getRandomColor() {
        return colors[Math.floor(Math.random() * colors.length)];
    }

    function truncate(str, maxLength) {
        if (str && str != null) {
            if (str.length > maxLength) {
                return str.substring(0, maxLength) + '...';
            } else {
                return str;
            }

        } else {
            return false;
        }
    }



    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: " ",

                    },
                    {
                        Header: "Nome / Fantasia",
                        accessor: row => row.nome_fantasia || row.nome,
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-cliente/${original.id}`}>{
                                <span className="ant-badge">
                                    <span className="ant-avatar ant-avatar-circle me-3" style={{ backgroundColor: getRandomColor() }}>
                                        <span className="ant-avatar-string" style={{ transform: 'scale(1) translateX(-50%)' }}>
                                            {getInitials(value)}
                                        </span>
                                    </span>
                                    <span className="fw-bold">
                                        {(value)}
                                    </span>
                                </span>
                                ?? '-'}
                            </Link>
                        ),
                    },
                    {
                        Header: "Razão Social / Apelido",
                        accessor: row => truncate(row.razao, 40) || truncate(row.nome, 40) || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link title={original.razao || original.nome} to={`/editar-cliente/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "CPF / CNPJ",
                        accessor: row => row.cpf || row.cnpj || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-cliente/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Telefone",
                        accessor: row => row.telefone || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-cliente/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    // {
                    //     Header: "Endereço",
                    //     accessor: row => row.endereco || '-',
                    //     Cell: ({ cell: { value }, row: { original } }) => (
                    //         <Link to={`/editar-cliente/${original.id}`}>{value || '-'}</Link>
                    //     ),
                    // },
                    {
                        Header: "Celular",
                        accessor: row => row.celular || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-cliente/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Excluir",
                        accessor: row =>
                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link className="fs-4" onClick={() => { setModalData({ nome: row.nome, id: row.id }); setShowModalConfirm(true); }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                            </div>

                    }
                ]
            }
        ],
        []
    );

    const [clientes, setClientes] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarClientes');
                setClientes(response.data.map((cliente) => {
                    return {
                        ...cliente,
                        nome: cliente.apelido || cliente.razao_social || cliente.nome_fantasia || cliente.nome || cliente.nome_contato || cliente.cnpj || cliente.cpf
                    }
                }).sort((a, b) => a.nome.localeCompare(b.nome)));
                console.log(response.data)
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [status]);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row">

                    <div className="d-flex justify-content-between">
                        <div>
                            <h1 className="h3 mb-3 font-bold">Listagem de Clientes</h1>
                        </div>
                        <div>
                            <Link to="/cadastrar-cliente" className='btn btn-primary fs-5'><FontAwesomeIcon icon="fa-solid fa-plus" /> | Novo Cliente</Link>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={clientes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja deletar o registro de: <b>{modalData.nome}</b> ?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { deleteRecord(modalData.id) }}>Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => setShowModalConfirm(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>


    );

}

export default ListarClientes