import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logoBlack from './nova-logo-black.png';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


const generateCertificado = async (data) => {
    const {
        informacoesBasicas,
        veiculoSelecionado,
        produtosSelecionados,
        servicosSelecionados,
        clientes,
        funcionarios,
        produtos,
        servicos,
        peliculasSelecionadas,
        peliculas
    } = data;

    const docDefinition = {
        pageSize: "A4",
        pageOrientation: "portrait",
        content: [],
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            logo: {
                alignment: "right",
                width: 100
            },
            title: {
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subtitle: {
                fontSize: 12,
                margin: [0, 0, 0, 5]
            }
        },
        defaultStyle: {
            fontSize: 10,
        }
    };

    // Adicionar o título e o logotipo da empresa
    docDefinition.content.push(
        { image: logoBlack, style: "logo", alignment: "right", width: 105, height: 54 },
        { text: `Ordem de Serviço ${informacoesBasicas.idOS ? "#" + informacoesBasicas.idOS : ''}`, style: "title" },
        {
            canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
            ]
        }
    );

    const addSectionIfDataExists = (sectionTitle, data) => {
        if (data) {
            docDefinition.content.push(
                { text: sectionTitle, style: "header" },
                { ul: data }
            );
        }
    };

    const getNomeCliente = (idCliente) => {
        if (clientes && Array.isArray(clientes)) {
            const cliente = clientes.find((cliente) => cliente.id === idCliente);
            if (cliente) {
                return cliente.nome || cliente.nome_fantasia || "Nome não encontrado";
            }
        }
        return "Nome do cliente não encontrado";
    };

    const getNomeFuncionario = (idList) => {
        console.log("lista: ", idList)
        let nomes = [];
        for (const id in idList) {
            const nome = funcionarios.find(funcionario => funcionario.id == idList[id]).apelido || funcionarios.find(funcionario => funcionario.id == idList[id]).nome;
            nomes.push(nome);
        }
        return nomes.join(', ');
    };

    const getNomeProduto = (idProduto) => {
        if (produtos && Array.isArray(produtos)) {
            const produto = produtos.find((produto) => produto.id === idProduto);
            return produto ? produto.nome : "Nome não encontrado";
        }
        return "Nome do produto não encontrado";
    };

    const getNomeServico = (idServico) => {
        if (servicos && Array.isArray(servicos)) {
            const servico = servicos.find((servico) => servico.id === idServico);
            return servico ? servico.nome : "Nome não encontrado";
        }
        return "Nome do serviço não encontrado";
    };

    const getNomePelicula = (idPelicula) => {
        if (peliculas && Array.isArray(peliculas)) {
            const pelicula = peliculas.find((pelicula) => pelicula.id === idPelicula);
            return pelicula ? pelicula.nome : "Nome não encontrado";
        }
        return "Nome da película não encontrado";
    };


    //find cliente usando o id em informacoesBasicas.id_cliente e criar uma variavel com o endereço do cliente
    const rua = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).endereco;
    const numero = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).numero;
    const bairro = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).bairro;
    const cidade = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).cidade;

    // filtrar os campos que não estão preenchidos
    const enderecoClienteParts = [rua, numero, bairro, cidade];
    const enderecoClienteFiltered = enderecoClienteParts.filter((part) => part);
    const enderecoCliente = enderecoClienteFiltered.join(', ');

    const quadra = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).quadra;
    const lote = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).lote;
    const apto = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).apto;
    const complemento = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).complemento;

    const complementos = [quadra ? `Quadra: ${quadra}` : '', lote ? `Lote: ${lote}` : '', apto ? `Apto: ${apto != null ? apto : ''}` : '', complemento ? `Complemento: ${complemento}` : ''];
    const complementosFiltered = complementos.filter((part) => part);
    const complementosCliente = complementosFiltered.join(', ');


    // precisa criar uma variavel com o contato do cliente, podendo ser o campo telefone, celular ou email, só deve mostrar os que estiverem preenchidos
    const telefone = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).telefone;
    const celular = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).celular;
    const contato = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).contato;
    const contatoCliente = [telefone, celular, contato].filter(Boolean).join(', ');
    const email_pessoal = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).email;
    const email_profissional = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).email_profissional;

    const email = [email_pessoal, email_profissional].filter(Boolean).join(', ');
    
    let nomeContato = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).nome_contato || '-';
    const razao = clientes.find((cliente) => cliente.id === informacoesBasicas.id_cliente).razao;

    const atendente = informacoesBasicas.id_funcionario ? funcionarios.find((funcionario) => funcionario.id === informacoesBasicas.id_funcionario).apelido || funcionarios.find((funcionario) => funcionario.id === informacoesBasicas.id_funcionario).nome || null : null;

    addSectionIfDataExists("", [
        `${getNomeCliente(informacoesBasicas?.id_cliente)}`,
        razao ? `${razao}\n` : null,
        enderecoCliente ?? `${enderecoCliente}\n`,
        complementosCliente ?? `${complementosCliente}\n`,
        nomeContato && nomeContato != '-' ? `${nomeContato}\n` : null,
        contatoCliente != '' ? `${contatoCliente}\n` : null,
        email ?? `${email}\n`,
    ]);

    docDefinition.content.push({
        text: [
            atendente ? { text: `\nAtendente: ${atendente}` } : null,
        ],
        margin: [0, 5, 0, 10]
    });

    if (veiculoSelecionado.nome != null) {

        docDefinition.content.push({
            canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
            ]
        });

        docDefinition.content.push({
            text: [
                { text: "Veículo: ", style: "header" },
                { text: `${veiculoSelecionado.modelo}. ${informacoesBasicas.placa ? `Placa: ${informacoesBasicas.placa}` : ''}` }
            ],
            margin: [0, 5, 0, 10]
        }, {
            canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 }
            ]
        });
    }

    //funcao usada antes de 05/07/2023 e removida após reuniao
    // const calculaArea = (tipo) => {
    //     const veiculo = veiculoSelecionado;
    //     //para calcular a area deve procurar nas propriedades o {tipo}_qtd e {tipo}_altura e {tipo}_largura
    //     const qtd = veiculo[`${tipo}_qtd`];
    //     const altura = veiculo[`${tipo}_altura`];
    //     const largura = veiculo[`${tipo}_largura`];
    //     const area = qtd * altura * largura;

    //     return area.toFixed(2); //retorna a area com 2 casas decimais
    // };

    const calculaArea = (tipo) => {
        const veiculo = veiculoSelecionado;
        //para calcular a area deve procurar nas propriedades o {tipo}_qtd e {tipo}_altura e {tipo}_largura
        const qtd = veiculo[`${tipo}_qtd`];
        const altura = veiculo[`${tipo}_altura`];
        const largura = veiculo[`${tipo}_largura`]; // medidas precisa ser a string com as medidas do vidro (ex: 1.00x1.00)

        const medidas = `${altura.replace('.', ',')} x ${largura.replace('.', ',')}`;

        return medidas; //retorna a area com 2 casas decimais
    };


    const somaPeliculas = (peliculasSelecionadas) => {
        let soma = 0;
        //para cada pelicula, somar o campo valor_vidro peliculasSelecionadas[key].valor_vidro
        for (const key in peliculasSelecionadas) {
            if (Object.hasOwnProperty.call(peliculasSelecionadas, key)) {
                const pelicula = peliculasSelecionadas[key];
                const valorVidro = parseFloat(pelicula.valor_vidro.replace(/R\$\s/g, "").replace(",", "."));
                soma += valorVidro
            }
        }


        return soma.toFixed(2).replace('.', ',');
    }


    if (peliculasSelecionadas) {
        const peliculasList = [];

        for (const key in peliculasSelecionadas) {
            if (Object.hasOwnProperty.call(peliculasSelecionadas, key)) {
                const pelicula = peliculasSelecionadas[key];
                const nomeVidro = key.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                const nomePelicula = getNomePelicula(pelicula.id);


                const valorVidro = parseFloat(pelicula.valor_vidro.replace(/R\$\s/g, "").replace(",", ".")).toFixed(2).replace('.', ',');

                const areaVidro = calculaArea(nomeVidro.replaceAll(" ", "_").toLocaleLowerCase()).replace(".", ",")

                console.log(pelicula)

                let quantidade = null;
                if (pelicula.qtd && pelicula.qtd != null && pelicula.qtd != undefined) {
                    quantidade = pelicula.qtd;
                } else {
                    quantidade = veiculoSelecionado[`${nomeVidro.replaceAll(" ", "_").toLocaleLowerCase()}_qtd`];
                }
                console.log(quantidade)

                const peliculaText = [
                    { text: nomePelicula, bold: true },
                    { text: `${nomeVidro}` },
                    { text: `${areaVidro}` },
                    { text: `${quantidade}` }
                    // { text: `R$ ${valorVidro}` }
                ];

                peliculasList.push(peliculaText);
            }
        }

        if (peliculasList.length > 0) {
            docDefinition.content.push({ text: "Películas:", style: "header" });

            const tableHeader = [
                { text: "Película", bold: true, alignment: "left" },
                { text: "Vidro", bold: true, alignment: "left" },
                { text: "Medidas Vidro (m)", bold: true, alignment: "left" },
                { text: "Quantidade", bold: true, alignment: "left" }
            ];

            const tableBody = [tableHeader];

            for (const peliculaText of peliculasList) {
                const tableRow = [
                    peliculaText[0],
                    peliculaText[1],
                    peliculaText[2],
                    peliculaText[3]
                ];

                tableBody.push(tableRow);
            }

            const table = {
                table: {
                    widths: ["*", "*", "*", "auto"],
                    body: tableBody
                },
                margin: [0, 5, 0, 10]
            };

            docDefinition.content.push(table);
        }
    }
    const buscaAplicadores = (aplicadores) => {
        //iterar os ids dos aplicadores e construir uma string com os nomes dos funcionarios correspondentes
        let nomes = [];

        for (const id in aplicadores) {
            const nome = funcionarios.find(funcionario => funcionario.id == aplicadores[id]).apelido || funcionarios.find(funcionario => funcionario.id == aplicadores[id]).nome;
            nomes.push(nome);
        }
        console.log(nomes)
        const nomes_string = nomes.join(', ');
        return nomes_string;
    }

    const aplicadores = buscaAplicadores(informacoesBasicas.aplicador);


    docDefinition.content.push({
        text: [
            aplicadores ? { text: `Aplicador(es):  ${aplicadores}` } : null,
            // aplicadores ? { text: `\nValor Total Vidros: R$ ${somaPeliculas(peliculasSelecionadas)}`, bold: true, alignment: "right" } : null,
        ],
        margin: [0, 5, 0, 10]
    });

    if (produtosSelecionados && produtosSelecionados.length > 0) {
        docDefinition.content.push({
            canvas: [
                { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
            ]
        });
        docDefinition.content.push({ text: "Produtos:", style: "header" });

        const tableBody = []; // Array para armazenar as linhas da tabela

        // Cabeçalho da tabela
        const tableHeader = [
            { text: "Produto", style: "tableHeader", alignment: "left" },
            { text: "Quantidade", style: "tableHeader", alignment: "center" },
            // { text: "Valor Unitário", style: "tableHeader", alignment: "center" },
            // { text: "Desconto", style: "tableHeader", alignment: "center" },
            { text: "Valor Total", style: "tableHeader", alignment: "right" }
        ];

        tableBody.push(tableHeader); // Adiciona o cabeçalho à tabela

        for (const produto of produtosSelecionados) {
            const nomeProduto = getNomeProduto(produto.id);
            const valorUnitario = parseFloat(produto.valor_venda.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));


            //quando o valor vem em BRL
            // const desconto = produto.desconto ? parseFloat(produto.desconto.replace(/[^0-9.,]+/g, '').replace(',', '.').replace('.', '')) : 0; // Verifica se o desconto existe e converte para float, caso contrário, define como 0

            //quando o valor vem em decimal
            let desconto = 0 // Verifica se o desconto existe e converte para float, caso contrário, define como 0

            if (produto.desconto && produto.desconto != null && produto.desconto != undefined && produto.desconto[0] === 'R') {
                desconto = parseFloat(produto.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.')) // Verifica se o desconto existe e converte para float, caso contrário, define como 0
            } else {
                if (produto.desconto != null && produto.desconto != undefined && produto.desconto > 0) {
                    desconto = parseFloat(produto.desconto)
                }
            }

            const valorTotal = (valorUnitario * produto.quantidade) - desconto;

            const tableRow = [
                { text: `${nomeProduto} (Cod. ${produto.id})`, alignment: "left" },
                { text: produto.quantidade.toString(), alignment: "center" },
                // { text: `R$ ${valorUnitario.toFixed(2).replace(".", ",")}`, alignment: "center" },
                // { text: desconto ? `R$ ${desconto.toFixed(2).replace(".", ",")}` : "", alignment: "center" }, // Verifica se o desconto existe para exibir seu valor, caso contrário, exibe uma string vazia
                { text: `R$ ${valorTotal.toFixed(2).replace(".", ",")}`, alignment: "right" }
            ];

            tableBody.push(tableRow); // Adiciona a linha à tabela
        }

        const table = {
            table: {
                widths: ["*", "auto", "auto"], // Define a largura das colunas da tabela
                body: tableBody // Define o corpo da tabela
            },
            margin: [0, 5, 0, 10] // Define as margens da tabela
        };

        docDefinition.content.push(table); // Adiciona a tabela ao conteúdo do documento
    }

    docDefinition.content.push({
        canvas: [
            { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
        ]
    });

    if (servicosSelecionados && servicosSelecionados.length > 0) {
        docDefinition.content.push({ text: "Serviços:", style: "header" });

        const tableBody = []; // Array para armazenar as linhas da tabela

        // Cabeçalho da tabela
        const tableHeader = [
            { text: "Serviço", style: "tableHeader", alignment: "left" },
            { text: "Responsável", style: "tableHeader", alignment: "center" },
            { text: "Quantidade", style: "tableHeader", alignment: "center" },
            // { text: "Valor Unitário", style: "tableHeader", alignment: "center" },
            // { text: "Desconto", style: "tableHeader", alignment: "center" },
            { text: "Valor Total", style: "tableHeader", alignment: "right" }
        ];

        tableBody.push(tableHeader); // Adiciona o cabeçalho à tabela

        for (const servico of servicosSelecionados) {
            const nomeServico = getNomeServico(servico.id);
            const valorUnitario = parseFloat(servico.valor.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));


            let desconto = 0.0 // Verifica se o desconto existe e converte para float, caso contrário, define como 0

            if (servico.desconto && servico.desconto != null && servico.desconto != undefined && servico.desconto[0] === 'R') {
                desconto = parseFloat(servico.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.')) // Verifica se o desconto existe e converte para float, caso contrário, define como 0
            } else {
                if (servico.desconto != null && servico.desconto != undefined && servico.desconto > 0) {

                    desconto = parseFloat(servico.desconto)
                }
            }
            const valorTotal = (valorUnitario * servico.quantidade) - desconto;

            const funcionarioNome = servico.funcionarios ? getNomeFuncionario(servico.funcionarios) : '-';
            const tableRow = [
                { text: nomeServico, alignment: "left" },
                { text: funcionarioNome, alignment: "center" },
                { text: servico.quantidade.toString(), alignment: "center" },
                // { text: `R$ ${valorUnitario.toFixed(2).replace(".", ",")}`, alignment: "center" },
                // { text: desconto ? `R$ ${desconto.toFixed(2).replace(".", ",")}` : "", alignment: "center" }, // Verifica se o desconto existe para exibir seu valor, caso contrário, exibe uma string vazia
                { text: `R$ ${valorTotal.toFixed(2).replace(".", ",")}`, alignment: "right" }
            ];

            tableBody.push(tableRow); // Adiciona a linha à tabela
        }

        const table = {
            table: {
                widths: ["*", "auto", "auto", "auto"], // Define a largura das colunas da tabela
                body: tableBody // Define o corpo da tabela
            },
            margin: [0, 5, 0, 10] // Define as margens da tabela
        };

        docDefinition.content.push(table); // Adiciona a tabela ao conteúdo do documento
    }

    docDefinition.content.push({
        canvas: [
            { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
        ]
    });


    if (informacoesBasicas.observacao) {
        docDefinition.content.push({
            text: [
                //margem superior
                { text: `\nObservação: ${informacoesBasicas.observacao}`, margin: [0, 5, 0, 0] },
            ],
        });
    }



    // Adicionar o valor total
    const valorTotal = data.valor_total;
    // const desconto = data.informacoesBasicas.desconto ? parseFloat(data.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.')) : 0; // Valor padrão de desconto caso não esteja definido

    let desconto = 0.0 // Verifica se o desconto existe e converte para float, caso contrário, define como 0

    if (data.informacoesBasicas.desconto && data.informacoesBasicas.desconto != null && data.informacoesBasicas.desconto != undefined && data.informacoesBasicas.desconto[0] === 'R') {
        desconto = parseFloat(data.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.')) || 0 // Verifica se o desconto existe e converte para float, caso contrário, define como 0
    } else {
        desconto = parseFloat(data.informacoesBasicas.desconto) || 0
    }


    if (valorTotal) {
        const valorFinal = valorTotal - desconto;

        docDefinition.content.push(
            {
                text: "\n",
            },
            // {
            //     canvas: [
            //         { type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1 } // Adiciona uma linha horizontal
            //     ]
            // },
            { text: `Valor Total: R$ ${parseFloat(valorFinal).toFixed(2).replace(".", ",")}`, alignment: "right", margin: [0, 10], bold: true }
        );
    }


    if (docDefinition.content.length > 0) {
        const newWindow = window.open("", "_blank", "width=800,height=900");
        pdfMake.createPdf(docDefinition).open({}, newWindow);
    } else {
        console.log("Não há dados para gerar o certificado.");
    }
};

export default generateCertificado;
