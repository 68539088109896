import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Api from '../../Api';
import Table from '../../Components/Table'
import { parse, isValid, format, parseISO, addMonths, subDays } from 'date-fns';
import CurrencyInput from '../../Components/CurrencyInput';


const OrdensLista = () => {
    const [status, setStatus] = useState({ success: false, message: '' })

    const [rawData, setRawData] = useState([]);
    const [funcionarios, setFuncionarios] = useState([]);
    const [resetTable, setResetTable] = useState(); //estado inicial da tabela
    const [dataShow, setDataShow] = useState([]);


    const [filterData, setFilterData] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [detailsData, setDetailsData] = useState([]);


    const filtrarLista = () => {
        const { inicio, fim } = filterData;
        console.log(inicio, fim);

        const registrosFiltrados = rawData.filter(item => {
            const dataInicio = new Date(item.data_inicio);
            dataInicio.setUTCHours(0, 0, 0, 0);

            let dataItem;
            if (item.data_fim) {
                dataItem = new Date(item.data_fim);
            } else {
                // Se não houver data de fim, use a data de início
                dataItem = dataInicio;
            }
            dataItem.setUTCHours(0, 0, 0, 0);

            // Verifica se a data de início foi fornecida e se o registro atende a ela
            const atendeInicio = !inicio || dataItem >= new Date(inicio);

            // Verifica se a data de fim foi fornecida e se o registro atende a ela
            const atendeFim = !fim || dataItem <= new Date(fim);

            // Se ambos os filtros de início e fim estiverem presentes e forem iguais,
            // inclui o registro se a dataItem for igual à data de início
            if (inicio && fim && inicio === fim) {
                return atendeInicio && atendeFim && dataItem.getTime() === dataInicio.getTime();
            }

            return atendeInicio && atendeFim;
        });

        const registros = calcularQuantidadeTotal(registrosFiltrados);
        setDataShow(registros);
    };








    function calcularQuantidadeTotal(data) {
        const resultado = {
            OS_arq_aberta: { quantidade: 0, idOSList: [] },
            OS_arq_fechada: { quantidade: 0, idOSList: [] },
            OS_veic_aberta: { quantidade: 0, idOSList: [] },
            OS_veic_fechada: { quantidade: 0, idOSList: [] }
        };

        data.forEach(item => {
            const status = item.status;
            const file = item.file;

            const categoria = status === 'aberta' ? (file === 'arquitetura' ? 'OS_arq_aberta' : 'OS_veic_aberta') : (file === 'arquitetura' ? 'OS_arq_fechada' : 'OS_veic_fechada');

            resultado[categoria].quantidade++;
            resultado[categoria].idOSList.push(item.idOS);
        });

        return resultado;
    }







    useEffect(() => {
        const getData = async () => {
            const relatoriosResponse = await Api.get('/Relatorios');
            const data = relatoriosResponse.data.data.OS;
            setRawData(data);
            console.log(data)



            const registros = calcularQuantidadeTotal(data); //quantidade de ocorrencias de cada veículo
            // console.log(registros)


            setDataShow(registros);
            setResetTable(registros);

        }
        getData();
    }, []);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });
        filtrarLista();
    }, [rawData]);


    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Relatório de Ordens</h1>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        {status.message && (
                            <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                <div className="alert-icon">
                                    <i className="far fa-fw fa-bell"></i>
                                </div>
                                <div className="alert-message">
                                    {status.message}
                                </div>
                            </div>
                        )}
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="mb-4">Ordens Veículares:</h4>
                                </div>
                                <div className="col-md-6">
                                    {dataShow.OS_veic_aberta && (
                                        <div className="card mb-4 position-relative">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">Abertas</h5>
                                                <p className="fs-4">Qtd: {dataShow.OS_veic_aberta.quantidade}</p>
                                            </div>
                                            <button className="btn position-absolute top-0 end-0" style={{ marginTop: 10, marginRight: 10 }} onClick={() => {
                                                setDetailsData({
                                                    titulo: 'Ordens de serviço Veículares Abertas',
                                                    idOSList: dataShow.OS_veic_aberta.idOSList
                                                })
                                                setShowDetailsModal(true);
                                            }}>
                                                <i className="bi bi-info-circle"></i>
                                            </button>
                                        </div>

                                    )}
                                </div>
                                <div className="col-md-6">
                                    {dataShow.OS_veic_fechada && (
                                        <div className="card mb-4 position-relative">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">Fechadas</h5>
                                                <p className="fs-4">Qtd: {dataShow.OS_veic_fechada.quantidade}</p>
                                            </div>
                                            <button className="btn position-absolute top-0 end-0" style={{ marginTop: 10, marginRight: 10 }} onClick={() => {
                                                setDetailsData({
                                                    titulo: 'Ordens de serviço Veículares Fechadas',
                                                    idOSList: dataShow.OS_veic_fechada.idOSList
                                                })
                                                setShowDetailsModal(true);
                                            }}>
                                                <i className="bi bi-info-circle"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="mb-4">Ordens Arquitetura:</h4>
                                </div>
                                <div className="col-md-6">
                                    {dataShow.OS_arq_aberta && (
                                        <div className="card mb-4 position-relative">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">Abertas</h5>
                                                <p className="fs-4">Qtd: {dataShow.OS_arq_aberta.quantidade}</p>
                                            </div>
                                            <button className="btn position-absolute top-0 end-0" style={{ marginTop: 10, marginRight: 10 }} onClick={() => {
                                                setDetailsData({
                                                    titulo: 'Ordens de serviço Arquitetura Abertas',
                                                    idOSList: dataShow.OS_arq_aberta.idOSList
                                                })
                                                setShowDetailsModal(true);
                                            }}>
                                                <i className="bi bi-info-circle"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {dataShow.OS_arq_fechada && (
                                        <div className="card mb-4 position-relative">
                                            <div className="card-body text-center">
                                                <h5 className="card-title">Fechadas</h5>
                                                <p className="fs-4">Qtd: {dataShow.OS_arq_fechada.quantidade}</p>
                                            </div>
                                            <button className="btn position-absolute top-0 end-0" style={{ marginTop: 10, marginRight: 10 }} onClick={() => {
                                                setDetailsData({
                                                    titulo: 'Ordens de serviço Arquitetura Fechadas',
                                                    idOSList: dataShow.OS_arq_fechada.idOSList
                                                })
                                                setShowDetailsModal(true);
                                            }}>
                                                <i className="bi bi-info-circle"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>



                        </>

                    </div>
                </div>
            </div>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista(); setShowFilterModal(false) }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setDataShow(resetTable); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>

            </Modal>

            <Modal centered show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{detailsData.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailsData.idOSList && (
                        <div>
                            <p>ID's das OS:</p>
                            <ul>
                                {detailsData.idOSList.map(idOS => (
                                    <li key={idOS}>{idOS}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setShowDetailsModal(false)}>Fechar</button>
                </Modal.Footer>
            </Modal>



        </>
    );
}

export default OrdensLista;
