import React, { useState, useRef, useEffect } from 'react';
import { set, useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { TextareaAutosize } from '@mui/material';
import CurrencyInput from '../../Components/CurrencyInput';
import Select from 'react-select';



function EditarConta() {
    const { id } = useParams(); // Obtém o id do conta a partir da URL
    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })

    const [produtos, setProdutos] = useState([])
    const [fornecedores, setFornecedores] = useState([])


    useEffect(() => {
        const buscarConta = async () => {
            try {
                const response = await Api.get(`BuscarConta?id=${id}`);
                // Define os valores iniciais dos campos
                console.log(response.data)
                const { produto, fornecedor, quantidade, descricao, valor, anexo, data, tipo } = response.data.data[0];
                setValue('produto', produto);
                setValue('fornecedor', fornecedor);
                setValue('quantidade', quantidade);
                setValue('descricao', descricao);
                setValue('valor', `R$ ${valor.replace('.', ',')}`);
                setValue('anexo', anexo);
                setValue('data', data);
                setValue('tipo', tipo);

            } catch (error) {
                console.log(error);
            }
        };
        buscarConta();

        const buscarProdutos = async () => {
            try {
                const response = await Api.get('ListarProdutos');
                if (response.status === 200) {
                    const produtos = response.data.map((produto) => {
                        return {
                            id: produto.id,
                            nome: produto.nome,
                            codigo_produto: produto.preco,
                            valor_venda: produto.quantidade,
                            descricao: produto.estoque,
                            categoria: produto.categoria,
                            tipo: produto.tipo
                        }
                    })
                    setProdutos(produtos)
                    console.log(produtos)
                }
            } catch (error) {
                console.log(error);
            }
        }
        buscarProdutos();

        const buscarFornecedores = async () => {
            try {
                const response = await Api.get('ListarFornecedores');
                if (response.status === 200) {
                    const fornecedores = response.data.map((fornecedor) => {
                        return {
                            id: fornecedor.id,
                            nome: fornecedor.nome,
                            nome_contato: fornecedor.nome_contato,
                            nome_fantasia: fornecedor.nome_fantasia
                        }
                    })
                    setFornecedores(fornecedores)
                    console.log(fornecedores)
                }
            } catch (error) {
                console.log(error);
            }
        }
        buscarFornecedores();

    }, [id, setValue]);

    const [valor, setValor] = useState('');
    const handleValorChange = (valor) => {
        setValor(valor);
        setValue('valor', valor)
    }

    const handleProdutoChange = (produto) => {
        //const produtoFull = produtos.find((p) => p.id === produto.value)
        setValue('produto', produto.value)
    }

    const handleFornecedorChange = (fornecedor) => {
        setValue('fornecedor', fornecedor.value)
    }

    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        console.log(data);
        try {

            const formData = new FormData();
            formData.append('id', id);
            formData.append('produto', JSON.stringify(data.produto));
            formData.append('fornecedor', JSON.stringify(data.fornecedor));
            formData.append('arquivo', data.anexo[0]);
            formData.append('descricao', data.descricao);
            formData.append('tipo', data.tipo);
            formData.append('quantidade', data.quantidade ? data.quantidade.replace(",", ".") : null);
            formData.append('valor', data.valor.replace(".", "").replace(",", ".").replace("R$ ", ""));

            const response = await Api.put('EditarConta', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setStatus(response.data)

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-contas'
            }



        } catch (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao atualizar a conta  . Tente novamente mais tarde.",
                });
            }

        }
    };

    const resetFields = (e) => {
        setValue('tipo', e);
        setValue('produto', '');
        setValue('fornecedor', '');
        setValue('quantidade', '');
        setValue('descricao', '');
        setValue('valor', '');
        setValue('anexo', '');
    }



    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Entradas e saídas</h1>

                            <div>
                                <label className="form-label">
                                    Selecione o tipo:
                                    <RadioGroup className='d-flex flex-row'>
                                        <FormControlLabel
                                            // {...register("tipo")}
                                            value="despesa"
                                            control={<Radio />}
                                            label="Despesa"
                                            className='mr-3'
                                            checked={watch("tipo") === "despesa"}
                                            onChange={() => resetFields('despesa')}
                                        />
                                        <FormControlLabel
                                            // {...register("tipo")}
                                            value="receita"
                                            control={<Radio />}
                                            label="Receita"
                                            className='mr-3'
                                            checked={watch("tipo") === "receita"}
                                            onChange={() => resetFields('receita')}
                                        />
                                    </RadioGroup>
                                </label>
                            </div>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            {watch('tipo') === 'despesa' &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o produto</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={produtos.map(produto => ({
                                                    value: produto.id,
                                                    label: produto.nome
                                                }))}
                                                onChange={handleProdutoChange}
                                                value={
                                                    watch("produto") && {
                                                        value: watch("produto"),
                                                        label: `${produtos.find((produto) => produto.id == watch('produto'))?.nome || 'indefinido'}`
                                                    }
                                                }
                                            />

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o fornecedor</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={fornecedores.map(fornecedor => ({
                                                    value: fornecedor.id,
                                                    label: fornecedor.nome_fantasia || fornecedor.nome
                                                }))}
                                                onChange={handleFornecedorChange}
                                                value={
                                                    watch("fornecedor") && {
                                                        value: watch("fornecedor"),
                                                        label: `${fornecedores.find((fornecedor) => fornecedor.id == watch('fornecedor'))?.nome_fantasia || fornecedores.find((fornecedor) => fornecedor.id == watch('fornecedor'))?.nome || 'indefinido'}`
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            {watch('produto') && watch('produto').tipo == 'material' ?
                                                <>
                                                    <label className="form-label">Metros²</label>
                                                    <input type="text" pattern="[0-9.,]+" className="form-control" {...register("quantidade")} />
                                                </>
                                                :
                                                <>
                                                    <label className="form-label">Quantidade</label>
                                                    <input type="text" className="form-control" {...register("quantidade")} />
                                                </>
                                            }

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor</label>
                                            <CurrencyInput defaultValue={watch('valor')} onChange={handleValorChange} />
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                </>
                            }
                            {watch('tipo') === 'receita' &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor</label>
                                            <CurrencyInput defaultValue={watch('valor')} onChange={handleValorChange} />
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                </>
                            }
                            {watch('anexo') && (
                                <img
                                    src={
                                        watch('anexo') instanceof FileList
                                            ? URL.createObjectURL(watch('anexo')[0])
                                            : `data:image/png;base64,${watch('anexo')}`
                                    }
                                    style={{
                                        display: watch('anexo') ? 'block' : 'none',
                                        marginTop: '10px',
                                        marginLeft: 'auto', // Alinhamento à direita
                                        marginRight: '10px', // Espaçamento direito
                                    }}
                                    width='320'
                                    height='auto'
                                    alt="anexo do produto"
                                />
                            )}













                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarConta;
