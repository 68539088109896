import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../Api';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NovoVeiculo() {
    const { register, handleSubmit, setValue, watch, formState: { errors }, reset } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })
    const [selectedMarca, setSelectedMarca] = useState(null);

    const [options, SetOptions] = useState([]);
    const [modeloOptions, SetModeloOptions] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const getMarcas = async () => {
            try {
                const response = await Api.get('https://parallelum.com.br/fipe/api/v1/carros/marcas');
                //set value same as label in marcas (setMarcas)
                const marcas = response.data.map((marca) => {
                    return { value: marca.nome, label: marca.nome, id: marca.codigo }
                });
                console.log(marcas)
                SetOptions(marcas);
            }
            catch (error) {
                console.log(error)
            }
        }
        getMarcas();
    }, [])

    const buscaVeiculos = async (selectedOption) => {
        setValue('nome', selectedOption?.label)


        if (selectedOption.id) {
            console.log('tem id: ', selectedOption.id);
        } else {
            setValue('modelo', selectedOption?.value)
        }
        try {
            const response = await Api.get(`https://parallelum.com.br/fipe/api/v1/carros/marcas/${selectedOption.id}/modelos`);
            console.log(response.data.modelos)
            const modelos = response.data.modelos.map((modelo) => {
                return { value: modelo.nome, label: modelo.nome }
            });
            console.log(modelos)
            SetModeloOptions(modelos);
        }
        catch (error) {
            console.log(error)
        }
    }

    //comentario pra commitar ??? kkk
    const onSubmit = async (data) => {
        try {
            console.log(data);
            const response = await Api.post('NovoVeiculo', JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' } //o formato padrão do axios é x-www-form-urlencoded, entao precisa definir que é json pro backend receber certinho
            });
            setStatus(response.data)

            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setSelectedMarca(null);
                setValue('modelo', '');
                reset();
                window.location.reload();
            }


        }
        catch (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar o veículo. Tente novamente mais tarde.",
                });
            }

        }

        // window.scrollTo(0, 0);

    };

    const handleMarcaChange = (selectedOption) => {
        setSelectedMarca(selectedOption); // Atualizar o valor selecionado no estado
        setValue('marca', selectedOption?.value);

        buscaVeiculos(selectedOption);
    };

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-car" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Veículo</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Marca</label>
                                    <input type="hidden" {...register('marca')} />
                                    <Select
                                        className='text-uppercase'
                                        options={options}
                                        value={selectedMarca}
                                        onChange={handleMarcaChange}
                                        placeholder="Selecione a marca do veículo"
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Modelo</label>
                                    <input type="text" className="form-control text-uppercase" {...register("modelo")} />
                                    {errors.modelo && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Ano</label>
                                    <input type="text" maxLength={4} className="form-control" {...register("ano")} />
                                    {errors.ano && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>
                            </div>
                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Para-brisa</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("parabrisa_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.parabrisa_altura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("parabrisa_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.parabrisa_largura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("parabrisa_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                        {errors.parabrisa_qtd && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input type="number" step="any" min="0" className="form-control"
                                                value={(Math.round(watch("parabrisa_altura") * watch("parabrisa_largura") * watch("parabrisa_qtd") * 100) / 100).toFixed(2)} disabled />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Quebra vento dianteiro</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("quebra_vento_dianteiro_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("quebra_vento_dianteiro_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("quebra_vento_dianteiro_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control"
                                                value={(Math.round(watch("quebra_vento_dianteiro_altura") * watch("quebra_vento_dianteiro_largura") * watch("quebra_vento_dianteiro_qtd") * 100) / 100).toFixed(2)} disabled />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Porta dianteira</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("porta_dianteira_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.porta_dianteira_altura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("porta_dianteira_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.porta_dianteira_largura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("porta_dianteira_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                        {errors.porta_dianteira_qtd && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control"
                                                value={(Math.round(watch("porta_dianteira_altura") * watch("porta_dianteira_largura") * watch("porta_dianteira_qtd") * 100) / 100).toFixed(2)} disabled />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Porta traseira</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("porta_traseira_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("porta_traseira_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("porta_traseira_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("porta_traseira_altura") * watch("porta_traseira_largura") * watch("porta_traseira_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras1_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras1_altura") * watch("lateral_tras1_largura") * watch("lateral_tras1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras2_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras2_altura") * watch("lateral_tras2_largura") * watch("lateral_tras2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras3_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras3_altura") * watch("lateral_tras3_largura") * watch("lateral_tras3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(4)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras4_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras4_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras4_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras4_altura") * watch("lateral_tras4_largura") * watch("lateral_tras4_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(5)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras5_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras5_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras5_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras5_altura") * watch("lateral_tras5_largura") * watch("lateral_tras5_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Quebra vento fixo da porta traseira</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_porta_tras_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_porta_tras_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("fixo_porta_tras_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("fixo_porta_tras_altura") * watch("fixo_porta_tras_largura") * watch("fixo_porta_tras_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Quebra vento fixo do vigia</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_vigia_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_vigia_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("fixo_vigia_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("fixo_vigia_altura") * watch("fixo_vigia_largura") * watch("fixo_vigia_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Vigia(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia1_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("vigia1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("vigia1_altura") * watch("vigia1_largura") * watch("vigia1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Vigia(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia2_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("vigia2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("vigia2_altura") * watch("vigia2_largura") * watch("vigia2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Vigia(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia3_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("vigia3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("vigia3_altura") * watch("vigia3_largura") * watch("vigia3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Teto(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto1_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("teto1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("teto1_altura") * watch("teto1_largura") * watch("teto1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Teto(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto2_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("teto2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("teto2_altura") * watch("teto2_largura") * watch("teto2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Teto(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto3_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("teto3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("teto3_altura") * watch("teto3_largura") * watch("teto3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Complemento(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento1_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("complemento1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("complemento1_altura") * watch("complemento1_largura") * watch("complemento1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Complemento(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento2_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("complemento2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("complemento2_altura") * watch("complemento2_largura") * watch("complemento2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Complemento(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento3_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("complemento3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("complemento3_altura") * watch("complemento3_largura") * watch("complemento3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div className='row'>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Observação</label>
                                    <textarea className="form-control" {...register("obs")}></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form >
        </div >
    );
}

export default NovoVeiculo;
