import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Api from '../../Api';
import Table from '../../Components/Table'
import { parse, isValid, format, parseISO, addMonths, subDays } from 'date-fns';
import CurrencyInput from '../../Components/CurrencyInput';


const ClientesLista = () => {
    const [status, setStatus] = useState({ success: false, message: '' })

    const [rawData, setRawData] = useState([]);
    const [funcionarios, setFuncionarios] = useState([]);
    const [resetTable, setResetTable] = useState(); //estado inicial da tabela
    const [dataShow, setDataShow] = useState([]);


    const [filterData, setFilterData] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [detailsData, setDetailsData] = useState([]);


    const filtrarLista = () => {
        const { inicio, fim } = filterData;
        // console.log(inicio, fim);

        const registrosFiltrados = rawData.filter(item => {
            const dataItem = new Date(item.data_fim);
            dataItem.setUTCHours(0, 0, 0, 0);

            // Verifica se a data de início foi fornecida e se o registro atende a ela
            const atendeInicio = !inicio || dataItem >= new Date(inicio);

            // Verifica se a data de fim foi fornecida e se o registro atende a ela
            const atendeFim = !fim || dataItem <= new Date(fim);

            // Se ambos os filtros de início e fim estiverem presentes e forem iguais,
            // inclui o registro se a dataItem for igual à data de início
            if (inicio && fim && inicio === fim) {
                const dataInicio = new Date(inicio);
                dataInicio.setUTCHours(0, 0, 0, 0);
                return atendeInicio && atendeFim && dataItem.getTime() === dataInicio.getTime();
            }

            return atendeInicio && atendeFim;
        });

        // Filtra por id
        if (filterData.id) {
            const id = parseInt(filterData.id);
            const registrosFiltradosPorId = registrosFiltrados.filter(item => item.id_cliente === id);
            const registros = calcularQuantidadeTotal(registrosFiltradosPorId);
            registros.sort((a, b) => b.quantidade - a.quantidade); // Sort by quantidade in descending order
            setDataShow(registros);
            return;
        }

        const registros = calcularQuantidadeTotal(registrosFiltrados);
        registros.sort((a, b) => b.quantidade - a.quantidade); // Sort by quantidade in descending order
        setDataShow(registros);
    };







    function calcularQuantidadeTotal(data) {
        // Objeto para rastrear a quantidade e os idOS para cada id_cliente
        const clientesMap = {};

        // Itera sobre a lista de objetos
        data.forEach(item => {
            const idCliente = item.id_cliente;

            // Se o id_cliente ainda não existe no mapa, cria uma entrada para ele
            if (!clientesMap[idCliente]) {
                clientesMap[idCliente] = {
                    quantidade: 0,
                    idOSList: [],
                    nome_cliente: item.nome_cliente,
                    telefone: item.telefone,
                    email: item.email,
                    celular: item.celular,

                };
            }

            // Atualiza a quantidade e a lista de idOS para o id_cliente
            clientesMap[idCliente].quantidade++;
            clientesMap[idCliente].idOSList.push(item.idOS);
        });

        // Converte o mapa de volta para uma lista de objetos
        const resultado = Object.entries(clientesMap).map(([idCliente, info]) => ({
            id_cliente: parseInt(idCliente),
            quantidade: info.quantidade,
            idOSList: info.idOSList,
            nome_cliente: info.nome_cliente,
            telefone: info.telefone,
            email: info.email,
            celular: info.celular,
        }));

        return resultado;
    }


    useEffect(() => {
        const getData = async () => {
            const relatoriosResponse = await Api.get('/Relatorios');
            const data = relatoriosResponse.data.data.clientes;
            setRawData(data);



            const registros = calcularQuantidadeTotal(data); //quantidade de ocorrencias de cada veículo
            registros.sort((a, b) => b.quantidade - a.quantidade); // Sort by quantidade in descending order

            setDataShow(registros);
            setResetTable(registros);

        }
        getData();
    }, []);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });
        filtrarLista();
    }, [rawData]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'idOS',
                columns: [
                    {
                        Header: "#",
                        accessor: "id_cliente"
                    },
                    {
                        Header: "Nome do Cliente",
                        accessor: "nome_cliente"
                    },
                    {
                        Header: "Telefone",
                        accessor: "telefone"
                    },
                    {
                        Header: "Celular",
                        accessor: "celular"
                    },
                    {
                        Header: "Email",
                        accessor: "email"
                    },
                    {
                        Header: "Ocorrências",
                        accessor: "quantidade"
                    },
                    {
                        Header: "Detalhes",
                        accessor: row => (
                            <div className="btn-group">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setShowDetailsModal(true);
                                        setDetailsData(row);
                                        // console.log(row)
                                    }}
                                >
                                    Ver
                                </button>
                            </div>
                        )
                    },
                ]
            }
        ],
        [dataShow]
    );



    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Relatório de Clientes</h1>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={dataShow} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-label">ID</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="id"
                                    placeholder='#'
                                    min={0}
                                    value={filterData.id || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            id: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista(); setShowFilterModal(false) }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setDataShow(resetTable); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>

            </Modal>

            <Modal centered show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailsData.idOSList && (
                        <div>
                            {detailsData.id_cliente && <p>ID do Cliente: {detailsData.id_cliente}</p>}
                            {detailsData.nome_cliente && <p>Nome do Cliente: {detailsData.nome_cliente}</p>}
                            {detailsData.telefone && <p>Telefone do Cliente: {detailsData.telefone}</p>}
                            {detailsData.celular && <p>Celular do Cliente: {detailsData.celular}</p>}
                            {detailsData.email && <p>Email do Cliente: {detailsData.email}</p>}
                            {detailsData.quantidade && <p>Quantidade de Ocorrências: {detailsData.quantidade}</p>}
                            <p>ID's das OS:</p>
                            <ul>
                                {detailsData.idOSList.map(idOS => (
                                    <li key={idOS}>{idOS}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setShowDetailsModal(false)}>Fechar</button>
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default ClientesLista;
