class OS {
    constructor() {
        this.clientes = [];
        this.funcionarios = [];
        this.produtos = [];
        this.servicos = [];
        this.veiculos = [];


        this.informacoesBasicas = {
            id_cliente: "",
            id_funcionario: "",
            data_inicio: new Date().toISOString().slice(0, 10),
            observacao: "",
            anexos: "",
        };

        this.produtosSelecionados = [];

        this.servicosSelecionados = [];

        this.veiculoSelecionado = [];

    }


}

export default OS;
