import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//import axios from 'axios';
import { useParams } from 'react-router-dom';
import Api from '../../Api'

import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function EditarVeiculo() {
    const { id } = useParams(); // Obtém o id do produto a partir da URL
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });

    const [options, SetOptions] = useState([]);
    const [modeloOptions, SetModeloOptions] = useState([])

    useEffect(() => {
        const getMarcas = async () => {
            try {
                const response = await Api.get('https://parallelum.com.br/fipe/api/v1/carros/marcas');
                const marcas = response.data.map((marca) => {
                    return { value: marca.nome, label: marca.nome, id: marca.codigo }
                });
                SetOptions(marcas);
            }
            catch (error) {
                console.log(error)
            }
        }
        getMarcas();
    }, [])

    const handleMarcaChange = (selectedOption) => {
        setValue('marca', selectedOption.value);

        if (!selectedOption.id) {
            const marcaObj = options.find((marcaObj) => marcaObj.value === watch("marca"));
            buscaVeiculos(marcaObj);
            return;
        }

        buscaVeiculos(selectedOption);
    };

    const buscaVeiculos = async (selectedOption) => {
        console.log(selectedOption)
        if (!selectedOption.id) {

            setValue('nome', selectedOption?.label)
            setValue('modelo', selectedOption?.value)

        }


        // console.log(selectedOption)
        try {
            const response = await Api.get(`https://parallelum.com.br/fipe/api/v1/carros/marcas/${selectedOption.id}/modelos`);
            // console.log(response.data.modelos)
            const modelos = response.data.modelos.map((modelo) => {
                return { value: modelo.nome, label: modelo.nome }
            });
            // console.log(modelos)
            SetModeloOptions(modelos);
        }
        catch (error) {
            console.log(error)
        }
    }


    const [marcaaux, setMarcaaux] = useState('');

    useEffect(() => {
        const buscarVeiculo = async () => {
            try {
                const response = await Api.get(`BuscarVeiculo?id=${id}`);
                // Define os valores iniciais dos campos
                console.log(response.data)
                const { nome, ano, modelo, marca,
                    parabrisa_altura, parabrisa_largura, parabrisa_qtd,
                    obs,
                    quebra_vento_dianteiro_altura, quebra_vento_dianteiro_largura, quebra_vento_dianteiro_qtd,
                    porta_dianteira_altura, porta_dianteira_largura, porta_dianteira_qtd,
                    porta_traseira_altura, porta_traseira_largura, porta_traseira_qtd,
                    lateral_tras1_altura, lateral_tras1_largura, lateral_tras1_qtd,
                    lateral_tras2_altura, lateral_tras2_largura, lateral_tras2_qtd,
                    lateral_tras3_altura, lateral_tras3_largura, lateral_tras3_qtd,
                    lateral_tras4_altura, lateral_tras4_largura, lateral_tras4_qtd,
                    lateral_tras5_altura, lateral_tras5_largura, lateral_tras5_qtd,
                    fixo_porta_tras_altura, fixo_porta_tras_largura, fixo_porta_tras_qtd,
                    fixo_vigia_altura, fixo_vigia_largura, fixo_vigia_qtd,
                    vigia1_altura, vigia1_largura, vigia1_qtd,
                    vigia2_altura, vigia2_largura, vigia2_qtd,
                    vigia3_altura, vigia3_largura, vigia3_qtd,
                    teto1_altura, teto1_largura, teto1_qtd,
                    teto2_altura, teto2_largura, teto2_qtd,
                    teto3_altura, teto3_largura, teto3_qtd,
                    complemento1_altura, complemento1_largura, complemento1_qtd,
                    complemento2_altura, complemento2_largura, complemento2_qtd,
                    complemento3_altura, complemento3_largura, complemento3_qtd
                } = response.data.data[0];
                setValue('nome', nome);
                setMarcaaux(marca);
                setValue('marca', marca);
                setValue('modelo', modelo);
                setValue('ano', ano);

                setValue('parabrisa_altura', parabrisa_altura);
                setValue('parabrisa_largura', parabrisa_largura);
                setValue('parabrisa_qtd', parabrisa_qtd);

                setValue('quebra_vento_dianteiro_altura', quebra_vento_dianteiro_altura);
                setValue('quebra_vento_dianteiro_largura', quebra_vento_dianteiro_largura);
                setValue('quebra_vento_dianteiro_qtd', quebra_vento_dianteiro_qtd);

                setValue('porta_dianteira_altura', porta_dianteira_altura);
                setValue('porta_dianteira_largura', porta_dianteira_largura);
                setValue('porta_dianteira_qtd', porta_dianteira_qtd);

                setValue('porta_traseira_altura', porta_traseira_altura);
                setValue('porta_traseira_largura', porta_traseira_largura);
                setValue('porta_traseira_qtd', porta_traseira_qtd);

                setValue('lateral_tras1_altura', lateral_tras1_altura);
                setValue('lateral_tras1_largura', lateral_tras1_largura);
                setValue('lateral_tras1_qtd', lateral_tras1_qtd);

                setValue('lateral_tras2_altura', lateral_tras2_altura);
                setValue('lateral_tras2_largura', lateral_tras2_largura);
                setValue('lateral_tras2_qtd', lateral_tras2_qtd);

                setValue('lateral_tras3_altura', lateral_tras3_altura);
                setValue('lateral_tras3_largura', lateral_tras3_largura);
                setValue('lateral_tras3_qtd', lateral_tras3_qtd);

                setValue('lateral_tras4_altura', lateral_tras4_altura);
                setValue('lateral_tras4_largura', lateral_tras4_largura);
                setValue('lateral_tras4_qtd', lateral_tras4_qtd);

                setValue('lateral_tras5_altura', lateral_tras5_altura);
                setValue('lateral_tras5_largura', lateral_tras5_largura);
                setValue('lateral_tras5_qtd', lateral_tras5_qtd);

                setValue('fixo_porta_tras_altura', fixo_porta_tras_altura);
                setValue('fixo_porta_tras_largura', fixo_porta_tras_largura);
                setValue('fixo_porta_tras_qtd', fixo_porta_tras_qtd);

                setValue('fixo_vigia_altura', fixo_vigia_altura);
                setValue('fixo_vigia_largura', fixo_vigia_largura);
                setValue('fixo_vigia_qtd', fixo_vigia_qtd);

                setValue('vigia1_altura', vigia1_altura);
                setValue('vigia1_largura', vigia1_largura);
                setValue('vigia1_qtd', vigia1_qtd);

                setValue('vigia2_altura', vigia2_altura);
                setValue('vigia2_largura', vigia2_largura);
                setValue('vigia2_qtd', vigia2_qtd);

                setValue('vigia3_altura', vigia3_altura);
                setValue('vigia3_largura', vigia3_largura);
                setValue('vigia3_qtd', vigia3_qtd);

                setValue('teto1_altura', teto1_altura);
                setValue('teto1_largura', teto1_largura);
                setValue('teto1_qtd', teto1_qtd);

                setValue('teto2_altura', teto2_altura);
                setValue('teto2_largura', teto2_largura);
                setValue('teto2_qtd', teto2_qtd);

                setValue('teto3_altura', teto3_altura);
                setValue('teto3_largura', teto3_largura);
                setValue('teto3_qtd', teto3_qtd);

                setValue('complemento1_altura', complemento1_altura);
                setValue('complemento1_largura', complemento1_largura);
                setValue('complemento1_qtd', complemento1_qtd);

                setValue('complemento2_altura', complemento2_altura);
                setValue('complemento2_largura', complemento2_largura);
                setValue('complemento2_qtd', complemento2_qtd);

                setValue('complemento3_altura', complemento3_altura);
                setValue('complemento3_largura', complemento3_largura);
                setValue('complemento3_qtd', complemento3_qtd);

                setValue('obs', obs);

            } catch (error) {
                console.log(error);
            }
        };

        buscarVeiculo();
    }, [id, setValue]);



    useEffect(() => {
        try {

            handleMarcaChange({ value: watch("marca"), label: watch("marca") });

        }
        catch (error) {
            console.log(error);
        }
    }, [options, watch("marca")])


    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {
            data.id = id;
            const response = await Api.put(`EditarVeiculo`, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            console.log(response);
            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-contas'
            }


        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao editar o veículo. Tente novamente mais tarde.",
                });
            }
        }
    };


    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-car" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Veículo</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Marca</label>
                                    <Select
                                        className='text-uppercase'
                                        options={options}
                                        onChange={handleMarcaChange}
                                        placeholder="Selecione a marca do veículo"
                                        value={{ value: watch("marca"), label: watch("marca") }}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Modelo</label>
                                    <input type="text" className="form-control text-uppercase" {...register("modelo")} />
                                    {errors.modelo && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Ano</label>
                                    <input type="text" maxLength={4} className="form-control" {...register("ano")} />
                                    {errors.ano && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>
                            </div>

                            <fieldset className='p-2 bg-light mb-3'>
                                <span className='text-left text-uppercase fs-3'>Para-brisa</span>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("parabrisa_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.parabrisa_altura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("parabrisa_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.parabrisa_largura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("parabrisa_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                        {errors.parabrisa_qtd && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input type="number" step="any" min="0" className="form-control"
                                                value={(Math.round(watch("parabrisa_altura") * watch("parabrisa_largura") * watch("parabrisa_qtd") * 100) / 100).toFixed(2)} disabled />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Quebra vento dianteiro</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("quebra_vento_dianteiro_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("quebra_vento_dianteiro_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("quebra_vento_dianteiro_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control"
                                                value={(Math.round(watch("quebra_vento_dianteiro_altura") * watch("quebra_vento_dianteiro_largura") * watch("quebra_vento_dianteiro_qtd") * 100) / 100).toFixed(2)} disabled />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Porta dianteira</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("porta_dianteira_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.porta_dianteira_altura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" min="0" step="any" className="form-control" {...register("porta_dianteira_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                        {errors.porta_dianteira_largura && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("porta_dianteira_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                        {errors.porta_dianteira_qtd && <span className="text-danger">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control"
                                                value={(Math.round(watch("porta_dianteira_altura") * watch("porta_dianteira_largura") * watch("porta_dianteira_qtd") * 100) / 100).toFixed(2)} disabled />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Porta traseira</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("porta_traseira_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("porta_traseira_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("porta_traseira_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("porta_traseira_altura") * watch("porta_traseira_largura") * watch("porta_traseira_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras1_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras1_altura") * watch("lateral_tras1_largura") * watch("lateral_tras1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras2_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras2_altura") * watch("lateral_tras2_largura") * watch("lateral_tras2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras3_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras3_altura") * watch("lateral_tras3_largura") * watch("lateral_tras3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(4)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras4_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras4_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras4_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras4_altura") * watch("lateral_tras4_largura") * watch("lateral_tras4_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Lateral traseira(5)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras5_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("lateral_tras5_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("lateral_tras5_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("lateral_tras5_altura") * watch("lateral_tras5_largura") * watch("lateral_tras5_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Quebra vento fixo da porta traseira</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_porta_tras_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_porta_tras_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("fixo_porta_tras_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("fixo_porta_tras_altura") * watch("fixo_porta_tras_largura") * watch("fixo_porta_tras_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Quebra vento fixo do vigia</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_vigia_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("fixo_vigia_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("fixo_vigia_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("fixo_vigia_altura") * watch("fixo_vigia_largura") * watch("fixo_vigia_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Vigia(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia1_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("vigia1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("vigia1_altura") * watch("vigia1_largura") * watch("vigia1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Vigia(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia2_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("vigia2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("vigia2_altura") * watch("vigia2_largura") * watch("vigia2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Vigia(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("vigia3_largura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("vigia3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("vigia3_altura") * watch("vigia3_largura") * watch("vigia3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Teto(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto1_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("teto1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("teto1_altura") * watch("teto1_largura") * watch("teto1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Teto(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto2_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("teto2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("teto2_altura") * watch("teto2_largura") * watch("teto2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Teto(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("teto3_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("teto3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("teto3_altura") * watch("teto3_largura") * watch("teto3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Complemento(1)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento1_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento1_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("complemento1_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("complemento1_altura") * watch("complemento1_largura") * watch("complemento1_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Complemento(2)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento2_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento2_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("complemento2_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("complemento2_altura") * watch("complemento2_largura") * watch("complemento2_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className='p-2 bg-light mb-3'>
                                <legend className='text-left text-uppercase fs-3'>Complemento(3)</legend>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Altura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento3_altura")} />
                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Largura</label>
                                        <div className="input-group">
                                            <input type="number" step="any" min="0" className="form-control" {...register("complemento3_largura")} />

                                            <span className="input-group-text">m</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Quantidade</label>
                                        <div className='input-group'>
                                            <input type="number" min="0" className="form-control" {...register("complemento3_qtd")} />
                                            <span className="input-group-text">un</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Total</label>
                                        <div className='input-group'>
                                            <input
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                value={(Math.round(watch("complemento3_altura") * watch("complemento3_largura") * watch("complemento3_qtd") * 100) / 100).toFixed(2)}
                                                disabled
                                            />
                                            <span className="input-group-text">m²</span>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <div className='row'>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Observação</label>
                                    <textarea className="form-control" {...register("obs")}></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> EDITAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarVeiculo;
