export function convertDate(inputDate) {
    // Criando um objeto Date a partir da string de entrada
    const date = new Date(inputDate);
    
    // Obtendo os componentes da data
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Os meses começam do zero
    const year = date.getUTCFullYear();
    
    // Retornando a data formatada
    return `${day}/${month}/${year}`;
}
