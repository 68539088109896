import React, { useState, useRef, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function EditarFuncionario() {
    const { id } = useParams(); // Obtém o id do cliente a partir da URL
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });

    const [cargos, setCargos] = useState([]);
    const [cargoID, setCargoID] = useState(0);

    const celular = useRef(null);
    const cpfRef = useRef(null);
    const rg = useRef(null);
    const cep = useRef(null);

    //campos com mask não são inseridos com o reacthookform
    const [cpfFuncionario, setCpfFuncionario] = useState('');
    const [rgFuncionario, setRgFuncionario] = useState('');
    const [cepFuncionario, setCepFuncionario] = useState('');

    const [cargoSelecionado, setCargoSelecionado] = useState(null);

    const [endereco, setEndereco] = useState({
        cep: '',
        endereco: '',
        bairro: '',
        cidade: ''
    });


    useEffect(() => {
        const buscarFuncionario = async () => {
            try {
                const response = await Api.get(`BuscarFuncionario?id=${id}`);
                // Define os valores iniciais dos campos
                //console.log(response.data)
                const { nome, telefone: tel, cpf: cpfFuncionario, rg: rgFuncionario, cep: cepFuncionario, endereco: rua, numero, bairro, cidade, email, data_admissao, data_demissao, cargo_id, data_nascimento, quadra, lote, sala, apelido, apto } = response.data.data[0];
                setValue('nome', nome);
                setValue('cpf', cpfFuncionario);
                setRgFuncionario(rgFuncionario);
                setCepFuncionario(cepFuncionario);
                setValue('rua', rua);
                setValue('numero', numero);
                setValue('bairro', bairro);
                setValue('cidade', cidade);
                setValue('email', email);
                setValue('data_admissao', data_admissao);
                setValue('data_demissao', data_demissao);
                setValue('cargo', cargo_id);
                setValue('data_nascimento', data_nascimento);
                setCargoID(cargo_id);
                setValue('quadra', quadra);
                setValue('lote', lote);
                setValue('sala', sala);
                setValue('apelido', apelido);
                setValue('apto', apto);


                if (tel) {
                    setValue('celular', tel);
                } else {
                    setValue('celular', '16 ');
                }





            } catch (error) {
                console.log(error);
            }
        };

        buscarFuncionario();
    }, [id, setValue]);

    useEffect(() => {
        const fetchCargos = async () => {
            try {
                const responseCargos = await Api.get(`BuscarCargos`);
                setCargos(responseCargos.data.data);

                const cargoSelecionado = responseCargos.data.data.find(cargo => cargo.id === watch('cargo'));
                if (cargoSelecionado) {
                    setCargoSelecionado({ value: cargoSelecionado.id, label: cargoSelecionado.nome });
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchCargos();
    }, []);


    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {
            // CAMPOS COM MASK
            if (celular.current.value.length > 6) {
                data.celular = celular.current.value
            } else {
                data.celular = null;
            }

            data.cpfRef = cpfRef.current.value;
            data.rg = rg.current.value;
            data.cep = cep.current.value;

            if (watch("cargo_id")) {
                data.cargo = watch("cargo_id").toString();
            }
            data.id = id;

            console.log(data);
            const response = await Api.put(`EditarFuncionario`, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            console.log(response);

            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-funcionarios'
            }



        } catch (error) {
            console.log(error);
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o funcionario. Por favor, tente novamente mais tarde." + error.response.data.error
            });
        }
    };

    const handle_busca_cpf = async () => {
        try {
            console.log(cpfRef.current.value, watch('data_nascimento'))
            const data_de_nascimento_formato_pt_br = '10/10/2010';
            const cpf = cpfRef.current.value.replace(/\D/g, '');
            const response = await Api.get(`https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${data_de_nascimento_formato_pt_br}&token=${process.env.REACT_APP_TOKEN}`);
            console.log(response.data)

            console.log(response.data.result.nome_da_pf)
            setValue('nome', response.data.result.nome_da_pf)

            const parts = response.data.result.data_nascimento.split('/');
            const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            setValue('data_nascimento', reformattedDate)


        } catch (error) {
            console.log(error)
        }
    }

    const [isValidCpf, setIsValidCpf] = useState(false);
    const [isCpfTouched, setIsCpfTouched] = useState(false);
    const [isCpfEmpty, setIsCpfEmpty] = useState(true);

    const validateCpf = (cpf) => {
        cpf = cpf.replace(/[^\d]/g, ""); // remove non-digits
        if (cpf.length !== 11) return false; // CPF must have 11 digits

        // calculate first digit verifier
        let sum = 0;
        for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i);
        let mod = sum % 11;
        let dv1 = mod < 2 ? 0 : 11 - mod;

        // calculate second digit verifier
        sum = 0;
        for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i);
        mod = sum % 11;
        let dv2 = mod < 2 ? 0 : 11 - mod;

        return cpf.charAt(9) == dv1 && cpf.charAt(10) == dv2;
    };

    const handleChangeCpf = () => {
        const cpf = cpfRef.current.value.replace(/[^\d]/g, "");
        const isValid = cpf ? validateCpf(cpf) : false;
        setIsValidCpf(isValid);
        setIsCpfTouched(true);
        setIsCpfEmpty(cpf === "");

        if (isValid && watch('data_nascimento').length === 10) {
            handle_busca_cpf();
        }
    };

    const handleCelularChange = (event) => {
        setValue('celular', event.target.value)
    }

    const handleCepChange = async (event) => {
        console.log(event.target.value);
        const cepValue = event.target.value.replace(/\D/g, '');

        if (cepValue.length !== 8) {
            return;
        }

        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`);
            console.log(response.data)
            if (response.data.erro !== true) {
                setEndereco({
                    cep: response.data.cep,
                    endereco: response.data.logradouro,
                    bairro: response.data.bairro,
                    cidade: response.data.localidade
                });
                setValue('rua', response.data.logradouro);
                setValue('bairro', response.data.bairro);
                setValue('cidade', response.data.localidade);

            }

        } catch (error) {
            console.log(error);
        }
    };

    // Anti uso moderno
    const handleCelularFocus = (e) => {
        const input = celular.current;
        const tamanho = input.value.length;
        if (input) {
            input.setSelectionRange(tamanho, tamanho);
        }
    };

    const handleNascimentoChange = (event) => {
        setValue('data_nascimento', event.target.value)
        if (event.target.value.length === 10 && cpfRef.current.value.length === 14)
            handle_busca_cpf();
    }

    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }



    //console.log(cargoSelecionado)
    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Funcionário</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">CPF*</label>
                                    <IMaskInput
                                        mask="000.000.000-00"
                                        type="text"
                                        inputRef={cpfRef}
                                        {...register("cpf")}
                                        defaultValue={watch('cpf')}
                                        onAccept={handleChangeCpf}
                                        className={`form-control ${!isCpfEmpty && isCpfTouched ? (isValidCpf ? "is-valid" : "is-invalid") : ""
                                            }`}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Data de Nascimento*</label>
                                    <input type="date" className="form-control" onChange={handleNascimentoChange} value={watch('data_nascimento')} />
                                </div>

                            </div>
                            <div className='row'>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">RG*</label>
                                    <IMaskInput
                                        mask="00.000.000-W"
                                        type="text"
                                        inputRef={rg}
                                        {...register("rg")}
                                        definitions={{
                                            W: {
                                                validate(char) {
                                                    return /[0-9a-zA-Z]/.test(char);
                                                },
                                            },
                                        }}
                                        defaultValue={rgFuncionario}
                                        value={rgFuncionario}
                                        onAccept={(value, mask) => {
                                            setRgFuncionario(value);
                                            mask.updateValue();
                                        }}
                                        className="form-control"
                                        placeholder="Digite o RG do funcionário"
                                    />

                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Nome*</label>
                                    <input type="text" className="form-control" {...register("nome", { required: true })} value={watch('nome')} />
                                    {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Apelido</label>
                                    <input type="text" className="form-control text-uppercase" {...register("apelido")} value={watch('apelido')} onKeyUp={handleKeyUp} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Celular*</label>
                                    <IMaskInput
                                        mask="(00) 00000-0000"
                                        type="text"
                                        inputRef={celular}
                                        onChange={handleCelularChange}
                                        onFocus={handleCelularFocus}
                                        className="form-control"
                                        value={watch('celular')}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Cep*</label>
                                    <IMaskInput
                                        mask="00.000-000"
                                        type="text"
                                        inputRef={cep}
                                        {...register("cep")}
                                        value={cepFuncionario}
                                        onAccept={(value, mask) => {
                                            setCepFuncionario(value);
                                            mask.updateValue();
                                        }}
                                        className="form-control"
                                        onChange={handleCepChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Endereço*</label>
                                    <input type="text" className="form-control" {...register("rua")} />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Número*</label>
                                    <input type="text" className="form-control" {...register("numero")} />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Bairro*</label>
                                    <input type="text" className="form-control" {...register("bairro")}
                                        placeholder="Digite o bairro do cliente" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Quadra</label>
                                    <input type="text" className="form-control text-uppercase" {...register("quadra")} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Lote</label>
                                    <input type="text" className="form-control" {...register("lote")} />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label className="form-label">Sala</label>
                                    <input type="text" className="form-control" {...register("sala")} />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label className="form-label">Apto</label>
                                    <input type="text" className="form-control" {...register("apto")} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Cidade*</label>
                                    <input type="text" className="form-control" {...register("cidade")} />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Cargo</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={cargos.map(cargo => ({
                                            value: cargo.id,
                                            label: cargo.nome
                                        }))}
                                        onChange={(selectedOption) => {
                                            register("cargo_id", { value: selectedOption?.value });
                                            setCargoSelecionado(selectedOption);
                                        }}
                                        value={cargoSelecionado}
                                    />


                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" {...register("email")} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Admissão</label>
                                    <input type="date" className="form-control" {...register("data_admissao")} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Demissão</label>
                                    <input type="date" className="form-control" {...register("data_demissao")} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarFuncionario;
