import MainContent from './Components/MainContent';
import AuthProvider from './Context/auth';
import { BrowserRouter as Router} from 'react-router-dom'

function App() {

    return (
        <AuthProvider>
            <Router>
                <MainContent/>
            </Router>
        </AuthProvider>
    );
}



export default App;
