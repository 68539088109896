import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../Api';
import TextareaAutosize from 'react-textarea-autosize';

import CurrencyInput from '../../Components/CurrencyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';


function NovoServico() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })

    const onSubmit = async (data) => {
        window.scrollTo(0, 0);
        try {
            data.valor = valorVenda.replace(/[^0-9]/g, "")

            const response = await Api.post('NovoServico', JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' } //o formato padrão do axios é x-www-form-urlencoded, entao precisa definir que é json pro backend receber certinho
            });
            setStatus(response.data)

            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setValorVenda('')
                reset()
            }


        } catch (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar o serviço. Tente novamente mais tarde.",
                });
            }

        }

    };

    const [valorVenda, setValorVenda] = useState('');
    const handleValorVendaChange = (valor) => {
        setValorVenda(valor);
    }

    const [comissoes, setComissoes] = useState([]);
    useEffect(() => {
        const getComissoes = async () => {
            try {
                const response = await Api.get(`ListarComissoes`);
                setComissoes(response.data.filter(comissao => comissao.id !== 1))
            } catch (error) {
                console.log(error)
            }
        }
        getComissoes()

    }, []);


    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Serviço</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Título do serviço*</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Descrição</label>
                                    <TextareaAutosize className="form-control" {...register("descricao")} minRows={1} maxRows={7} />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Valor</label>
                                    <CurrencyInput
                                        defaultValue={valorVenda}
                                        onChange={handleValorVendaChange}
                                    />
                                </div>
                                <div className='col-md-2 mb-3'>
                                    <label className='form-label'>Comissão</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={comissoes.map(comissao => ({
                                            value: comissao.id,
                                            label: comissao.nome
                                        }))}
                                        onChange={(selectedOption) => {
                                            register("comissao", { value: selectedOption?.value });
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NovoServico
