import React, { useState } from "react";
import Select from "react-select";
import CurrencyInput from "../../../Components/CurrencyInput";

const PeliculaEdit = ({ os, handleVeiculoChange, handlePeliculaChange, handlePeliculaPrecoChange, handleFieldChange, handleQuantidadeVidro, handleReset }) => {

    const [selectKey, setSelectKey] = useState(0);
    const [refreshSelect, setRefreshSelect] = useState(false);

    const limpaSelects = () => {
        setSelectKey((prevKey) => prevKey + 1); // Atualiza a chave para re-renderizar o Select
        setRefreshSelect(false); // Atualiza o estado para re-renderizar o Select
        setTimeout(() => { //pqp, sem isso não funciona
            setRefreshSelect(true);
        }, 1);


    };

    const calculaArea = (tipo) => {
        const veiculo = os.veiculoSelecionado;
        //para calcular a area deve procurar nas propriedades o {tipo}_qtd e {tipo}_altura e {tipo}_largura
        const qtd = veiculo[`${tipo}_qtd`];
        const altura = veiculo[`${tipo}_altura`];
        const largura = veiculo[`${tipo}_largura`];
        const area = qtd * altura * largura;

        return area.toFixed(2); //retorna a area com 2 casas decimais
    };

    const formataMedidas = (tipo) => {
        const veiculo = os.veiculoSelecionado;
        //para calcular a area deve procurar nas propriedades o {tipo}_qtd e {tipo}_altura e {tipo}_largura
        const qtd = veiculo[`${tipo}_qtd`];
        const altura = veiculo[`${tipo}_altura`];
        const largura = veiculo[`${tipo}_largura`];

        //concatenar as medidas e retornar tipo a x b x c
        const area = String(altura + ' x ' + largura).replace(".", ",");

        return area; //retorna a ar
    }

    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }

    const secoesVidro = [
        {
            chave: 'parabrisa',
            titulo: 'Parabrisa',
            quantidade: os.veiculoSelecionado.parabrisa_qtd,
            altura: os.veiculoSelecionado.parabrisa_altura,
        },
        {
            chave: 'quebra_vento_dianteiro',
            titulo: 'Quebra vento dianteiro',
            quantidade: os.veiculoSelecionado.quebra_vento_dianteiro_qtd,
            altura: os.veiculoSelecionado.quebra_vento_dianteiro_altura,
        },
        {
            chave: 'porta_dianteira',
            titulo: 'Porta dianteira',
            quantidade: os.veiculoSelecionado.porta_dianteira_qtd,
            altura: os.veiculoSelecionado.porta_dianteira_altura,
        },
        {
            chave: 'porta_traseira',
            titulo: 'Porta traseira',
            quantidade: os.veiculoSelecionado.porta_traseira_qtd,
            altura: os.veiculoSelecionado.porta_traseira_altura,
        },
        {
            chave: 'lateral_tras1',
            titulo: 'Lateral traseira(1)',
            quantidade: os.veiculoSelecionado.lateral_tras1_qtd,
            altura: os.veiculoSelecionado.lateral_tras1_altura,
        },
        {
            chave: 'lateral_tras2',
            titulo: 'Lateral traseira(2)',
            quantidade: os.veiculoSelecionado.lateral_tras2_qtd,
            altura: os.veiculoSelecionado.lateral_tras2_altura,
        },
        {
            chave: 'lateral_tras3',
            titulo: 'Lateral traseira(3)',
            quantidade: os.veiculoSelecionado.lateral_tras3_qtd,
            altura: os.veiculoSelecionado.lateral_tras3_altura,
        },
        {
            chave: 'lateral_tras4',
            titulo: 'Lateral traseira(4)',
            quantidade: os.veiculoSelecionado.lateral_tras4_qtd,
            altura: os.veiculoSelecionado.lateral_tras4_altura,
        },
        {
            chave: 'lateral_tras5',
            titulo: 'Lateral traseira(5)',
            quantidade: os.veiculoSelecionado.lateral_tras5_qtd,
            altura: os.veiculoSelecionado.lateral_tras5_altura,
        },
        {
            chave: 'fixo_porta_tras',
            titulo: 'Fixo porta traseira',
            quantidade: os.veiculoSelecionado.fixo_porta_tras_qtd,
            altura: os.veiculoSelecionado.fixo_porta_tras_altura,
        },
        {
            chave: 'fixo_vigia',
            titulo: 'Fixo do vigia',
            quantidade: os.veiculoSelecionado.fixo_vigia_qtd,
            altura: os.veiculoSelecionado.fixo_vigia_altura,
        },
        {
            chave: 'vigia1',
            titulo: 'Vigia 1',
            quantidade: os.veiculoSelecionado.vigia1_qtd,
            altura: os.veiculoSelecionado.vigia1_altura,
        },
        {
            chave: 'vigia2',
            titulo: 'Vigia 2',
            quantidade: os.veiculoSelecionado.vigia2_qtd,
            altura: os.veiculoSelecionado.vigia2_altura,
        },
        {
            chave: 'vigia3',
            titulo: 'Vigia 3',
            quantidade: os.veiculoSelecionado.vigia3_qtd,
            altura: os.veiculoSelecionado.vigia3_altura,
        },
        {
            chave: 'teto1',
            titulo: 'Teto 1',
            quantidade: os.veiculoSelecionado.teto1_qtd,
            altura: os.veiculoSelecionado.teto1_altura,
        },
        {
            chave: 'teto2',
            titulo: 'Teto 2',
            quantidade: os.veiculoSelecionado.teto2_qtd,
            altura: os.veiculoSelecionado.teto2_altura,
        },
        {
            chave: 'teto3',
            titulo: 'Teto 3',
            quantidade: os.veiculoSelecionado.teto3_qtd,
            altura: os.veiculoSelecionado.teto3_altura,
        },
        {
            chave: 'complemento1',
            titulo: 'Complemento 1',
            quantidade: os.veiculoSelecionado.complemento1_qtd,
            altura: os.veiculoSelecionado.complemento1_altura,
        },
        {
            chave: 'complemento2',
            titulo: 'Complemento 2',
            quantidade: os.veiculoSelecionado.complemento2_qtd,
            altura: os.veiculoSelecionado.complemento2_altura,
        },
        {
            chave: 'complemento3',
            titulo: 'Complemento 3',
            quantidade: os.veiculoSelecionado.complemento3_qtd,
            altura: os.veiculoSelecionado.complemento3_altura,
        },
    ];


    return (
        <div>
            <h2 className="h4 mb-3">Películas</h2>
            <div className="row">
                <div className="col-md-4 mb-3">
                    <label className="form-label">Selecione o Veículo</label>
                    <Select
                        placeholder="Selecione"
                        options={os.veiculos.map(veiculo => {
                            const nome = veiculo.nome || "Nome não disponível";
                            return { //exibir nome, modelo e ano
                                value: veiculo.id,
                                modelo: veiculo.modelo,
                                ano: veiculo.ano,
                                label: `${veiculo.modelo}`

                            };
                        })}
                        className="text-uppercase"
                        onChange={(selectedOption) => {
                            limpaSelects();
                            handleVeiculoChange(selectedOption?.value)
                        }}
                        value={os.veiculoSelecionado.id && { value: os.veiculoSelecionado.id, label: `${os.veiculoSelecionado.modelo}` }}

                    />
                </div>
                <div className="col-md-5 mb-3">
                    <label className="form-label">Aplicador</label>
                    <Select
                        placeholder="Selecione"
                        options={os.funcionarios.map(funcionario => {
                            const nome = funcionario.apelido || funcionario.nome || "Nome não disponível";
                            return { //exibir nome, modelo e ano
                                value: funcionario.id,
                                label: `${nome}`
                            };
                        }).sort((a, b) => a.label.localeCompare(b.label))}
                        //o default value teve ter value os.informacoesBasicas.aplicador e label o nome do funcionario que deve ser encontrado em os.funcionarios
                        value={
                            os.informacoesBasicas.aplicador ?
                                os.informacoesBasicas.aplicador.map(aplicadorID => ({
                                    value: aplicadorID,
                                    label: os.funcionarios.find((funcionario) => funcionario.id === parseInt(aplicadorID))?.apelido || os.funcionarios.find((funcionario) => funcionario.id === parseInt(aplicadorID))?.nome || "",
                                })) :
                                []
                        }
                        onChange={(selectedOptions) => {
                            const selectedFuncionarios = selectedOptions.map(option => option.value);
                            handleFieldChange("aplicador", selectedFuncionarios)
                        }
                        }
                        isMulti
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label">Placa</label>
                    <input
                        className="form-control"
                        type="text"
                        maxLength={15}
                        onChange={e => handleFieldChange("placa", e.target.value.toUpperCase())}
                        defaultValue={os.informacoesBasicas.placa}
                        onKeyUp={handleKeyUp}
                    />
                </div>

                {secoesVidro.map((secao) =>
                    os.veiculoSelecionado && secao.altura > 0 ? (
                        <div className="row bg-light pt-2 pb-2 mt-2" key={secao.chave}>
                            <div className="col-md-4 mb-3">
                                <label className="text-left text-uppercase">{secao.titulo}</label>
                                <div className='input-group'>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={formataMedidas(secao.chave).replace(".", ",")}
                                        readOnly
                                    />
                                    <span className="input-group-text">{calculaArea(secao.chave).replace(".", ",")}m²</span>
                                </div>
                            </div>
                            <div className="col-md-2 mb-3">
                                <label className="text-left text-uppercase">Película</label>
                                <Select
                                    placeholder="Selecione"
                                    key={`select- ${secao.chave}`}
                                    options={os.peliculas.map(pelicula => ({
                                        value: pelicula.id,
                                        label: pelicula.nome || "Nome não disponível",
                                    }))}
                                    onChange={(selectedOption) =>
                                        handlePeliculaChange(secao.chave, selectedOption?.value, calculaArea(secao.chave))
                                    }
                                    value={{
                                        value: os.peliculasSelecionadas[secao.chave]?.id,
                                        label: os.peliculas.find(pelicula => pelicula.id === os.peliculasSelecionadas[secao.chave]?.id)?.nome
                                    }}
                                />
                            </div>
                            <div className="col-md-2 mb-3">
                                <label className="text-left text-uppercase">Quantidade</label>
                                <div className='input-group'>
                                    <input
                                        className="form-control"
                                        type="number"
                                        value={os.peliculasSelecionadas[secao.chave]?.qtd || secao.quantidade}
                                        onChange={(event) => {
                                            handleQuantidadeVidro(secao.chave, event.target.value)
                                            handlePeliculaChange(secao.chave, os.peliculasSelecionadas[secao.chave].id, calculaArea(secao.chave)) //precisa chamar pra calcular o valor

                                        }}
                                        min={1}
                                        disabled={os.peliculasSelecionadas[secao.chave] != null ? false : true}

                                    />
                                </div>
                            </div>
                            <div className="col-md-2 mb-3">
                                <label className="text-left text-uppercase">Valor</label>
                                <div className='input-group'>
                                    <CurrencyInput
                                        className="form-control"
                                        type="text"
                                        defaultValue={os.peliculasSelecionadas[secao.chave]?.valor_vidro || "0,00"}
                                        onChange={(event, value) => {
                                            handlePeliculaPrecoChange(secao.chave, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 mb-3">
                                <div className="col-md-1 mb-3">
                                    <label className="text-left text-uppercase">Resetar</label>
                                    <span className="btn btn-primary" onClick={() => { handleReset(secao.chave) }}>
                                        <i className="fas fa-undo"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : null
                )}


            </div>
        </div>
    );
}

export default PeliculaEdit;