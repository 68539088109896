const formatarCelular = (rawNumber) => {
    let cleaned = rawNumber.replace(/\D/g, '');

    // Verificar se é um número de celular sem o código de área
    if (cleaned.length === 9) {
        cleaned = cleaned.slice(0, 5) + '-' + cleaned.slice(5);
    }

    if (cleaned.length <= 2) {
        return '(' + cleaned;
    } else if (cleaned.length <= 7) {
        return '(' + cleaned.slice(0, 2) + ')' + cleaned.slice(2);
    } else {
        return '(' + cleaned.slice(0, 2) + ')' + cleaned.slice(2, 7) + '-' + cleaned.slice(7, 11);
    }
}

const formatarTelefone = (rawNumber) => {
    let cleaned = rawNumber.replace(/\D/g, '');

    // Verificar se é um número fixo sem o código de área
    if (cleaned.length === 8) {
        cleaned = cleaned.slice(0, 4) + '-' + cleaned.slice(4);
    }

    if (cleaned.length <= 2) {
        return '(' + cleaned;
    } else if (cleaned.length <= 6) {
        return '(' + cleaned.slice(0, 2) + ')' + cleaned.slice(2);
    } else {
        return '(' + cleaned.slice(0, 2) + ')' + cleaned.slice(2, 6) + '-' + cleaned.slice(6, 10);
    }
}

export { formatarCelular, formatarTelefone };