import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NovaCategoria() {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	const [status, setStatus] = useState({ success: false, message: '' })

	const onSubmit = async (data) => {
		
		window.scrollTo(0, 0);
		try {
			console.log(data);
			const response = await Api.post('NovaCategoria', JSON.stringify(data), {
				headers: { 'Content-Type': 'application/json' } //o formato padrão do axios é x-www-form-urlencoded, entao precisa definir que é json pro backend receber certinho
			});
			setStatus(response.data)

			//Resetar o formulario após enviar e der sucesso
			if (response.data.success === true) {
				reset()
			}


		} catch (error) {
			console.log(error)
			if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
				setStatus({
					success: false,
					message: error.response.data.error,
				});
			} else { // Mostra uma mensagem de erro genérica ao usuário
				setStatus({
					success: false,
					message: "Ocorreu um erro ao cadastrar o cliente. Tente novamente mais tarde.",
				});
			}

		}

	};

	return (
		<div className='page-full'>
			<form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
				<div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
					<FontAwesomeIcon icon="fa-solid fa-arrow-left" />
				</div>
				<div className="pt-5 m-5">
					<div className="row d-flex justify-content-center align-items-top">
						<div className='col-md-2'>
							<span className="ant-avatar-circle nex-avatar default circle-icon">
								<FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
							</span>
						</div>
						<div className="col-md-8 mb-5">
							<h1 className="h3 mb-3 fw-bold">Informações da Categoria</h1>

							{status.message && (
								<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
									<div className="alert-icon">
										<i className="far fa-fw fa-bell"></i>
									</div>
									<div className="alert-message">
										{status.message}
									</div>
								</div>
							)}
							<div className="mb-3">
								<label className="form-label">Categoria</label>
								<input type="text" className="form-control" {...register("nome", { required: true })} />
								{errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
							</div>
						</div>
					</div>
				</div>
				<div className="footer-page-full">
					<div className="footer-client">
						<button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
						<button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default NovaCategoria;
