import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import Api from '../../Api';
import { useNavigate } from 'react-router-dom';


const LoginPage = () => {
	const navigate = useNavigate();

	const [cpf, setCPF] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState({ success: false, message: '' });

	const handleLogin = async () => {
		const data = {
			cpf: cpf,
			senha: password
		};

		try {
			const response = await Api.post('Login', JSON.stringify(data), {
				headers: { 'Content-Type': 'application/json' }
			});

			if (response.data.success === true) {
				setStatus(response.data);
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('cpf', cpf);
				localStorage.setItem('nivel', response.data.nivel);
				localStorage.setItem('nome', response.data.nome);
				window.location.href = '/home';
			}
		} catch (error) {
			console.log(error.response.data.error)
			setStatus({ success: false, message: error.response.data.error });
		}
	};

	return (
		<div className='page-full'>
			<form>
				<div className="pt-5 m-5">
					<div className="row d-flex justify-content-center align-items-top">
						<div className='col-md-5 mb-5'>
							<h1 className="h3 mb-3 fw-bold">Login</h1>

							{status.message && (
								<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
									<div className="alert-icon">
										<i className={`far fa-fw ${status.success ? 'fa-check-circle' : 'fa-bell'}`}></i>
									</div>
									<div className="alert-message">
										{status.message}
									</div>
								</div>
							)}

							<div className="mb-3">
								<label className="form-label">CPF</label>
								<IMaskInput
									mask="000.000.000-00"
									type="text"
									inputRef={cpf}
									onAccept={(value) => setCPF(value, { shouldValidate: true })}
									className="form-control"
								/>
							</div>

							<div className="mb-3">
								<label className="form-label">Senha</label>
								<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
							</div>

							<button type="button" className="btn btn-light button-large fw-bold" onClick={handleLogin}> LOGIN </button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default LoginPage;
