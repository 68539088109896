import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import Api from '../../Api';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { TextareaAutosize } from '@mui/material';
import CurrencyInput from '../../Components/CurrencyInput';

function NovaConta() {
    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })

    const onSubmit = async (data) => {
        
		window.scrollTo(0, 0);
        console.log(data);
        try {

            const formData = new FormData();
            formData.append('produto', JSON.stringify(data.produto));
            formData.append('fornecedor', JSON.stringify(data.fornecedor));
            formData.append('arquivo', data.anexo[0]);
            formData.append('descricao', data.descricao);
            formData.append('tipo', data.tipo);
            formData.append('quantidade', data.quantidade ? data.quantidade.replace(",", ".") : null);
            formData.append('valor', data.valor.replace(".", "").replace(",", ".").replace("R$ ", ""));

            const response = await Api.post('NovaConta', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setStatus(response.data)

            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                reset()
                //refresh
                window.location.reload();
            }



        } catch (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar a conta. Tente novamente mais tarde.",
                });
            }

        }

    };
    const [produtos, setProdutos] = useState([])
    const [fornecedores, setFornecedores] = useState([])

    useEffect(() => {
        //buscar os produtos
        const getProdutos = async () => {
            try {
                const response = await Api.get('ListarProdutos');
                if (response.status === 200) {
                    const produtos = response.data.map((produto) => {
                        return {
                            id: produto.id,
                            nome: produto.nome,
                            codigo_produto: produto.preco,
                            valor_venda: produto.quantidade,
                            descricao: produto.estoque,
                            categoria: produto.categoria,
                            tipo: produto.tipo
                        }
                    })
                    setProdutos(produtos)
                    console.log(produtos)
                }

            } catch (error) {
                console.log(error)
            }
        }
        getProdutos()

        //buscar os fornecedores
        const getFornecedores = async () => {
            try {
                const response = await Api.get('ListarFornecedores');
                if (response.status === 200) {
                    const fornecedores = response.data.map((fornecedor) => {
                        return {
                            id: fornecedor.id,
                            nome: fornecedor.nome,
                            nome_contato: fornecedor.nome_contato,
                            nome_fantasia: fornecedor.nome_fantasia
                        }
                    })
                    setFornecedores(fornecedores)
                    console.log(fornecedores)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getFornecedores()
    }, [])


    const [valor, setValor] = useState('');
    const handleValorChange = (valor) => {
        setValor(valor);
        setValue('valor', valor)
    }

    const handleProdutoChange = (produto) => {
        const produtoFull = produtos.find((p) => p.id === produto.value)
        setValue('produto', produtoFull)
        setValue('descricao', "Compra de " + produtoFull.nome)
        console.log("Compra de " + produtoFull.nome)
    }

    const handleFornecedorChange = (fornecedor) => {
        const fornecedorFull = fornecedores.find((f) => f.id === fornecedor.value)
        setValue('fornecedor', fornecedorFull)
    }



    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Entradas e saídas</h1>

                            <div>
                                <label className="form-label">
                                    Selecione o tipo:
                                    <RadioGroup className='d-flex flex-row'>
                                        <FormControlLabel
                                            {...register("tipo")}
                                            value="despesa"
                                            control={<Radio />}
                                            label="Despesa"
                                            className='mr-3'
                                        />
                                        <FormControlLabel
                                            {...register("tipo")}
                                            value="receita"
                                            control={<Radio />}
                                            label="Receita"
                                            className='mr-3'
                                        />
                                    </RadioGroup>
                                </label>
                            </div>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            {watch('tipo') === 'despesa' &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o produto</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={produtos.map(produto => ({
                                                    value: produto.id,
                                                    label: produto.nome
                                                }))}
                                                onChange={handleProdutoChange}
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Selecione o fornecedor</label>
                                            <Select
                                                placeholder="Selecione"
                                                options={fornecedores.map(fornecedor => ({
                                                    value: fornecedor.id,
                                                    label: fornecedor.nome_fantasia || fornecedor.nome
                                                }))}
                                                onChange={handleFornecedorChange}
                                            />
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            {watch('produto') && watch('produto').tipo == 'material' ?
                                                <>
                                                    <label className="form-label">Metros²</label>
                                                    <input type="text" pattern="[0-9.,]+" className="form-control" {...register("quantidade")} />
                                                </>
                                                :
                                                <>
                                                    <label className="form-label">Quantidade</label>
                                                    <input type="text" className="form-control" {...register("quantidade")} />
                                                </>
                                            }

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} defaultValue={watch("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor*</label>
                                            <CurrencyInput defaultValue={valor} onChange={handleValorChange} />
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                </>
                            }
                            {watch('tipo') === 'receita' &&
                                <>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label">Descrição</label>
                                            <TextareaAutosize className="form-control" {...register("descricao")} />
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label">Valor</label>
                                            <CurrencyInput defaultValue={valor} onChange={handleValorChange} />
                                        </div>
                                        <div className='col-md-5 mb-3'>
                                            <label className="form-label">Anexo</label>
                                            <input type="file" className="form-control" {...register("anexo")} />
                                        </div>
                                    </div>
                                </>
                            }












                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NovaConta
