import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parse, isValid, format, parseISO, addMonths, subDays } from 'date-fns';
import CurrencyInput from '../../Components/CurrencyInput';



function ListarContas() {

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState({});

    const [totalShow, setTotalShow] = useState({});

    const deleteRecord = async (data) => {
        try {
            const response = await Api.delete(`DeletarConta/${data}`);
            setShowModalConfirm(false)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
            }
        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao apagar o usuário. Tente novamente mais tarde."
            })
        }

    };

    function parseCustomDate(dateString) {
        const formatsToTry = ['yyyy-MM-dd', 'dd-MM-yyyy'];

        for (const formatString of formatsToTry) {
            const parsedDate = parse(dateString, formatString, new Date());

            if (isValid(parsedDate)) {
                return parsedDate;
            }
        }

        return null;
    }



    const [contas, setContas] = useState([]);
    const [contasShow, setContasShow] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarContas');
                setContas(response.data.data);
                setContasShow(response.data.data);

                console.log(response.data.data)
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [status]);

    useEffect(() => { //useeffect pra mostrar só o mes atual quando carregar a página
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        // Calculate the last day of the current month
        const nextMonth = addMonths(firstDayOfMonth, 1);
        const lastDayOfMonth = subDays(nextMonth, 1);

        setFilterData({
            inicio: format(firstDayOfMonth, 'yyyy-MM-dd'),
            fim: format(lastDayOfMonth, 'yyyy-MM-dd'),
        });
        filtrarLista();
    }, [contas]);

    useEffect(() => { //useeffect pro total no fim da página
        let totalReceitas = 0;
        let totalDespesas = 0;
        let total = 0;
        contasShow.forEach(conta => {
            if (conta.tipo === 'receita') {
                totalReceitas += Number(conta.valor);
            } else {
                totalDespesas += Number(conta.valor);
            }
        });
        total = totalReceitas - totalDespesas;
        setTotalShow({
            receitas: totalReceitas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }),
            despesas: totalDespesas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }),
            total: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }),
        });
    }, [contasShow, filterData]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <Link to={`/editar-conta/${row.id}`}>{row.id}</Link> ?? '-'
                    },

                    {
                        Header: "Descrição",
                        accessor: row => row.descricao || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Valor",
                        accessor: row => `${Number(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }) ?? '-'}`,
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`} style={{ color: original.tipo === 'despesa' ? 'red' : 'green' }}>
                                {value || '-'}
                            </Link>
                        ),
                    },
                    {
                        Header: "Data",
                        accessor: row => {
                            const date = parseCustomDate(row.data);

                            if (date) {
                                return format(date, 'dd/MM/yyyy');
                            } else {
                                return '-';
                            }
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Tipo",
                        accessor: row => row.tipo.charAt(0).toUpperCase() + row.tipo.slice(1) || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <Link to={`/editar-conta/${original.id}`}>{value || '-'}</Link>
                        ),
                    },
                    {
                        Header: "Excluir",
                        accessor: row =>
                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link className="fs-4" onClick={() => { setModalData({ nome: row.nome, id: row.id }); setShowModalConfirm(true); }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                            </div>
                    }
                ]
            }
        ],
        [contas, contasShow]
    );



    const isDateInRange = (date, startDate, endDate) =>
        (!startDate || date >= startDate) && (!endDate || date <= endDate);

    const isCompraMatch = (produto, compra) =>
        !compra || (compra === '1' && produto) || (compra === '0' && !produto);

    const isSameTipo = (tipoConta, tipo) =>
        !tipo || tipoConta == tipo;


    const filtrarLista = () => {
        const { inicio, fim, compra, tipo, valorMin, valorMax } = filterData;
        console.log(filterData);

        const selectedStartDate = inicio ? parseISO(inicio) : null;
        const selectedEndDate = fim ? parseISO(fim) : null;

        const filteredData = contas.filter(conta => {
            const osStartDate = parseISO(conta.data);

            const isDateInRangeCondition =
                isDateInRange(osStartDate, selectedStartDate, selectedEndDate);

            const isCompraMatchCondition = isCompraMatch(conta.produto, compra);

            const isSameTipoCondition = isSameTipo(conta.tipo, tipo);

            const isValueInRangeCondition =
                (!valorMin || Math.round(conta.valor) >= Math.round(valorMin)) &&
                (!valorMax || Math.round(conta.valor) <= Math.round(valorMax));

            return (
                isDateInRangeCondition &&
                isCompraMatchCondition &&
                isSameTipoCondition &&
                isValueInRangeCondition
            );
        });

        setContasShow(filteredData);
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de Contas</h1>
                    </div>
                    <div>
                        <Link to="/cadastrar-conta" className='btn btn-primary fs-5 me-2'><FontAwesomeIcon icon="fa-solid fa-plus" /> | Nova</Link>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={contasShow} />
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex align-items-center">
                                        <span className="me-2">Receitas:</span>
                                        <span className="fs-4 fw-bold">{totalShow.receitas}</span>
                                    </div>
                                    <div className="d-flex align-items-center ms-4">
                                        <span className="me-2">Despesas:</span>
                                        <span className="fs-4 fw-bold">{totalShow.despesas}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja deletar o registro <b>{modalData.id}</b> ?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { deleteRecord(modalData.id) }}>Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => setShowModalConfirm(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Tipo</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    value={filterData.tipo}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            tipo: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    <option value="receita">Receita</option>
                                    <option value="despesa">Despesa</option>
                                </select>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Categoria</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    value={filterData.conta}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            compra: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    <option value="1">Compra de produto</option>
                                    <option value="0">Demais contas</option>
                                </select>

                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Valor min</label>
                                <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                    onChange={(event, value) => {
                                        setFilterData({
                                            ...filterData,
                                            valorMin: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                        })
                                    }}
                                    defaultValue={filterData.valorMin || '0'}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>

                            <div className="form-group">
                                <label className="form-label">Valor max</label>
                                <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                    onChange={(event, value) => {
                                        setFilterData({
                                            ...filterData,
                                            valorMax: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                        })
                                    }}
                                    defaultValue={filterData.valorMax || '0'}
                                />
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista() }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setContasShow(contas); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>
            </Modal >
        </>


    );

}

export default ListarContas