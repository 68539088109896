import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IMaskInput } from "react-imask";
import Api from '../../Api';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NovoUsuario() {
    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })


    const telefone = useRef(null);
    const cpf = useRef(null);
    const onSubmit = async (data) => {
        
        window.scrollTo(0, 0);
        try {
            // CAMPOS COM MASK
            data.telefone = telefone.current.value
            data.cpf = cpf.current.value
            console.log(data)
            const response = await Api.post('NovoUsuario', JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' } //o formato padrão do axios é x-www-form-urlencoded, entao precisa definir que é json pro backend receber certinho
            });
            setStatus(response.data)

            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                reset()
            }


        } catch (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar o usuario. Tente novamente mais tarde.",
                });
            }

        }


    };

    // const options = [ //substituir quando os níveis estiverem definidos no banco de dados
    //     { value: '1', label: 'Administrador' },
    //     { value: '2', label: 'Usuário' },
    // ]

    const [options, setOptions] = useState([]);
    // localizar a opção pelo value
    const selectedOption = options.find(option => option.value === watch("nivel"));

    const handleNivelChange = (selectedOption) => {
        console.log(selectedOption)
        setValue('nivel', selectedOption.value);
    };

    useEffect(() => {
        const fetchNiveis = async () => {
            try {
                const clientesResponse = await Api.get("ListarNiveis");
                console.log(clientesResponse.data.data)
                setOptions(clientesResponse.data.data)
            } catch (error) {
                console.log(error);
            }
        };
        fetchNiveis();

    }, []);

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-regular fa-user" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Usuário</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}




                            <div className="mb-3">
                                <label className="form-label">Nome Completo*</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Telefone / Celular*</label>
                                    <IMaskInput mask="(00) 00000-0000" type="text" inputRef={telefone} {...register("telefone")} className="form-control" />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Email*</label>
                                    <input type="email" className="form-control" {...register("email")} />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Nível*</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={options.map((option) => {
                                            const id = option.id;
                                            const nome = option.nome;
                                            return {
                                                value: id,
                                                label: nome,
                                            };
                                        })}
                                        value={selectedOption}
                                        onChange={handleNivelChange}
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">CPF*</label>
                                    <IMaskInput
                                        mask="000.000.000-00"
                                        type="text"
                                        inputRef={cpf}
                                        value={watch('cpf')}
                                        onAccept={(value) => setValue('cpf', value.replace(/[^\d]/g, ''), { shouldValidate: true })}
                                        className="form-control"
                                    />

                                    {errors.cpf && errors.cpf.type === 'validate' && (
                                        <span className="text-danger">CPF inválido</span>
                                    )}
                                    {errors.cpf && errors.cpf.type === 'required' && (
                                        <span className="text-danger">Este campo é obrigatório</span>
                                    )}

                                </div>

                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Senha*</label>
                                    <input type="password" className="form-control" {...register("senha")} />
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NovoUsuario
