import React from 'react'
import { Outlet, Navigate, Routes, Route } from 'react-router-dom'

// PAGES
import Home from './Pages/Home';

//clientes
import NovoCliente from './Pages/Clientes/NovoCliente';
import ListarClientes from './Pages/Clientes/ListarClientes';
import EditarCliente from './Pages/Clientes/EditarCliente';

//produtos
import NovoProduto from './Pages/Produtos/NovoProduto';
import ListarProdutos from './Pages/Produtos/ListarProdutos';
import EditarProduto from './Pages/Produtos/EditarProduto';

//servicos
import NovoServico from './Pages/Servicos/NovoServico';
import ListarServicos from './Pages/Servicos/ListarServicos';
import EditarServico from './Pages/Servicos/EditarServico';

//veiculos
import NovoVeiculo from './Pages/Veiculos/NovoVeiculo';
import ListarVeiculos from './Pages/Veiculos/ListarVeiculos';
import EditarVeiculo from './Pages/Veiculos/EditarVeiculo';

//os
import NovaOS from './Pages/OS/NovaOS';
import ListarOS from './Pages/OS/ListarOS';
import EditarOS from './Pages/OS/EditarOS/EditarOS';

//agendamento
import ListarAgendamentos from './Pages/Agendamentos/ListarAgendamentos';
import Api from './Api';


//esse comentario aqui é como tava antes de eu mexer no controle de acesso por nivel
// const PrivateRoutes = () => {
//     let auth = { token: localStorage.getItem('token') } // pega o token do navegador
//     return (
//         auth.token ? <Outlet /> : <Navigate to="/login" />
//     )
// }




const PrivateRoutes = () => {
    const auth = {
        token: localStorage.getItem('token'),
        nivel: localStorage.getItem('nivel')
    }; // pega o token e nível do navegador


    // Verifica se existe um token e nível de usuário válido
    if (auth.token && auth.nivel) { //tratar melhor o token aqui (requisicao inves de existencia)
        // Aqui você pode definir a lógica para controlar o acesso às rotas
        // com base no nível do usuário

        // Permite acesso a todas as rotas se o nível for "Administrador"
        if (auth.nivel == '1') {
            return <Outlet />;
        }

        if (auth.nivel == '2') { //rotas para o nivel 2 (funcionario)
            return (
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />

                    <Route path="/cadastrar-cliente" element={<NovoCliente />} />
                    <Route path="/listar-clientes" element={<ListarClientes />} />
                    <Route path="/editar-cliente/:id" element={<EditarCliente />} />

                    <Route path="/cadastrar-produto" element={<NovoProduto />} />
                    <Route path="/listar-produtos" element={<ListarProdutos />} />
                    <Route path="/editar-produto/:id" element={<EditarProduto />} />

                    <Route path="/cadastrar-servico" element={<NovoServico />} />
                    <Route path="/listar-servicos" element={<ListarServicos />} />
                    <Route path="/editar-servico/:id" element={<EditarServico />} />

                    <Route path="/cadastrar-veiculo" element={<NovoVeiculo />} />
                    <Route path="/listar-veiculos" element={<ListarVeiculos />} />
                    <Route path="/editar-veiculo/:id" element={<EditarVeiculo />} />

                    <Route path="/cadastrar-os" element={<NovaOS />} />
                    <Route path="/listar-os" element={<ListarOS />} />
                    <Route path="/editar-os/:id" element={<EditarOS />} />

                    <Route path="/listar-agendamentos" element={<ListarAgendamentos />} />
                </Routes>
            );
        }
    }

    // Redireciona para a página de login se não houver token ou nível válido
    return <Navigate to="/login" />;
};

export default PrivateRoutes