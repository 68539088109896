import React, { useState, useEffect } from "react";
import FormFieldsEdit from "./FormFieldsEdit";
import OS from "./OSEdit";
import Api from "../../../Api";
import { useParams } from 'react-router-dom';
import PeliculaEdit from "./PeliculaEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "../../../Components/CurrencyInput";
import { Modal } from "react-bootstrap";
import Certificado from "../OScomponents/pdfUtils";
import { useForm } from 'react-hook-form';
import TextareaAutosize from "react-textarea-autosize";
import Select from 'react-select';


function EditarOS() {
    const { watch, setValue } = useForm()

    const [os, setOs] = useState(new OS());
    const { id } = useParams(); // Obtém o id do cliente a partir da URL

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});


    useEffect(() => { //buscando informações dos clientes e funcionários

        const fetchClientes = async () => {
            try {
                const clientesResponse = await Api.get("ListarClientes");
                setOs((prevOs) => ({
                    ...prevOs,
                    clientes: clientesResponse.data,
                }));
            } catch (error) {
                console.log(error);
            }
        };
        fetchClientes();

        const fetchFuncionarios = async () => {
            try {
                const funcionariosResponse = await Api.get("ListarFuncionarios");
                setOs((prevOs) => ({
                    ...prevOs,
                    funcionarios: funcionariosResponse.data,
                }));
            } catch (error) {
                console.log(error);
            }
        };
        fetchFuncionarios();

        const fetchProdutos = async () => {
            try {
                const response = await Api.get("ListarProdutos?modo=OS")
                //deve fazer o set dos produtos que o campo "tipo" for "unit"
                const filteredProdutos = response.data
                    .filter((produto) => produto.tipo === "unit")
                    .map((produto) => ({
                        ...produto,
                        valor_venda: `R$ ${parseFloat(produto.valor_venda).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`,
                    }));

                setOs((prevOs) => ({
                    ...prevOs,
                    produtos: filteredProdutos,
                }));
            } catch (error) {
                console.log(error);
            }
        }
        fetchProdutos();

        const fetchServicos = async () => {
            try {
                const response = await Api.get("ListarServicos")
                const filteredServicos = response.data
                    .map((servico) => ({
                        ...servico,
                        valor: `R$ ${parseFloat(servico.valor).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`,
                    }));
                setOs((prevOs) => ({
                    ...prevOs,
                    servicos: filteredServicos,
                }));
            } catch (error) {
                console.log(error);
            }
        }
        fetchServicos();

        const fetchVeiculos = async () => {
            try {
                const response = await Api.get("ListarVeiculos")
                setOs((prevOs) => ({
                    ...prevOs,
                    veiculos: response.data,
                }));
            } catch (error) {
                console.log(error);
            }
        }
        fetchVeiculos();

        const fetchPeliculas = async () => {
            try {
                const response = await Api.get("ListarProdutos?modo=OS")
                const filter = response.data.filter((produto) => produto.tipo === "material")
                setOs((prevOs) => ({
                    ...prevOs,
                    peliculas: filter,
                }));
            } catch (error) {
                console.log(error);
            }
        }
        fetchPeliculas();

        const fetchOS = async () => {
            try {
                const osResponse = await Api.get(`BuscarOS?id=${id}`);

                handleFieldChange("idOS", osResponse.data.data[0].idOS);
                const inicio = osResponse.data.data[0].data_inicio ? osResponse.data.data[0].data_inicio.split("T")[0] : null;
                if (osResponse.data.data[0].data_fim === null) {
                    handleFieldChange("data_fim", "");
                } else {

                    const fim = osResponse.data.data[0].data_fim.split("T")[0];
                    handleFieldChange("data_fim", fim);

                }
                handleFieldChange("data_inicio", inicio);
                handleFieldChange("id_cliente", osResponse.data.data[0].id_cliente);
                handleFieldChange("id_funcionario", osResponse.data.data[0].id_funcionario);
                handleFieldChange("observacao", osResponse.data.data[0].observacao);
                handleFieldChange("anexos", osResponse.data.data[0].file);

                handleFieldChange("placa", osResponse.data.data[0].placa);

                if (osResponse.data.data[0].desconto !== null) {
                    handleFieldChange("desconto", formatarValorMonetario(osResponse.data.data[0].desconto));
                }

                if (osResponse.data.data[0].acrescimo !== null) {
                    handleFieldChange("acrescimo", formatarValorMonetario(osResponse.data.data[0].acrescimo));
                    setValue('acrescimo', osResponse.data.data[0].acrescimo);
                    console.log(osResponse.data.data[0].acrescimo);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchOS();

        const fetchAplicador = async () => {
            try {
                const aplicadorResponse = await Api.get(`BuscarAplicador?id=${id}`);
                console.log("id aplicador:" + aplicadorResponse.data.data[0])
                const aplicador = aplicadorResponse.data.data[0].id_funcionario;
                handleFieldChange("aplicador", aplicador?.split(','));


                // console.log('value: ', os.informacoesBasicas.aplicador)
                // console.log('label: ', os.funcionarios.find(funcionario => funcionario.id == os.informacoesBasicas.aplicador)?.nome)
            } catch (error) {
                console.log(error);
            }
        };
        fetchAplicador();

    }, []);

    const formatarValorMonetario = (valor) => {
        // console.log("VALOR", valor);
        const valorNumerico = parseFloat(valor);

        if (isNaN(valorNumerico)) {
            return '';
        }

        const valorFormatado = valorNumerico.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
        return valorFormatado;
    }


    useEffect(() => {
        const fetchProdutosOS = async () => {
            try {
                const produtosOSResponse = await Api.get(`BuscarProdutosOS?id=${id}`);
                const produtos = produtosOSResponse.data.data;
                ;

                const novosProdutosSelecionados = produtos.map((produto) => ({
                    id: produto.id_produto,
                    quantidade: produto.quantidade,
                    valor_venda: formatarValorMonetario(produto.subtotal / produto.quantidade),
                    descricao: produto.descricao,
                    desconto: formatarValorMonetario(produto.desconto),
                }));

                setOs((prevOs) => ({
                    ...prevOs,
                    produtosSelecionados: novosProdutosSelecionados,
                }));
            } catch (error) {
                console.log(error);
            }
        };

        fetchProdutosOS();


        //fzer o mesmo com os servicos
        const fetchServicosOS = async () => {
            try {
                const servicosOSResponse = await Api.get(`BuscarServicosOS?id=${id}`);
                const servicos = servicosOSResponse.data.data;

                const novosServicosSelecionados = servicos.map((servico) => {
                    const funcionariosIds = servico.funcionario ? servico.funcionario.split(',') : [];
                    return {
                        id: servico.id_servico,
                        quantidade: servico.quantidade,
                        valor: formatarValorMonetario(servico.subtotal / servico.quantidade),
                        descricao: servico.descricao,
                        desconto: formatarValorMonetario(servico.desconto),
                        funcionario: servico.funcionario,
                        funcionarios: funcionariosIds,
                    }
                });

                setOs((prevOs) => ({
                    ...prevOs,
                    servicosSelecionados: novosServicosSelecionados,
                }));
            } catch (error) {
                console.log(error);
            }
        };
        fetchServicosOS();
    }, []);

    const calculaArea = (tipo) => {
        const veiculo = os.veiculoSelecionado;
        //para calcular a area deve procurar nas propriedades o {tipo}_qtd e {tipo}_altura e {tipo}_largura
        const qtd = veiculo[`${tipo}_qtd`];
        const altura = veiculo[`${tipo}_altura`];
        const largura = veiculo[`${tipo}_largura`];
        const area = qtd * altura * largura;

        return area.toFixed(2); //retorna a area com 2 casas decimais
    };


    useEffect(() => {
        const fetchPeliculasOS = async () => {
            try {
                const peliculasOSResponse = await Api.get(`BuscarPeliculasOS?id=${id}`);
                const peliculas = peliculasOSResponse.data.data;
                let veiculo = null;

                if (peliculasOSResponse.data.data[0].id_veiculo !== null) {
                    const veiculosResponse = await Api.get("ListarVeiculos");
                    const veiculos = veiculosResponse.data;

                    veiculo = veiculos.find((veiculo) => veiculo.id === peliculasOSResponse.data.data[0].id_veiculo);
                }


                const peliculasSelecionadas = {};

                peliculas.forEach((pelicula) => {
                    const subtotalNumerico = parseFloat(pelicula.subtotal);
                    const valorVidro = isNaN(subtotalNumerico) ? 0 : subtotalNumerico.toFixed(2);

                    peliculasSelecionadas[pelicula.vidro] = {
                        id: pelicula.id_material,
                        valor_vidro: valorVidro,
                        qtd: pelicula.quantidade,
                    };
                });

                // Verifica se as informações foram buscadas com sucesso
                if (veiculo && peliculasSelecionadas) {
                    setOs((prevOs) => ({
                        ...prevOs,
                        veiculoSelecionado: veiculo,
                        peliculasSelecionadas: peliculasSelecionadas,
                    }));
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchPeliculasOS();
    }, []);


    const handleQuantidadeVidro = (vidro, quantidade) => { //atualizar o vidro do campo ${vidro}_qtd pela quantidade recebida
        const updatedOs = { ...os };
        updatedOs.veiculoSelecionado[`${vidro}_qtd`] = quantidade;
        //deve criar .qtd em updatedOs.peliculasSelecionadas.${vidro}.qtd e atualizar o valor
        updatedOs.peliculasSelecionadas[vidro].qtd = quantidade;
        console.log(updatedOs)
        setOs(updatedOs);
    }



    //informacoes basicas
    const handleFieldChange = (fieldName, value) => { //interface para a classe OS
        console.log(fieldName, value)
        setOs((prevOs) => ({
            ...prevOs,
            informacoesBasicas: {
                ...prevOs.informacoesBasicas,
                [fieldName]: value,
            },
        }));
    };


    //produtos

    const handleProdutoChange = (id, field, value, produto) => { //id do produto, campo, quantidade
        const updatedOs = { ...os };
        const produtoIndex = updatedOs.produtosSelecionados.findIndex((produto) => produto.id === id);
        updatedOs.produtosSelecionados[produtoIndex][field] = value;
        if (field === 'id') {
            updatedOs.produtosSelecionados[produtoIndex].valor_venda = produto.valor_venda; // Atualizando o valor de venda
        }
        setOs(updatedOs);
    };

    const alterarPrecoProduto = (id, novoPreco) => {
        const updatedOs = { ...os };
        const produtoIndex = updatedOs.produtosSelecionados.findIndex((produto) => produto.id === id);
        updatedOs.produtosSelecionados[produtoIndex].valor_venda = novoPreco;
        setOs(updatedOs);
    };

    const handleRemoveProduto = (id) => {
        const updatedProdutosSelecionados = os.produtosSelecionados.filter((produto) => produto.id !== id);
        const updatedOs = { ...os, produtosSelecionados: updatedProdutosSelecionados };
        setOs(updatedOs);
    };

    const handleAddProduto = () => {
        // Lógica para adicionar um novo produto ao estado `os`
        const novoProduto = {
            id: "", // Defina o valor do ID do novo produto
            valor_venda: "", // Defina o valor do preço do novo produto
            quantidade: "", // Defina o valor da quantidade do novo produto
        };

        const updatedProdutosSelecionados = [...os.produtosSelecionados, novoProduto];
        const updatedOs = { ...os, produtosSelecionados: updatedProdutosSelecionados };
        setOs(updatedOs);
    };



    //servicos
    const handleServicoChange = (id, field, value, servico) => {
        console.log(id, field, value, servico);
        const updatedOs = { ...os };
        const servicoIndex = updatedOs.servicosSelecionados.findIndex((servico) => servico.id === id);
        updatedOs.servicosSelecionados[servicoIndex][field] = value;
        if (field === 'id') {
            updatedOs.servicosSelecionados[servicoIndex].valor = servico.valor; // Atualizando o valor de venda
        }
        setOs(updatedOs);
    };

    const handleRemoveServico = (id) => {
        const updatedServicosSelecionados = os.servicosSelecionados.filter((servico) => servico.id !== id);
        const updatedOs = { ...os, servicosSelecionados: updatedServicosSelecionados };
        setOs(updatedOs);
    };

    const handleAddServico = () => {
        // Lógica para adicionar um novo servico ao estado `os`
        const novoServico = {
            id: "", // Defina o valor do ID do novo servico
            valor: "", // Defina o valor do preço do novo servico
        };

        const updatedServicosSelecionados = [...os.servicosSelecionados, novoServico];
        const updatedOs = { ...os, servicosSelecionados: updatedServicosSelecionados };
        setOs(updatedOs);
    };

    const alterarPrecoServico = (id, novoPreco) => {
        const updatedOs = { ...os };
        const servicoIndex = updatedOs.servicosSelecionados.findIndex((servico) => servico.id === id);
        updatedOs.servicosSelecionados[servicoIndex].valor = novoPreco;
        setOs(updatedOs);
    };


    //veiculo adiciona no objeto "veiculoSelecionado" o id do veículo no campo 'id'
    const handleVeiculoChange = (id) => {
        const updatedOs = { ...os };
        const veiculo = os.veiculos.find((veiculo) => veiculo.id === id);
        setOs((prevOs) => ({
            ...prevOs,
            veiculoSelecionado: veiculo
        }));

        //limpar o objeto "peliculasSelecionadas" para não ter conflito de informações e inserir somente o id do veiculo
        updatedOs.peliculasSelecionadas = {};
        setOs((prevOs) => ({
            ...prevOs,
            peliculasSelecionadas: updatedOs.peliculasSelecionadas
        }));





    };

    const handlePeliculaChange = (vidro, id, area) => { //vai receber o nome do vidro e o id da pelicula respectiva, deve inserir no objeto peliculasSelecionadas
        const updatedOs = { ...os };
        const aux = updatedOs.peliculas.find((pelicula) => pelicula.id === id).valor_venda * area;

        const valor_vidro = aux.toFixed(2);
        updatedOs.peliculasSelecionadas[vidro] = { id, area, valor_vidro };
        setOs((prevOs) => ({
            ...prevOs,
            peliculasSelecionadas: updatedOs.peliculasSelecionadas
        }));

    };

    const handlePeliculaPrecoChange = (vidro, valor) => { //vai receber o nome do vidro e o valor da pelicula respectiva, deve inserir no objeto peliculasSelecionadas
        const valorNumerico = parseFloat(valor.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
        const updatedOs = { ...os };


        const valor_vidro = valor;
        updatedOs.peliculasSelecionadas[vidro].valor_vidro = valor_vidro;
        setOs((prevOs) => ({
            ...prevOs,
            peliculasSelecionadas: updatedOs.peliculasSelecionadas
        }));
    };


    //função para calcular o valor dos produtos da OS
    const calcularValorProdutos = () => {
        const produtos = os.produtosSelecionados.map((produto) => {
            if (produto.id && produto.valor_venda && produto.quantidade) {
                let valorVenda = parseFloat(produto.valor_venda.replace(/[^0-9.,]+/g, '').replace(',', '.'));

                if (produto.valor_venda[0] == 'R') {
                    valorVenda = parseFloat(produto.valor_venda.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                }

                if (produto.desconto != undefined && produto.desconto != null && produto.desconto != "") {
                    const desconto = parseFloat(produto.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                    return (valorVenda * produto.quantidade) - desconto;
                }
                return valorVenda * produto.quantidade;
            }
            return 0;
        });

        const valorProdutos = produtos.reduce((acc, cur) => acc + cur, 0).toFixed(2);
        return valorProdutos;
    };

    //função para calcular o valor total da OS
    const calcularValorServicos = () => {
        const servicos = os.servicosSelecionados.map((servico) => {
            if (servico.id && servico.valor && servico.quantidade) {
                const valor = parseFloat(servico.valor.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));

                if (servico.desconto != undefined && servico.desconto != null && servico.desconto != "") {
                    const desconto = parseFloat(servico.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                    return (valor * servico.quantidade) - desconto;
                }

                return valor * servico.quantidade;
            }
            return 0;
        });

        const valorServicos = servicos.reduce((acc, cur) => acc + cur, 0).toFixed(2);
        return valorServicos;
    };

    const calcularValorPeliculas = () => {
        const peliculasSelecionadas = os.peliculasSelecionadas;
        let valorTotal = 0;

        if (peliculasSelecionadas && typeof peliculasSelecionadas === 'object') {
            for (const chave in peliculasSelecionadas) {
                if (peliculasSelecionadas.hasOwnProperty(chave)) {
                    const item = peliculasSelecionadas[chave];
                    if (item && typeof item === 'object' && item.valor_vidro) {
                        if (item.valor_vidro[0] == "R") {
                            valorTotal += parseFloat(item.valor_vidro.replace(/[^0-9.,]+/g, '').replace(',', '.'));
                        } else {
                            valorTotal += parseFloat(item.valor_vidro);
                        }
                    }
                }
            }
        }

        return valorTotal;
    };



    const calcularValorTotal = () => {

        let acrescimo = 0; //setar para watch('acrescimo') somente se for numérico, nao nulo, nao indefinido e nao vazio
        if (watch('acrescimo') !== undefined && watch('acrescimo') !== null && watch('acrescimo') !== '') {
            acrescimo = parseFloat(watch('acrescimo'));
        }

        const valorProdutos = calcularValorProdutos();
        const valorServicos = calcularValorServicos();
        const valorPeliculas = calcularValorPeliculas();
        const valorTotal = parseFloat(valorProdutos) + parseFloat(valorServicos) + parseFloat(valorPeliculas) + acrescimo;

        return valorTotal.toFixed(2);
    };

    useEffect(() => {
        calcularValorTotal();
        console.log('acrescimo chegou: ', watch('acrescimo'))
    }, [os.produtosSelecionados, os.servicosSelecionados, os.peliculasSelecionadas, watch('acrescimo')]);

    const [status, setStatus] = useState({ success: false, message: '' })

    const submitOS = async () => {

        window.scrollTo(0, 0);
        //precisa ter cliente, funcionario, data de inicio e de fim
        if (!os.informacoesBasicas.id_cliente) {
            // alert("Preencha todos os campos obrigatórios: cliente, funcionario e datas de inicio e fim");
            setStatus({
                success: false,
                message: "Campos obrigatórios não preenchidos."
            })
            return;
        }
        const { informacoesBasicas, peliculasSelecionadas, produtosSelecionados, veiculoSelecionado, servicosSelecionados, produtos, servicos, clientes, funcionarios, peliculas } = os;

        const formData = new FormData();
        formData.append('informacoesBasicas', JSON.stringify(informacoesBasicas));
        formData.append('peliculasSelecionadas', JSON.stringify(peliculasSelecionadas));
        formData.append('produtosSelecionados', JSON.stringify(produtosSelecionados));
        formData.append('veiculoSelecionado', JSON.stringify(veiculoSelecionado));
        formData.append('servicosSelecionados', JSON.stringify(servicosSelecionados));
        formData.append('produtos', JSON.stringify(produtos));
        formData.append('servicos', JSON.stringify(servicos));
        formData.append('valor_total', calcularValorTotal());
        formData.append('arquivo', informacoesBasicas.anexos);
        formData.append('id', id);

        const data = { //definindo somente os itens necessários do objeto OS
            informacoesBasicas,
            peliculasSelecionadas,
            produtosSelecionados,
            veiculoSelecionado,
            servicosSelecionados,
            produtos,
            servicos,
            clientes,
            funcionarios,
            peliculas,
            valor_total: calcularValorTotal(),
            id: id
        };

        console.log(data);
        try {

            const response = await Api.put(`EditarOS`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            console.log(response);

            // if (response.data.success || response.status == 200) {
            //     window.location.href = '/listar-os'
            // }


        } catch (error) {
            console.log(error);
        }



        setModalData({ data: data });
        setShowModalConfirm(true);




    };

    const handleClienteChange = (selectedOption) => {
        handleFieldChange("id_cliente", selectedOption.value);
    };

    const handleFuncionarioChange = (selectedOption) => {
        handleFieldChange("id_funcionario", selectedOption.value);
    };
    const createOptions = (data, idKey, labelKey) => {
        return data.map((item) => {
            const label = item['apelido'] || item[labelKey];
            const telefone = item.telefone || "Indisponível";
            return {
                value: item[idKey],
                label: `${label}`,
            };
        });
    };
    const calculaValor_final = () => {
        const subtotal = calcularValorTotal();
        let desconto = 0;

        if (os.informacoesBasicas.desconto !== undefined && os.informacoesBasicas.desconto !== null && os.informacoesBasicas.desconto !== '') { //se tiver um desconto
            desconto = parseFloat(os.informacoesBasicas.desconto.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
            return (subtotal - desconto).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        }
        return subtotal.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    const handleReset = (arg) => {
        const updatedOs = { ...os };
        delete updatedOs.peliculasSelecionadas[arg];
        setOs(updatedOs);
    };



    return (


        <div className='page-full'>
            <form className="bg-white">

                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5 bg-white">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-1'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-table-cells-large" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações da OS <b>#{id}</b></h1>

                            <div>
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                {/* {os.informacoesBasicas.id_cliente && typeof os.informacoesBasicas === 'object' &&(  */}
                                <div className="row">
                                    <div className="col-md-5 mb-3">
                                        <label className="form-label">Nome do Cliente*</label>
                                        <Select
                                            placeholder="Selecione"
                                            options={os.clientes.map((cliente) => {
                                                const nome = cliente.nome || cliente.nome_fantasia || "Nome não disponível";
                                                const telefone = cliente.telefone || 'Indisponível'
                                                return {
                                                    value: cliente.id,
                                                    label: `${nome}`,
                                                };
                                            }).sort((a, b) => { return a.label.localeCompare(b.label) })}

                                            onChange={handleClienteChange}
                                            value={
                                                os.informacoesBasicas.id_cliente && {
                                                    value: os.informacoesBasicas.id_cliente,
                                                    label: `${os.clientes.find((cliente) => cliente.id === os.informacoesBasicas.id_cliente)?.nome || os.clientes.find((cliente) => cliente.id === os.informacoesBasicas.id_cliente)?.nome_fantasia || 'Nome não disponível'}`
                                                }
                                            }
                                        />

                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <label className="form-label">Nome do Atendente</label>
                                        <Select
                                            placeholder="Selecione"
                                            options={createOptions(os.funcionarios, "id", "nome").sort((a, b) => { return a.label.localeCompare(b.label) })}
                                            onChange={handleFuncionarioChange}
                                            value={os.informacoesBasicas.id_funcionario && { value: os.informacoesBasicas.id_funcionario, label: `${os.funcionarios.find((funcionario) => funcionario.id === os.informacoesBasicas.id_funcionario)?.apelido || os.funcionarios.find((funcionario) => funcionario.id === os.informacoesBasicas.id_funcionario)?.nome} ` }}
                                        />
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <label className="form-label">Data de Início</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="data_inicio"
                                            onChange={(e) => handleFieldChange("data_inicio", e.target.value)}
                                            value={os.informacoesBasicas.data_inicio}
                                        />
                                    </div>

                                </div>

                                <hr />

                                <PeliculaEdit
                                    os={os}
                                    handleVeiculoChange={handleVeiculoChange}
                                    handlePeliculaChange={handlePeliculaChange}
                                    handlePeliculaPrecoChange={handlePeliculaPrecoChange}
                                    handleFieldChange={handleFieldChange}
                                    handleQuantidadeVidro={handleQuantidadeVidro}
                                    handleReset={handleReset}

                                />


                                <FormFieldsEdit
                                    os={os}
                                    onFieldChange={handleFieldChange}

                                    handleProdutoChange={handleProdutoChange}
                                    handleRemoveProduto={handleRemoveProduto}
                                    handleAddProduto={handleAddProduto}
                                    alterarPrecoProduto={alterarPrecoProduto}

                                    handleServicoChange={handleServicoChange}
                                    handleRemoveServico={handleRemoveServico}
                                    handleAddServico={handleAddServico}
                                    alterarPrecoServico={alterarPrecoServico}

                                />

                                {/* {os.peliculasSelecionadas && typeof os.peliculasSelecionadas === 'object' && ( */}

                                <hr />


                                {/* )} */}
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Observação</label>
                                    <TextareaAutosize
                                        className="form-control"
                                        name="observacao"
                                        onChange={(e) => handleFieldChange("observacao", e.target.value)}
                                        value={os.informacoesBasicas.observacao}
                                        minRows={1}
                                        maxRows={7}
                                    />
                                </div>

                                <div className="row justify-content-end">
                                    <div className="col-md-3 mb-3 mt-4">
                                        <label>Desconto</label>
                                        <div className='input-group'>
                                            <CurrencyInput
                                                defaultValue={os.informacoesBasicas.desconto || ""}
                                                onChange={(event, value) =>
                                                    handleFieldChange('desconto', event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3 mt-4">
                                        <label>Acréscimo</label>
                                        <div className='input-group'>
                                            <CurrencyInput
                                                defaultValue={os.informacoesBasicas.acrescimo || ""}
                                                onChange={(event, value) => {
                                                    setValue('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                    handleFieldChange('acrescimo', event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                    console.log(event.replace(/[^0-9.,]+/g, '').replace('.', '').replace(',', '.'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 mt-4">
                                        <label>Subtotal</label>
                                        <div className='input-group'>
                                            <span className="input-group-text">R$</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={Number(calcularValorTotal()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }).replace("R$", "")}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="row justify-content-end">
                                    <div className="col-md-4 mb-3">
                                        <label><b>Total</b></label>
                                        <div className='input-group'>
                                            <span className="input-group-text">R$</span>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={calculaValor_final()}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <div type="submit" className="btn btn-light button-large fw-bold" onClick={() => submitOS()}> ATUALIZAR </div>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>

                <Modal centered show={showModalConfirm} onHide={() => { setShowModalConfirm(false); window.location.href = "/listar-os" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Deseja imprimir a OS?</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => { Certificado(modalData.data); setShowModalConfirm(false); window.location.href = "/listar-os" }}>Confirmar</button>
                        <button className="btn btn-secondary" onClick={() => { setShowModalConfirm(false); window.location.href = "/listar-os" }}>Cancelar</button>
                    </Modal.Footer>
                </Modal>
            </form>


        </div>
    );
}
export default EditarOS;