import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//import axios from 'axios';
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';


function NovaComissao() {
    const { id } = useParams(); // Obtém o id do produto a partir da URL
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });


    const tipos = ["porcentagem", "linear"].map((tipo, index) => ({ //id 1 é porcentagem id 2 é linear
        id: index + 1,
        nome: tipo
    }));


    const onSubmit = async (data) => {
        console.log(tipos)
        console.log(data)
        data.tipo = tipos.find(tipo => tipo.id == data.tipo).nome;
        data.comissao = data.comissao.replace(",", ".")
        window.scrollTo(0, 0);
        try {
            data.id = id;
            const response = await Api.post(`NovaComissao`, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            console.log(response);
            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-comissoes'
            }


        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar o comissao. Tente novamente mais tarde.",
                });
            }
        }
    };


    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Comissao</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Nome</label>
                                    <input type="text" className="form-control" {...register("nome", { required: true })} />
                                    {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>

                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Comissao</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register("comissao", {
                                            required: true,
                                            pattern: {
                                                value: /^[0-9]+([.,][0-9]+)?$/,
                                                message: "Por favor, insira um número válido.",
                                            },
                                        })}
                                    />
                                    {errors.comissao && (
                                        <span className="text-danger">{errors.comissao.message}</span>
                                    )}
                                    {errors.comissao && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>

                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Tipo</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={tipos.map(tipo => ({
                                            value: tipo.id,
                                            label: tipo.nome
                                        }))}
                                        value={{ value: watch("tipo"), label: tipos.find(tipo => tipo.id == watch("tipo"))?.nome }}
                                        onChange={(selectedOption) => {
                                            setValue("tipo", selectedOption.value);
                                            console.log(selectedOption?.value)
                                        }}
                                    />
                                    {errors.tipo && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default NovaComissao;
