import React, { useState } from 'react';
import ProdutosLista from './Produtos';
import PeliculasLista from './Peliculas';
import ServicosLista from './Servicos';
import ComissoesLista from './Comissoes';
import VeiculosLista from './Veiculos';
import ClientesLista from './Clientes';
import OrdensLista from './Ordens';



const Relatorio = () => {

    const [tabelaSelecionada, setTabelaSelecionada] = useState('peliculas');

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('produtos')}>
                    Produtos
                </button>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('servicos')}>
                    Serviço
                </button>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('comissoes')}>
                    Comissões
                </button>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('peliculas')}>
                    Películas
                </button>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('veiculos')}>
                    Veículos
                </button>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('clientes')}>
                    Clientes
                </button>
                <button type="button" className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setTabelaSelecionada('ordens')}>
                    Ordens
                </button>
            </div>

            <hr />

            {tabelaSelecionada === 'produtos' && <ProdutosLista />}
            {tabelaSelecionada === 'peliculas' && <PeliculasLista />}
            {tabelaSelecionada === 'servicos' && <ServicosLista />}
            {tabelaSelecionada === 'comissoes' && <ComissoesLista />}
            {tabelaSelecionada === 'veiculos' && <VeiculosLista />}
            {tabelaSelecionada === 'clientes' && <ClientesLista />}
            {tabelaSelecionada === 'ordens' && <OrdensLista />}
        </>
    );

}

export default Relatorio;
