import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//import axios from 'axios';
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function EditarCargo() {
    const { id } = useParams(); // Obtém o id do produto a partir da URL
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });



    useEffect(() => {
        const buscarCargo = async () => {
            try {
                const response = await Api.get(`BuscarCargo?id=${id}`);
                // Define os valores iniciais dos campos
                console.log(response.data)
                const { nome } = response.data.data[0];
                setValue('nome', nome);
            } catch (error) {
                console.log(error);
            }
        };

        buscarCargo();
    }, [id, setValue]);

    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {
            data.id = id;
            const response = await Api.put(`EditarCargo`, JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            console.log(response);
            setStatus(response.data);

            if(response.data.success || response.status == 200){
                window.location.href = '/listar-cargos'
            }


        } catch (error) {
            console.log(error);
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao editar o cargo. Tente novamente mais tarde.",
                });
            }
        }
    };


    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Cargo</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}
                            <div className="mb-3">
                                <label className="form-label">Cargo</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarCargo;
