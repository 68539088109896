import React, { useEffect, useState } from 'react';
import Table from '../../Components/Table';
import Api from '../../Api';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Certificado from './pdf';
import Select from "react-select";
import CurrencyInput from '../../Components/CurrencyInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextareaAutosize from "react-textarea-autosize";
import { parseISO, isWithinInterval, format, set } from 'date-fns';
import { convertDate } from '../../Components/Functions';



function ListarArquitetura() {

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});
    const [status, setStatus] = useState({ success: false, message: '' })
    const [cancelarPagamento, setCancelarPagamento] = useState(false);

    const deleteRecord = async (id) => {
        try {
            console.log(id)
            const response = await Api.delete(`DeletarOS/${id}`);
            setShowModalConfirm(false)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
                const refresh = await Api.get('ListarOS');
                setArquitetura(refresh.data);
            }
        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao apagar a OS. Tente novamente mais tarde."
            })
        }

    };

    const enviarEmail = async (id) => {
        try {
            //requisicao para EnviarEmail enviando informacoesBasicas.idOS
            const response = await Api.post('EnviarEmail', { informacoesBasicas: { idOS: id } });

            // Recupera o conteúdo do PDF da resposta
            const pdfContentArray = response.data.email.pdfContent.data; // Acessa o array de dados

            // Converte o array de dados para Uint8Array
            const uint8Array = new Uint8Array(pdfContentArray);

            // Realiza o download do PDF
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'arquivo.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


        } catch (error) {
            console.log(error)

        }
    }

    const enviarWhatsapp = async (id, id_cliente) => {
        
        try {
            const cliente = clientes.find(cliente => cliente.id === id_cliente);

            const response = await Api.post('EnviarWhatsapp', { informacoesBasicas: { idOS: id, celular: cliente.celular } });

            // Recupera o conteúdo do PDF da resposta
            const pdfContentArray = response.data.email.pdfContent.data; // Acessa o array de dados

            // Converte o array de dados para Uint8Array
            const uint8Array = new Uint8Array(pdfContentArray);

            // Realiza o download do PDF
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'arquivo.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


        } catch (error) {
            console.log(error)

        }
    }


    const handleImprimirOS = async (id) => {
        try {
            console.log(id);
            const response = await Api.get(`ImprimirArquitetura/?id=${id}`);
            console.log(response.data.data)


            Certificado(response.data.data)
        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao imprimir a OS. Tente novamente mais tarde."
            })
        }
    }

    function getInitials(name) {
        if (!name) {
            return '';
        }

        const splitName = name.toUpperCase().split(' ');

        if (splitName.length === 1) {
            return splitName[0] ? splitName[0].charAt(0) : '';
        } else {
            return splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0);
        }
    }

    const colors = ['rgb(255, 226, 226)', 'rgb(255, 234, 209)', 'rgb(247, 247, 176)', 'rgb(204, 255, 210)', 'rgb(216, 255, 252)', 'rgb(224, 236, 255)'];
    function getRandomColor() {
        return colors[Math.floor(Math.random() * colors.length)];
    }

    const [arquitetura, setArquitetura] = useState([]);
    const [arquiteturaShow, setArquiteturaShow] = useState([]);
    const [clientes, setClientes] = useState([]);

    const [showModalPagamentoConfirm, setShowModalPagamentoConfirm] = useState(false);
    const [modalPagamentoData, setModalPagamentoData] = useState({});
    const [valorPagamento, setValorPagamento] = useState("R$ 0,00");

    const [pagamentos, setPagamentos] = useState([]);
    const [showModalStatusConfirm, setShowModalStatusConfirm] = useState(false);




    const [modalStatusData, setModalStatusData] = useState({});
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [filterData, setFilterData] = useState([]);

    const isDateInRange = (date, startDate, endDate) =>
        (!startDate || date >= startDate) && (!endDate || date <= endDate);

    const isStatusMatch = (osStatus, selectedStatus) =>
        !selectedStatus || osStatus === selectedStatus;

    const isPaymentStatusMatch = (id, selectedPaymentStatus) =>
        !selectedPaymentStatus || pagamentoOS({ idOS: id }) === (selectedPaymentStatus === 'pago');

    const filtrarLista = () => {
        console.log(filterData);
        const { inicio, fim, status, pagamento, valorMin, valorMax, id } = filterData;

        const selectedStartDate = inicio ? parseISO(inicio) : null;
        const selectedEndDate = fim ? parseISO(fim) : null;

        const filteredData = arquitetura.filter(os => {
            const osStartDate = parseISO(os.data_inicio);
            const osEndDate = parseISO(os.data_fim);

            //se tiver id só filtra por ele
            if (id) {
                return os.idOS == id;
            }

            const isDateInRangeCondition =
                isDateInRange(osStartDate, selectedStartDate, selectedEndDate) ||
                isDateInRange(osEndDate, selectedStartDate, selectedEndDate);

            const isStatusMatchCondition = isStatusMatch(os.status, status);

            const isPaymentStatusMatchCondition = isPaymentStatusMatch(os.idOS, pagamento);

            const isValueInRangeCondition =
                (!valorMin || (os.valor_total - os.desconto) >= valorMin) &&
                (!valorMax || (os.valor_total - os.desconto) <= valorMax);

            return (
                isDateInRangeCondition &&
                isStatusMatchCondition &&
                isPaymentStatusMatchCondition &&
                isValueInRangeCondition
            );
        });

        setArquiteturaShow(filteredData);
    };



    function pagamentoOS(original) {
        const id = original.idOS
        if (original.tipo == "pix" || original.tipo == "dinheiro" || original.parcelas == 1) {
            return true;
        }


        // Procurar no objeto 'pagamentos' todos os registros que possuem o 'id' no campo 'idOS'
        const registros = pagamentos.filter(item => item.idOS == id);
        if (registros.length == 0) {
            return false;
        }


        // Verificar se todos os registros para esse 'id' possuem 'recebido' igual a 1
        const todosRecebidos = registros.every(item => item.recebido == 1);


        // console.log(todosRecebidos)
        return todosRecebidos;
    }

    const updateStatus = async (data) => {
        try {
            console.log(data)
            if (data.status == "fechada") {
                setShowModalPagamentoConfirm(true)
                setValorPagamento(encontraValorOS(data.id))
                setModalPagamentoData(data)
            }

        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o status da OS. Tente novamente mais tarde."
            })
        }
    };

    const encontraValorOS = (id) => {
        if (id && id != null && id != undefined && id != "") {
            const os = arquitetura.find(os => os.idOS == id); //deve ser o campo valor_total menos o campo desconto
            const valorFormatado = os.valor_total - os.desconto;

            return `R$ ${parseFloat(valorFormatado).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}`;

        }
    }

    const UpdateFinanceiroOS = async (data) => {
        try {
            console.log(data)
            const responseFecha = await Api.put(`AtualizarStatusOS/?id=${data.id}&status=fechada`);

            const response = await Api.put(`AtualizarFinanceiroOS/?id=${data.id}&tipo=${data.tipo}&parcelas=${data.parcelas}`);

            setShowModalStatusConfirm(false)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
                const refresh = await Api.get('ListarOS');
                setArquitetura(refresh.data);
            }

            // setShowModalStatusConfirm(false)

        }
        catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o financeiro da OS. Tente novamente mais tarde."
            })
        }
    };

    const financeiro = async (data) => {
        try {
            console.log(data)
            const response = await Api.post('Financeiro', data);
            console.log(response)

            setShowModalStatusConfirm(false)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
                const refresh = await Api.get('ListarOS');
                setArquitetura(refresh.data);
            }
        }
        catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro na inserção do financeiro. Tente novamente mais tarde."
            })
        }
    }

    const abrirOS = async (id) => {
        try {
            console.log(id)
            const response = await Api.put(`AtualizarStatusOrcamento/?id=${id}&status=aberta`);
            console.log(response)
            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setStatus(response.data)
                const refresh = await Api.get('ListarOS');
                setArquitetura(refresh.data);
            }
        } catch (error) {
            console.log(error)
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao abrir a OS. Tente novamente mais tarde."
            })
        }
    };



    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <Link to={`/editar-arquitetura/${row.idOS}`}>{row.idOS}</Link> ?? '-'
                    },
                    {
                        Header: "Cliente",
                        accessor: row => {
                            const cliente = clientes.find(cliente => cliente.id === row.id_cliente);
                            return (cliente ? cliente.nome_contato || cliente.nome || cliente.nome_fantasia : '-')
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            original.status !== 'fechada' ? (
                                <Link to={`/editar-arquitetura/${original.idOS}`}>
                                    <span className="ant-badge">
                                        <span className="ant-avatar ant-avatar-circle me-3" style={{ backgroundColor: getRandomColor() }}>
                                            <span className="ant-avatar-string" style={{ transform: 'scale(1) translateX(-50%)' }}>
                                                {getInitials(value)}
                                            </span>
                                        </span>
                                        <span className="fw-bold">
                                            {value}
                                        </span>
                                    </span>
                                </Link>
                            ) : (
                                <span>
                                    <span className="ant-badge">
                                        <span className="ant-avatar ant-avatar-circle me-3" style={{ backgroundColor: getRandomColor() }}>
                                            <span className="ant-avatar-string" style={{ transform: 'scale(1) translateX(-50%)' }}>
                                                {getInitials(value)}
                                            </span>
                                        </span>
                                        <span className="fw-bold">
                                            {value}
                                        </span>
                                    </span>
                                </span>
                            )
                        ),

                    },
                    {
                        Header: "Data do orçamento",
                        accessor: row => row.data_inicio ? convertDate(row.data_inicio) : '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            original.status !== 'fechada' ? (
                                <Link to={`/editar-os/${original.idOS}`}>{value ?? '-'}</Link>
                            ) : (
                                <span>{value ?? '-'}</span>
                            )
                        ),
                    },
                    // {
                    //     Header: "Data do Fim",
                    //     accessor: row => row.data_fim != null ? convertDate(row.data_fim) : '-',
                    //     Cell: ({ cell: { value }, row: { original } }) => (
                    //         original.status !== 'fechada' ? (
                    //             <Link to={`/editar-os/${original.idOS}`}>{value ?? '-'}</Link>
                    //         ) : (
                    //             <span>{value ?? '-'}</span>
                    //         )
                    //     ),
                    // },
                    {
                        Header: "Valor Total",
                        accessor: row => {
                            const valorTotal = row.valor_total != 0 ? row.valor_total - row.desconto : 0;
                            return `${Number(valorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }) ?? '-'}`;
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            original.status !== 'fechada' ? (
                                <Link to={`/editar-arquitetura/${original.idOS}`}>
                                    {value ? `${value}` : '-'}
                                </Link>
                            ) : (
                                <span>{value ? `${value}` : '-'}</span>
                            )
                        ),
                    },
                    // {
                    //     Header: "Status",
                    //     accessor: "status",
                    //     Cell: ({ cell: { value }, row: { original } }) => (
                    //         <button
                    //             className={`badge ${value === 'vendida' ? 'bg-success' : value === 'aberta' ? 'bg-warning' : 'bg-danger'}`}
                    //             style={{ border: 'none', outline: 'none', padding: '5px', background: 'none', cursor: 'pointer' }}
                    //             onClick={() => {
                    //                 setShowModalStatusConfirm(true);
                    //                 setModalStatusData({ id: original.idOS, status: value });
                    //             }}
                    //             disabled={original.status === 'fechada' && original.tipo_pagamento !== null}
                    //         >
                    //             {value ? value : '-'}
                    //         </button>
                    //     ),

                    // },
                    // {
                    //     Header: "Pagamento",
                    //     accessor: "pagamento",
                    //     Cell: ({ cell: { value }, row: { original } }) => (
                    //         <span
                    //             className={`badge ${pagamentoOS(original) == true ? 'bg-success' : 'text-dark'}`}

                    //             style={{ border: 'none', outline: 'none', padding: '5px', background: 'none', cursor: 'default', pointerEvents: 'none' }}
                    //         >
                    //             {pagamentoOS(original) == true ? 'Pago' : 'Pendente'}
                    //         </span>
                    //     ),
                    // },
                    {
                        Header: "Ações",
                        accessor: row =>
                            <div style={{ width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link className="fs-4 me-3" onClick={() => { setModalData({ nome: row.nome || row.nome_fantasia || row.razao || row.nome_contato, id: row.idOS }); setShowModalConfirm(true); }}>
                                    <i className="bi bi-trash3-fill"></i>
                                </Link>
                                {/* <Link className="fs-4" onClick={() => { handleImprimirOS(row.idOS); }}>
                                    <i className="bi bi-printer-fill"></i>
                                </Link> */}
                                <Link className="fs-4 me-3" onClick={() => { enviarEmail(row.idOS) }}>
                                    <i className="bi bi-envelope-fill"></i>
                                </Link>
                                <Link className="fs-4 me-3" onClick={() => { abrirOS(row.idOS) }}>
                                    <i className="bi bi-check-circle"></i>
                                </Link>
                                <Link className='fs-4' onClick={() => { enviarWhatsapp(row.idOS, row.id_cliente) }}>
                                    <i className="bi bi-whatsapp"></i>
                                </Link>

                            </div>
                    }
                ]
            }
        ],
        [clientes, arquitetura, pagamentos, arquiteturaShow]
    );

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarArquiteturas');
                const arqValidas = response.data.filter(arq => arq.status == 'orcamento');
                //deve filtrar somente as de status orcamento
                setArquitetura(arqValidas);
                setArquiteturaShow(arqValidas);
                console.log(arqValidas)

                const responseClientes = await Api.get('ListarClientes');
                console.log(responseClientes.data)
                setClientes(responseClientes.data);

                const pagamentosResponse = await Api.get('ListarPagamentos');
                console.log(pagamentosResponse.data.data)
                setPagamentos(pagamentosResponse.data.data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [status]);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de Orçamentos de Arquiteturas</h1>
                    </div>
                    <div>
                        <Link to="/cadastrar-arquitetura?tipo=orcamento" className='btn btn-primary fs-5'><FontAwesomeIcon icon="fa-solid fa-plus" /> | Nova Arquitetura</Link>
                        <button className="btn btn-primary m-2" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={arquiteturaShow} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={showModalStatusConfirm} onHide={() => setShowModalStatusConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alterar status da OS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        name="status"
                        label="Status"
                        options={[
                            { value: 'aberta', label: 'Aberta' },
                            { value: 'fechada', label: 'Fechada' },
                        ]}
                        defaultValue={{
                            value: modalStatusData.status,
                            label: modalStatusData.status ? modalStatusData.status.charAt(0).toUpperCase() + modalStatusData.status.slice(1) : ''
                        }}
                        onChange={selectedOption => {
                            const selectedStatus = selectedOption.value;
                            setModalStatusData({ ...modalStatusData, status: selectedStatus });
                        }} />


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { updateStatus(modalStatusData); setShowModalStatusConfirm(false); setModalStatusData({}) }}>Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => { setShowModalStatusConfirm(false); setModalStatusData({}) }}>Cancelar</button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showModalPagamentoConfirm} onHide={() => { setShowModalPagamentoConfirm(false); updateStatus({ id: modalPagamentoData.id, status: 'aberta' }) }} >
                <Modal.Header closeButton>
                    <Modal.Title>Financeiro da OS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto
                                        defaultValue={valorPagamento}

                                        onChange={(event, value) => {
                                            setModalPagamentoData({ ...modalPagamentoData, valor: value });
                                        }
                                        }
                                        readOnly={true}
                                    />
                                    <br />
                                    <div className="row">
                                        <div className={modalPagamentoData.tipo === 'credito' || modalPagamentoData.tipo === 'boleto' ? 'col-md-5' : ''}>
                                            <Select
                                                name="forma-pagamento"
                                                label="Forma de pagamento"
                                                options={[
                                                    { value: 'dinheiro', label: 'Dinheiro' },
                                                    { value: 'credito', label: 'Cartão de crédito' },
                                                    { value: 'debito', label: 'Cartão de débito' },
                                                    { value: 'pix', label: 'Pix' },
                                                    { value: 'boleto', label: 'Boleto' },
                                                ]}
                                                placeholder="Selecione a forma de pagamento"
                                                onChange={selectedOption => {
                                                    const selectedTipo = selectedOption.value;
                                                    setModalPagamentoData({ ...modalPagamentoData, tipo: selectedTipo, parcelas: undefined, dataVencimento: undefined });
                                                }} />
                                        </div>

                                        {modalPagamentoData.tipo === 'credito' &&
                                            <>
                                                <div className="col-md-3">
                                                    <Select
                                                        name="parcelas"
                                                        label="Parcelas"
                                                        options={[
                                                            { value: '1', label: '1x - À vista' },
                                                            { value: '2', label: '2x' },
                                                            { value: '3', label: '3x' },
                                                            { value: '4', label: '4x' },
                                                            { value: '5', label: '5x' },
                                                            { value: '6', label: '6x' },
                                                            { value: '7', label: '7x' },
                                                            { value: '8', label: '8x' },
                                                            { value: '9', label: '9x' },
                                                            { value: '10', label: '10x' },
                                                            { value: '11', label: '11x' },
                                                            { value: '12', label: '12x' },
                                                        ]}
                                                        placeholder="Parcelas"
                                                        onChange={selectedOption => {
                                                            const selectedParcelas = selectedOption.value;
                                                            setModalPagamentoData({ ...modalPagamentoData, parcelas: selectedParcelas });
                                                        }} />
                                                </div>

                                                <div className="col-md-4">
                                                    <Select
                                                        name="parcelas"
                                                        label="1ª Parcela"
                                                        options={[
                                                            { value: '1', label: 'Hoje' },
                                                            { value: '30', label: '30 dias' },
                                                            { value: '60', label: '60 dias' },
                                                            { value: '90', label: '90 dias' },
                                                        ]}
                                                        placeholder="1ª Parcela"
                                                        onChange={selectedOption => {
                                                            const selectedParcelas = selectedOption.value;
                                                            setModalPagamentoData({ ...modalPagamentoData, primeira: selectedParcelas });
                                                        }} />
                                                </div>
                                            </>
                                        }

                                        {modalPagamentoData.tipo === 'boleto' && (
                                            <div className="col-md-3">
                                                <DatePicker
                                                    className="form-control"
                                                    name="dataVencimento"
                                                    label="Data de Vencimento"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={modalPagamentoData.dataVencimento}
                                                    onChange={date => setModalPagamentoData({ ...modalPagamentoData, dataVencimento: date })}
                                                    placeholderText="Vencimento"
                                                    locale="pt-BR"
                                                />
                                            </div>
                                        )}
                                    </div>


                                    <br />
                                    <TextareaAutosize className="form-control" placeholder="Observações" value={modalPagamentoData.observacoes} onChange={e => setModalPagamentoData({ ...modalPagamentoData, observacoes: e.target.value })} />
                                </div>
                            </div>
                        </div>
                    </>



                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary"
                        disabled={(modalPagamentoData.tipo == 'boleto' && modalPagamentoData.dataVencimento == null && modalPagamentoData.dataVencimento == undefined) || (modalPagamentoData.tipo == 'credito' && modalPagamentoData.parcelas == null && modalPagamentoData.parcelas == undefined)}
                        onClick={() => { UpdateFinanceiroOS(modalPagamentoData); financeiro(modalPagamentoData); setShowModalPagamentoConfirm(false); setModalPagamentoData({}) }}>
                        Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => { setShowModalPagamentoConfirm(false); setModalPagamentoData({}); updateStatus({ id: modalPagamentoData.id, status: 'aberta' }); setModalStatusData(false) }}>Cancelar</button>
                </Modal.Footer>
            </Modal>



            <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-label">ID</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="id"
                                    placeholder='#'
                                    min={0}
                                    value={filterData.id || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            id: e.target.value
                                        })

                                        // console.log(filterData)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Inicial</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.inicio || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            inicio: e.target.value
                                        })

                                        // console.log(filterData)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="dataInicial"
                                    value={filterData.fim || ''}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            fim: e.target.value
                                        })

                                        // console.log(filterData)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Status</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    value={filterData.status}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            status: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    <option value="aberta">Aberta</option>
                                    <option value="fechada">Fechada</option>
                                </select>

                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Pagamento</label>
                                <select
                                    className="form-control"
                                    name="status"
                                    value={filterData.pagamento}
                                    onChange={e => {
                                        setFilterData({
                                            ...filterData,
                                            pagamento: e.target.value
                                        })
                                    }}
                                >
                                    <option value="">Selecione</option>
                                    <option value="pago">Pago</option>
                                    <option value="pendente">Pendente</option>
                                </select>

                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Valor min</label>
                                <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                    onChange={(event, value) => {
                                        setFilterData({
                                            ...filterData,
                                            valorMin: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                        })
                                    }}
                                    defaultValue={filterData.valorMin || '0'}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>

                            <div className="form-group">
                                <label className="form-label">Valor max</label>
                                <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                    onChange={(event, value) => {
                                        setFilterData({
                                            ...filterData,
                                            valorMax: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                        })
                                    }}
                                    defaultValue={filterData.valorMax || '0'}
                                />
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { filtrarLista() }}>Confirmar</button>
                    {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                    <button className="btn btn-danger" onClick={() => { setFilterData([]); setArquiteturaShow(arquitetura); setShowFilterModal(false) }}>Limpar</button>
                </Modal.Footer>
            </Modal >



            <Modal centered show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja deletar o registro de: <b>{modalData.nome}</b> ?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => { deleteRecord(modalData.id) }}>Confirmar</button>
                    <button className="btn btn-secondary" onClick={() => setShowModalConfirm(false)}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>


    );

}

export default ListarArquitetura;