import React from "react";
import { createContext, useState } from "react";

export const AuthContext = createContext({});

function AuthProvider({ children }) {

    const [id, setId] = useState(null);

    function saveInfo(a) {
        setId({
            id: a
        });
    }

    return (
        <AuthContext.Provider value={{ saveInfo, id }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;