import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
//import axios from 'axios';
import { useParams } from 'react-router-dom';
import Api from '../../Api'
import TextareaAutosize from 'react-textarea-autosize';

import CurrencyInput from '../../Components/CurrencyInput.js'


import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function EditarPelicula() {
    const { id } = useParams(); // Obtém o id do produto a partir da URL
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const [status, setStatus] = useState({ success: false, message: '' });
    const handleValorVendaChange = (value) => {
        setValue("valor_venda", value);
    };
    const [formattedValorVenda, setFormattedValorVenda] = useState('');

    useEffect(() => {
        const buscarProduto = async () => {
            try {
                const response = await Api.get(`BuscarProduto?id=${id}`);
                const { nome, codigo_produto, foto, descricao, valor_venda, categoria, tipo, estoque, estoque_min } = response.data.data[0];
                setValue('nome', nome);
                setValue('codigo_produto', codigo_produto);
                setValue('foto', foto);
                setValue('descricao', descricao);
                setValue('categoria', categoria);
                setValue('tipo', tipo);
                //mostra inteiro se for inteiro e decimal se for decimal
                const estoqueFloat = parseFloat(estoque);
                const estoqueInteiro = estoqueFloat % 1 === 0 ? Math.floor(estoqueFloat) : estoqueFloat;
                setValue('estoque', estoqueInteiro);
                setValue('estoque_min', estoque_min);


                const formattedValorVenda = `R$ ${parseFloat(valor_venda).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}`;
                setValue('valor_venda', valor_venda);
                setFormattedValorVenda(formattedValorVenda); // adicionando o valor formatado ao estado local
            } catch (error) {
                console.log(error);
            }
        };

        buscarProduto();
    }, [id, setValue]);

    const [categorias, setCategorias] = useState([]);

    const [categoriaSelecionada, setCategoriaSelecionada] = useState('');

    useEffect(() => {
        const buscarCategorias = async () => {
            try {
                const response = await Api.get(`ListarCategorias`);
                setCategorias(response.data);
                console.log(response.data)

                const categoriaSelecionada = response.data.find(categoria => categoria.id === watch('categoria'));
                if (categoriaSelecionada) {
                    setCategoriaSelecionada({ value: categoriaSelecionada.id, label: categoriaSelecionada.nome });
                }
            } catch (error) {
                console.log(error);
            }
        };
        buscarCategorias();
    }, []);

    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {
            data.id = id;
            const formData = new FormData();
            formData.append('nome', data.nome); // nome é a variável que contém o valor do campo "nome"
            formData.append('codigo_produto', data.codigo_produto); // codigo_produto é a variável que contém o valor do campo "codigo_produto"
            formData.append('foto', data.foto[0]); // foto é a variável que contém o valor do campo "foto". Note que estamos adicionando o primeiro elemento do array, pois é o único arquivo que o usuário pode selecionar
            formData.append('descricao', data.descricao); // descricao é a variável que contém o valor do campo "descricao"
            formData.append('id', data.id); // id é a variável que contém o valor do campo "id"


            //remover o R$ do valor_venda
            data.valor_venda = data.valor_venda.replace(/[^0-9]/g, "");
            data.valor_venda = data.valor_venda.slice(0, -2) + "." + data.valor_venda.slice(-2);

            formData.append('valor_venda', data.valor_venda); // valor_venda é a variável que contém o valor do campo "valor_venda"

            // formData.append('categoria', categoriaSelecionada.value); // categoria é a variável que contém o valor do campo "categoria"

            formData.append('tipo', 'material'); // tipo é a variável que contém o valor do campo "tipo"
            formData.append('estoque', data.estoque); // estoque é a variável que contém o valor do campo "estoque"
            formData.append('estoque_min', data.estoque_min); // estoque_min é a variável que contém o valor do campo "estoque_min"
            formData.append('categoria', 1); // categoria é a variável que contém o valor do campo "categoria" //editando isso em 17/12 acredito que 1 seja o valor padrão


            const response = await Api.put('EditarProduto', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setStatus(response.data);

            if (response.data.success || response.status == 200) {
                window.location.href = '/listar-peliculas'
            }

        } catch (error) {
            console.log(error);
            // Mostra uma mensagem de erro genérica ao usuário
            setStatus({
                success: false,
                message: "Ocorreu um erro ao atualizar o produto. Por favor, tente novamente mais tarde." + error.response.data.error
            });
        }
    };

    const [fotoTouched, setFotoTouched] = useState(false);

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-box-open" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Produto</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Nome da película*</label>
                                <input type="text" className="form-control" {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Código de referência</label>
                                    <input type="text" className="form-control" {...register("codigo_produto")} />
                                    {errors.codigo_produto && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>

                                {/* <div className="col-md-4 mb-3">
                                    <label className="form-label">Categoria*</label>
                                    <Select
                                        placeholder="Selecione"
                                        options={categorias.map(categoria => ({
                                            value: categoria.id,
                                            label: categoria.nome
                                        }))}
                                        onChange={(selectedOption) => {
                                            register("categoria", { value: selectedOption });
                                            setCategoriaSelecionada(selectedOption);
                                        }}
                                        value={categoriaSelecionada}
                                    />
                                </div> */}

                                <div className="col-md-3 mb-3">
                                    {watch('tipo') === "unit" && (
                                        <label className="form-label">Preço do produto</label>
                                    )}
                                    {watch('tipo') === "material" && (
                                        <label className="form-label">Preço por m²</label>
                                    )}
                                    <div>
                                        <CurrencyInput
                                            defaultValue={formattedValorVenda}
                                            onChange={handleValorVendaChange}
                                        />


                                    </div>
                                </div>

                                <div className='col-md-3 mb-3'>
                                    <label className="form-label">Estoque</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register("estoque")}
                                        defaultValue={watch('estoque')}
                                    />
                                </div>

                                <div className='col-md-3 mb-3'>
                                    <label className="form-label">Estoque mínimo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        {...register("estoque_min")}
                                        defaultValue={watch('estoque_min')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="foto" className="form-label">Foto</label>
                                    <div className="input-group">
                                        <input
                                            id="foto"
                                            type="file"
                                            accept=".jpg, .jpeg, .png"
                                            className="form-control"
                                            {...register("foto")}
                                            onChange={(e) => { setFotoTouched(true) }}
                                        />


                                    </div>

                                    {!fotoTouched && (
                                        watch('foto') && watch('foto').length >= 10 ? (
                                            <img className="img-fluid border rounded-3 mt-4" src={`data:image/jpeg;base64,${watch('foto')}`} style={{ width: 250, height: 250 }} />
                                        ) : (
                                            <div className="nex-products__product-image"></div>
                                        )
                                    )}


                                </div>

                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Descrição</label>
                                    <TextareaAutosize className="form-control" {...register("descricao")} minRows={1} maxRows={7} />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> ATUALIZAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EditarPelicula;
