import React, { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { IMaskInput } from "react-imask"
import axios from 'axios'
import Api from '../../Api'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

function NovoFuncionario() {
	const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm()
	const [status, setStatus] = useState({ success: false, message: '' })
	const [endereco, setEndereco] = useState({ cep: '', endereco: '', bairro: '', cidade: '' })
	const [cargos, setCargos] = useState([])

	const [isValidCpf, setIsValidCpf] = useState(false);
	const [isCpfTouched, setIsCpfTouched] = useState(false);
	const [isCpfEmpty, setIsCpfEmpty] = useState(true);

	const rg = useRef(null);
	const cep = useRef(null);

	const celular = useRef(null);
	const cpfRef = useRef(null);

	useEffect(() => {
		setValue('celular', '16 ')
		setValue('contato', '16 ')

	}, [setValue])

	const validateCpf = (cpf) => {
		cpf = cpf.replace(/[^\d]/g, ""); // remove non-digits
		if (cpf.length !== 11) return false; // CPF must have 11 digits

		// calculate first digit verifier
		let sum = 0;
		for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i);
		let mod = sum % 11;
		let dv1 = mod < 2 ? 0 : 11 - mod;

		// calculate second digit verifier
		sum = 0;
		for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i);
		mod = sum % 11;
		let dv2 = mod < 2 ? 0 : 11 - mod;

		return cpf.charAt(9) == dv1 && cpf.charAt(10) == dv2;
	};


	const handle_busca_cpf = async () => {
		try {
			console.log(cpfRef.current.value, watch('data_nascimento'))

			const data_de_nascimento_obj = new Date(watch('data_nascimento'));


			//adicionar um dia (não sei porque mas vem um dia a menos ao converter a data)
			data_de_nascimento_obj.setDate(data_de_nascimento_obj.getDate() + 1);

			const data_de_nascimento_formato_pt_br = format(data_de_nascimento_obj, 'dd/MM/yyyy');
			console.log(data_de_nascimento_formato_pt_br)

			const cpf = cpfRef.current.value.replace(/\D/g, '');
			const response = await Api.get(`https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${data_de_nascimento_formato_pt_br}&token=${process.env.REACT_APP_TOKEN}`);
			console.log(response.data)

			console.log(response.data.result.nome_da_pf)
			setValue('nome', response.data.result.nome_da_pf)

			const parts = response.data.result.data_nascimento.split('/');
			const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

			setValue('data_nascimento', reformattedDate)


		} catch (error) {
			console.log(error)
		}
	}

	// BUSCA OS CARGOS
	useEffect(() => {
		const fetchCargos = async () => {
			try {
				const response = await Api.get("BuscarCargos")
				setCargos(response.data.data)
			} catch (error) {
				console.log(error)
			}
		}
		fetchCargos()
	}, [])

	const handleChangeCpf = () => {
		const cpf = cpfRef.current.value.replace(/[^\d]/g, "");
		const isValid = cpf ? validateCpf(cpf) : false;
		setIsValidCpf(isValid);
		setIsCpfTouched(true);
		setIsCpfEmpty(cpf === "");
	};

	const onSubmit = async (data) => {

		window.scrollTo(0, 0);
		try {
			// CAMPOS COM MASK (geral)
			if (celular.current.value.length > 6) {
				data.celular = celular.current.value
			} else {
				data.celular = null;
			}

			data.cpf = cpfRef.current.value
			data.rg = rg.current.value
			data.cep = cep.current.value

			console.log(data)


			const response = await Api.post("NovoFuncionario", JSON.stringify(data), {
				headers: { 'Content-Type': 'application/json' }
			});
			setStatus(response.data);

			//Resetar o formulario após enviar e der sucesso
			if (response.data.success === true) {
				reset()
				setEndereco({ cep: '', endereco: '', bairro: '', cidade: '' });
			}



		} catch (error) {
			console.log(error)
			// Mostra uma mensagem de erro genérica ao usuário
			if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
				setStatus({
					success: false,
					message: error.response.data.error,
				});
			} else { // Mostra uma mensagem de erro genérica ao usuário
				setStatus({
					success: false,
					message: "Ocorreu um erro ao cadastrar o cliente. Tente novamente mais tarde.",
				});
			}
		}
	};

	const handleCepChange = async (event) => {
		const cepValue = event.target.value.replace(/\D/g, '')
		if (cepValue.length !== 8) {
			return;
		}

		try {
			const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`)
			setEndereco({
				cep: response.data.cep,
				endereco: response.data.logradouro,
				bairro: response.data.bairro,
				cidade: response.data.localidade
			});

			// Atualiza os valores
			setValue('cidade', response.data.localidade);
			setValue('bairro', response.data.bairro);
			setValue('endereco', response.data.logradouro);
		} catch (error) {
			console.log(error)
		}
	};

	const handleCelularChange = (event) => {
		setValue('celular', event.target.value)
	}


	// Anti uso moderno
	const handleCelularFocus = (e) => {
		const input = celular.current;
		if (input) {
			input.setSelectionRange(4, 4);
		}
	};

	const handleNascimentoChange = (event) => {
		console.log(event.target.value)
		setValue('data_nascimento', event.target.value)
		if (event.target.value.length === 10)
			handle_busca_cpf();
	}

	const handleChangeRg = () => {
		setValue('rg', rg.current.value)

	};

	function handleKeyUp(e) {
		e.target.value = e.target.value.toUpperCase();
	}


	return (
		<div className='page-full'>
			<form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
				<div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
					<FontAwesomeIcon icon="fa-solid fa-arrow-left" />
				</div>
				<div className="pt-5 m-5">
					<div className="row d-flex justify-content-center align-items-top">
						<div className='col-md-2'>
							<span className="ant-avatar-circle nex-avatar default circle-icon">
								<FontAwesomeIcon icon="fa-regular fa-user" />
							</span>
						</div>
						<div className="col-md-8 mb-5">
							<h1 className="h3 mb-3 fw-bold">Informações do Funcionário</h1>

							{status.message && (
								<div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
									<div className="alert-icon">
										<i className="far fa-fw fa-bell"></i>
									</div>
									<div className="alert-message">
										{status.message}
									</div>
								</div>
							)}

							<div className="row">
								<div className="col-md-6 mb-3">
									<label className="form-label">CPF*</label>
									<IMaskInput
										mask="000.000.000-00"
										type="text"
										inputRef={cpfRef}
										{...register("cpf")}
										onAccept={handleChangeCpf}
										className={`form-control ${!isCpfEmpty && isCpfTouched ? (isValidCpf ? "is-valid" : "is-invalid") : ""
											}`}
									/>
									{isCpfTouched && !isValidCpf && !isCpfEmpty ? (
										<div className="invalid-feedback">CPF inválido.</div>
									) : null}
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">Data de Nascimento*</label>
									<input type="date" className="form-control" onChange={handleNascimentoChange} value={watch('data_nascimento')} />
								</div>

							</div>

							<div className='row'>
								<div className="col-md-3 mb-3">
									<label className="form-label">RG*</label>
									<IMaskInput
										mask="00.000.000-W"
										type="text"
										inputRef={rg}
										onAccept={handleChangeRg}
										value={watch('rg') ? watch('rg') : ''}
										definitions={{
											W: {
												validate(char) {
													return /[0-9a-zA-Z]/.test(char);
												},
											},
										}}
										className="form-control"
									/>
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">Nome Completo*</label>
									<input type="text" className="form-control" {...register("nome", { required: true })} value={watch('nome')} />
									{errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Apelido</label>
									<input type="text" className="form-control text-uppercase" {...register("apelido")} onKeyUp={handleKeyUp} />
								</div>
							</div>


							<div className="row">
								<div className="col-md-6 mb-3">
									<label className="form-label">Celular*</label>
									<IMaskInput mask="(00) 00000-0000" type="text" inputRef={celular} onChange={handleCelularChange} onFocus={handleCelularFocus} className="form-control" value={watch('celular')} />
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">Cep*</label>
									<IMaskInput mask="00.000-000" type="text" inputRef={cep} {...register("cep")} className="form-control" onBlur={handleCepChange} />
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 mb-3">
									<label className="form-label">Endereço*</label>
									<input type="text" className="form-control" {...register("endereco")} value={endereco.endereco} onChange={(event) => setEndereco({ ...endereco, endereco: event.target.value })} />
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Número*</label>
									<input type="text" className="form-control" {...register("numero")} />
								</div>
								<div className="col-md-3 mb-3">
									<label className="form-label">Bairro*</label>
									<input type="text" className="form-control" {...register("bairro")} value={endereco.bairro} onChange={(event) => setEndereco({ ...endereco, bairro: event.target.value })} />
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 mb-3">
									<label className="form-label">Cidade*</label>
									<input
										type="text"
										className="form-control"
										{...register("cidade")}
										value={endereco.cidade}
										onChange={(event) => setEndereco({ ...endereco, cidade: event.target.value })}
									/>
								</div>

							</div>

							<div className="row">
								<div className="col-md-4 mb-3">
									<label className="form-label">Quadra</label>
									<input type="text" className="form-control text-uppercase" {...register("quadra")} />
								</div>
								<div className="col-md-4 mb-3">
									<label className="form-label">Lote</label>
									<input type="text" className="form-control" {...register("lote")} />
								</div>
								<div className="col-md-2 mb-3">
									<label className="form-label">Sala</label>
									<input type="text" className="form-control" {...register("sala")} />
								</div>
								<div className="col-md-2 mb-3">
									<label className="form-label">Apto</label>
									<input type="text" className="form-control" {...register("apto")} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-6 mb-3">
									<label className="form-label">Data de Admissão</label>
									<input type="date" className="form-control" {...register("data_admissao")} />
								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">Data de Demissão</label>
									<input type="date" className="form-control" {...register("data_demissao")} />
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 mb-3">
									<label className="form-label">Cargo</label>
									<Select
										placeholder="Selecione"
										options={cargos.map(cargo => ({
											value: cargo.id,
											label: cargo.nome
										}))}
										onChange={(selectedOption) => {
											register("cargo_id", { value: selectedOption?.value });
										}}
									/>


								</div>
								<div className="col-md-6 mb-3">
									<label className="form-label">E-mail</label>
									<input type="email" className="form-control" {...register("email")} />
								</div>
							</div>

						</div>
					</div>
				</div>
				<div className="footer-page-full">
					<div className="footer-client">
						<button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
						<button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default NovoFuncionario