import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import Api from '../../Api'
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseISO, format } from 'date-fns';
import CurrencyInput from '../../Components/CurrencyInput';
import { convertDate } from '../../Components/Functions';


function ListarPagamentos() {

    const [status, setStatus] = useState({ success: false, message: '' })


    const [parcelas, setParcelas] = useState([]);
    const [parcelasShow, setParcelasShow] = useState([]);
    const [os, setOs] = useState([]);
    const [clientes, setClientes] = useState([]);

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [modalData, setModalData] = useState({});

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterData, setFilterData] = useState({});

    const formatDate = (isoDate) => {
        const dateObj = new Date(isoDate);
        return format(dateObj, 'dd/MM/yyyy - HH:mm');
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get('ListarPagamentos');
                setParcelas(response.data.data);
                setParcelasShow(response.data.data);
                console.log(response.data.data)
            } catch (error) {
                console.error(error);
            }
        }

        async function fetchOS() {
            try {
                const response = await Api.get('ListarOS');
                setOs(response.data);
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        async function fetchClientes() {
            try {
                const response = await Api.get('ListarClientes');
                setClientes(response.data);
                console.log(response.data)
            } catch (error) {
                console.error(error);
            }
        }

        fetchOS();
        fetchData();
        fetchClientes();
    }, [status]);




    const confirmarBoleto = async (row) => {
        try {
            const response = await Api.post(`ConfirmarBoleto`, row);
            setStatus(response.data)
        } catch (error) {
            console.log(error)
            setStatus({
                success: false,
                message: "Ocorreu um erro ao apagar o usuário. Tente novamente mais tarde."
            })
        }
    };

    const colors = ['rgb(255, 226, 226)', 'rgb(255, 234, 209)', 'rgb(247, 247, 176)', 'rgb(204, 255, 210)', 'rgb(216, 255, 252)', 'rgb(224, 236, 255)'];
    function getRandomColor() {
        return colors[Math.floor(Math.random() * colors.length)];
    }

    function getInitials(name) {
        if (!name) {
            return '';
        }

        const splitName = name.toUpperCase().split(' ');

        if (splitName.length === 1) {
            return splitName[0] ? splitName[0].charAt(0) : '';
        } else {
            return splitName[0].charAt(0) + splitName[splitName.length - 1].charAt(0);
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                isVisible: false,
                hideHeader: false,
                id: 'id',
                columns: [
                    {
                        Header: "#",
                        accessor: row => <div>{row.id}</div> ?? '-'
                    },
                    {
                        Header: "Cliente",
                        accessor: row => {
                            const cliente = clientes.find(cliente => cliente.id == row.id_cliente);
                            return (cliente ? cliente.nome_contato || cliente.nome || cliente.nome_fantasia : '-')
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div>
                                <span className="ant-badge">
                                    <span className="ant-avatar ant-avatar-circle me-3" style={{ backgroundColor: getRandomColor() }}>
                                        <span className="ant-avatar-string" style={{ transform: 'scale(1) translateX(-50%)' }}>
                                            {getInitials(value)}
                                        </span>
                                    </span>
                                    <span className="fw-bold">
                                        {value + ' ' + '(' + original.idOS + ')'}
                                    </span>
                                </span>
                            </div>
                        ),

                    },
                    // {
                    //     Header: "id OS",
                    //     accessor: row => row.idOS || '-',
                    //     Cell: ({ cell: { value }, row: { original } }) => (
                    //         <div>{value || '-'}</div>
                    //     ),
                    // },
                    {
                        Header: "Valor",
                        accessor: row => `${Number(row.valor_final).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }) ?? '-'}`,
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div>{value || '-'}</div>
                        ),
                    },
                    {
                        Header: "Forma de pagamento",
                        accessor: row => row.forma_pagamento || '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div>{value ? value.charAt(0).toUpperCase() + value.slice(1) : '-'}</div>
                        ),
                    },
                    {
                        Header: "Data de vencimento",
                        accessor: row => {
                            if (row.data_fimOS) {
                                const formattedDate = convertDate(row.data_fimOS ?? '');
                                return formattedDate;
                            } else {
                                return '-';
                            }
                        },
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div>{value}</div>
                        ),
                    },
                    {
                        Header: "Parcela",
                        accessor: row => row.parcelas ? `${row.parcelas}/${os.find(item => item.idOS == row.idOS)?.parcelas}` : '-',
                        Cell: ({ cell: { value }, row: { original } }) => (
                            <div>{value || '-'}</div>
                        ),
                    },
                    {
                        Header: "Status",
                        accessor: "recebido",
                        Cell: ({ cell: { value }, row: { original } }) => {
                            if (original.forma_pagamento == 'boleto' && original.recebido == 0) {
                                return (
                                    <span
                                        className={`badge ${value === 1 ? 'bg-success' : 'bg-warning'}`}
                                        style={{ cursor: 'pointer', pointerEvents: 'auto' }}
                                        onClick={() => { setModalData(original); setShowModalConfirm(true) }}
                                    >
                                        {value === 1 ? 'Pago' : 'Pendente'}
                                    </span>

                                );
                            } else {
                                return (
                                    <span className={`badge ${value == 1 ? 'bg-success' : 'bg-warning'}`}>
                                        {value == 1 ? 'Pago' : 'Pendente'}
                                    </span>
                                );
                            }
                        }
                    },
                ]
            }
        ],
        [os, clientes]
    );

    const isDateInRange = (date, startDate, endDate) =>
        (!startDate || date >= startDate) && (!endDate || date <= endDate);

    const isStatusMatch = (osStatus, selectedStatus) =>
        !selectedStatus || osStatus === selectedStatus;

    const isPaymentStatusMatch = (forma_pagamento, selectedPaymentStatus) =>
        !selectedPaymentStatus || forma_pagamento === selectedPaymentStatus;

    const filtrarLista = () => {
        console.log(filterData);
        const { inicio, fim, status, pagamento, valorMin, valorMax } = filterData;

        const selectedStartDate = inicio ? parseISO(inicio) : null;
        const selectedEndDate = fim ? parseISO(fim) : null;

        const filteredData = parcelas.filter(parcelas => {
            const osStartDate = parseISO(parcelas.data_fimOS);

            const isDateInRangeCondition =
                isDateInRange(osStartDate, selectedStartDate, selectedEndDate);

            const isStatusMatchCondition = isStatusMatch(parcelas.recebido, status);

            const isPaymentStatusMatchCondition = isPaymentStatusMatch(parcelas.forma_pagamento, pagamento);

            const isValueInRangeCondition =
                (!valorMin || Math.round(parcelas.valor_final) >= Math.round(valorMin)) &&
                (!valorMax || Math.round(parcelas.valor_final) <= Math.round(valorMax));


            return (
                isDateInRangeCondition &&
                isStatusMatchCondition &&
                isPaymentStatusMatchCondition &&
                isValueInRangeCondition
            );
        });

        setParcelasShow(filteredData);
    };


    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Listagem de recebimentos</h1>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { setShowFilterModal(true) }}>Filtrar</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {status.message && (
                                    <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        <div className="alert-icon">
                                            <i className="far fa-fw fa-bell"></i>
                                        </div>
                                        <div className="alert-message">
                                            {status.message}
                                        </div>
                                    </div>
                                )}
                                <Table columns={columns} data={parcelasShow} />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal centered show={showModalConfirm} onHide={() => { setModalData({}); setShowModalConfirm(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmação</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Tem certeza que deseja confirmar o recebimento do boleto?</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => { confirmarBoleto(modalData); setModalData({}); setShowModalConfirm(false) }}>Confirmar</button>
                        <button className="btn btn-secondary" onClick={() => { setModalData({}); setShowModalConfirm(false) }}>Cancelar</button>
                    </Modal.Footer>
                </Modal>

                <Modal centered show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filtro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Data Inicial</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="dataInicial"
                                        value={filterData.inicio || ''}
                                        onChange={e => {
                                            setFilterData({
                                                ...filterData,
                                                inicio: e.target.value
                                            })

                                            // console.log(filterData)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Data Final</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="dataInicial"
                                        value={filterData.fim || ''}
                                        onChange={e => {
                                            setFilterData({
                                                ...filterData,
                                                fim: e.target.value
                                            })

                                            // console.log(filterData)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Status</label>
                                    <select
                                        className="form-control"
                                        name="status"
                                        value={filterData.status}
                                        onChange={e => {
                                            setFilterData({
                                                ...filterData,
                                                status: e.target.value
                                            })
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="1">Pago</option>
                                        <option value="0">Pendente</option>
                                    </select>

                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Forma de pagamento</label>
                                    <select
                                        className="form-control"
                                        name="status"
                                        value={filterData.pagamento}
                                        onChange={e => {
                                            setFilterData({
                                                ...filterData,
                                                pagamento: e.target.value
                                            })
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="dinheiro">Dinheiro</option>
                                        <option value="credito">Crédito</option>
                                        <option value="debito">Débito</option>
                                        <option value="boleto">Boleto</option>
                                        <option value="pix">Pix</option>
                                    </select>

                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Valor min</label>
                                    <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                        onChange={(event, value) => {
                                            setFilterData({
                                                ...filterData,
                                                valorMin: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                            })
                                        }}
                                        defaultValue={filterData.valorMin || '0'}
                                    />
                                </div>
                            </div>

                            <div className='col-md-6'>

                                <div className="form-group">
                                    <label className="form-label">Valor max</label>
                                    <CurrencyInput //default value tem que usar o modalPagamentoData.id para encontrar o campo valor_total e subtrair o campo desconto

                                        onChange={(event, value) => {
                                            setFilterData({
                                                ...filterData,
                                                valorMax: event.replace('R$ ', '').replace('.', '').replace(',', '.')
                                            })
                                        }}
                                        defaultValue={filterData.valorMax || '0'}
                                    />
                                </div>
                            </div>

                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => { filtrarLista() }}>Confirmar</button>
                        {/* <button className="btn btn-secondary" onClick={() => setShowFilterModal(false)}>Cancelar</button> */}
                        <button className="btn btn-danger" onClick={() => { setFilterData([]); setParcelasShow(parcelas); setShowFilterModal(false) }}>Limpar</button>
                    </Modal.Footer>
                </Modal >
            </div>

        </>


    );

}

export default ListarPagamentos