import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { right } from '@popperjs/core';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {

    const { nome, nivel } = {
        nome: localStorage.getItem('nome'),
        nivel: localStorage.getItem('nivel')
    };

    const handleLogout = () => {
        // Limpa o localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('cpf');
        localStorage.removeItem('nivel');
        localStorage.removeItem('nome');

        // Redireciona para a página de login
        window.location.href = '/Login'
    };

    return (
        <div>
            <nav className="navbar navbar-expand navbar-light bg-animate">
                <div className='navbar-custom'>
                    <div className="container d-flex justify-content-between w-100">
                        <span className="navbar-brand m-1" tabIndex="0" >
                           
                        </span>
                        <div className="d-flex align-items-center position-relative">
                            <div className="px-4 text-light">
                                {nome}
                            </div>
                            {/* <div className="px-4 text-light">
                                <i className="bi fs-2 bi-bell cursor-pointer"></i>
                            </div> */}

                            {/* Separador */}
                            <div className="nav-item" style={{ listStyle: 'none' }}>
                                <div className="vr d-none d-lg-flex h-100 mx-lg-2 text-white"></div>
                            </div>

                            <div className="nav-item dropdown">
                                <div className="d-flex align-items-center px-3 pe-0 text-light gap-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi fs-1 bi-person-circle cursor-pointer"></i>
                                </div>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <Link className="dropdown-item" to="/perfil">
                                        <i className="bi bi-person-fill"></i> Meu Perfil
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item" onClick={handleLogout}>
                                        <i className="bi bi-box-arrow-right"></i> Sair
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </nav >
            {/* <div className='d-none d-md-flex justify-content-center'>
                <div className="btn-group" style={{ marginTop: '-3px' }} role="group" aria-label="Basic example">
                    <Link to="/cadastrar-os" className="btn btn-primary border-0">
                        <span className="bg-light px-1 text-dark rounded-2">F2</span> Nova OS <FontAwesomeIcon icon="fa-solid fa-clipboard-list" />
                    </Link>
                    <Link to="/cadastrar-cliente" className="btn btn-primary border-0">
                        <span className="bg-light px-1 text-dark rounded-2">F4</span> Novo Cliente <FontAwesomeIcon icon="fa-solid fa-users" />
                    </Link>
                    <Link to="/cadastrar-produto" className="btn btn-primary border-0">
                        <span className="bg-light px-1 text-dark rounded-2">F6</span> Novo Produto <FontAwesomeIcon icon="fa-solid fa-box-open" />
                    </Link>
                </div>
            </div> */}
        </div >
    );
}

export default Header;