import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { set, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { formatarTelefone } from '../../Components/formataTelefones';

import Api from '../../Api';

const Perfil = () => {
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [showconfirm, setShowConfirm] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [status, setStatus] = useState({ success: false, message: '' })
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

    const [cpfUsuario, setCpfUsuario] = useState('');
    const [telefoneUsuario, setTelefoneUsuario] = useState('');
    const cpf = React.useRef(null);
    const telefone = React.useRef(null);


    const handleShow = () => setShowModal(true);
    const handleClose = () => { setShowModal(false); setShowConfirm(false) };

    const handlePasswordChange = () => {
        handleClose();
        //check if they are both equal
        if (password !== confirmPassword) {
            setStatus({ success: false, message: 'As senhas não coincidem' });
            setPassword('');
            setConfirmPassword('');
            return;
        }
        setPassword('');
        handleClose();
    };

    const handleTelefoneChange = (event) => {
        const rawValue = event.target.value;
        const formattedValue = formatarTelefone(rawValue);
        setTelefoneUsuario(formattedValue)
        setValue('telefone', event.target.value)
    }
    const handleTelefoneFocus = (e) => {
        const input = e.target;
        if (input) {
            input.setSelectionRange(input.value.length, input.value.length);
        }
    };

    const atualizarUser = async () => {
        setShowConfirm(false);
        const jonas = {
            cpf: cpfUsuario,
            telefone: telefoneUsuario,
            nome: watch("nome"),
            email: watch("email"),
            senha: password,
            novaSenha: confirmPassword,
            id: watch("id")
        }

        console.log(jonas)
        try {
            const response = await Api.put(`EditarPerfil`, jonas);
            console.log(response.data)
            if (response.data.success) {
                console.log("deu success");
                localStorage.setItem('cpf', cpfUsuario);
                localStorage.setItem('nome', watch("nome"));
            }
            setStatus({ success: true, message: 'Dados atualizados com sucesso!' });
        } catch (error) {
            console.log(error);
            setStatus({ success: false, message: 'Erro ao atualizar dados!' });
        }
    }

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const key = localStorage.getItem('cpf');
                const response = await Api.get(`BuscarUsuarioCPF?id=${key}`);
                console.log(response.data)
                const { nome, cpf, email, telefone, id } = response.data.data[0];
                setValue('nome', nome);
                setValue('email', email);
                setValue('id', id)

                setTelefoneUsuario(telefone);
                setValue('telefone', telefone);

                setCpfUsuario(cpf);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, []);


    return (
        <>
            <div className="container-fluid p-0">
                <div className="d-flex justify-content-between">
                    <div>
                        <h1 className="h3 mb-3 font-bold">Meu perfil</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="nome">Nome Completo</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nome"
                                            defaultValue={watch('nome')}
                                            onChange={(e) => setValue('nome', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="email">E-mail</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                value={watch('email')}
                                                onChange={(e) => setValue('email', e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="cpf">CPF*</label>
                                            <IMaskInput
                                                mask="000.000.000-00"
                                                type="text"
                                                value={cpfUsuario}
                                                onChange={(e) => setCpfUsuario(e.target.value)}
                                                className="form-control"
                                                placeholder="Digite o seu CPF"
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="telefone">Telefone</label>
                                            <input
                                                mask="(00) 0000-0000"
                                                type="text"
                                                inputref={telefone}
                                                onChange={handleTelefoneChange}
                                                onFocus={handleTelefoneFocus}
                                                className="form-control"
                                                value={telefoneUsuario || "(16)"}
                                                maxLength={14}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                style={{ width: '100%' }}
                                                className="btn btn-secondary mt-4"
                                                onClick={handleShow}
                                            >Alterar senha</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-4 text-end">
                                    <button type="button" className="btn btn-primary" onClick={setShowConfirm}>Salvar alterações</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* modal de troca de senha */}
            <Modal centered show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Trocar Senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="password">Nova Senha</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {/* Confirm password input */}
                    <div className="form-group mt-4">
                        <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                        <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handlePasswordChange}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*modal de verificacao de senha para alterar qqr coisa*/}
            <Modal centered show={showconfirm} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirme sua senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="password">Para continuar, digite sua senha atual</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={atualizarUser}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Perfil;
