import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../Api';
import TextareaAutosize from 'react-textarea-autosize';
import CurrencyInput from '../../Components/CurrencyInput';

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eachQuarterOfInterval } from 'date-fns';


function NovoCliente() {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm()
    const [status, setStatus] = useState({ success: false, message: '' })
    const [selectedCategoria, setSelectedCategoria] = useState(null);



    const onSubmit = async (data) => {

        window.scrollTo(0, 0);
        try {

            const formData = new FormData();
            formData.append('nome', data.nome); // nome é a variável que contém o valor do campo "nome"
            formData.append('codigo_produto', data.codigo_produto); // codigo_produto é a variável que contém o valor do campo "codigo_produto"
            formData.append('foto', data.foto[0]); // foto é a variável que contém o valor do campo "foto". Note que estamos adicionando o primeiro elemento do array, pois é o único arquivo que o usuário pode selecionar
            formData.append('descricao', data.descricao); // descricao é a variável que contém o valor do campo "descricao"
            formData.append('tipo', 'unit');

            // if (data.categoria.length > 0) {
            //     formData.append('categoria', data.categoria); // categoria é a variável que contém o valor do campo "categoria"
            // }


            formData.append('categoria', data.categoria); // categoria é a variável que contém o valor do campo "categoria"

            //limpa a string e deixa só o valor numerico
            formData.append('valor_venda', valorVenda.replace(/[^0-9]/g, ""));




            const response = await Api.post('NovoProduto', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setStatus(response.data)

            //Resetar o formulario após enviar e der sucesso
            if (response.data.success === true) {
                setValorVenda('');
                setSelectedCategoria(null);
                reset()
            }


        } catch (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.error) { //se o SERVIDOR retornar um erro, mostra a mensagem de erro
                setStatus({
                    success: false,
                    message: error.response.data.error,
                });
            } else { // Mostra uma mensagem de erro genérica ao usuário
                setStatus({
                    success: false,
                    message: "Ocorreu um erro ao cadastrar o cliente. Tente novamente mais tarde.",
                });
            }

        }

    };

    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        const buscarCategorias = async () => {
            try {
                const response = await Api.get(`ListarCategorias`);
                setCategorias(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        buscarCategorias();
    }, []);

    const [valorVenda, setValorVenda] = useState('');
    const handleValorVendaChange = (valor) => {
        setValorVenda(valor);
    }

    function handleKeyUp(e) {
        e.target.value = e.target.value.toUpperCase();
    }

    return (
        <div className='page-full'>
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-page-full mb-4 cursor-pointer" onClick={() => window.history.back()} >
                    <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                </div>
                <div className="pt-5 m-5">
                    <div className="row d-flex justify-content-center align-items-top">
                        <div className='col-md-2'>
                            <span className="ant-avatar-circle nex-avatar default circle-icon">
                                <FontAwesomeIcon icon="fa-solid fa-box-open" />
                            </span>
                        </div>
                        <div className="col-md-8 mb-5">
                            <h1 className="h3 mb-3 fw-bold">Informações do Produto</h1>

                            {status.message && (
                                <div className={`alert ${status.success ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div className="alert-icon">
                                        <i className="far fa-fw fa-bell"></i>
                                    </div>
                                    <div className="alert-message">
                                        {status.message}
                                    </div>
                                </div>
                            )}

                            <div className="mb-3">
                                <label className="form-label">Nome do produto*</label>
                                <input type="text" className="form-control" onKeyUp={handleKeyUp} {...register("nome", { required: true })} />
                                {errors.nome && <span className="text-danger">Este campo é obrigatório</span>}
                            </div>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label className="form-label">Código de referência</label>
                                    <input type="text" className="form-control" {...register("codigo_produto")} />
                                    {errors.codigo_produto && <span className="text-danger">Este campo é obrigatório</span>}
                                </div>

                                <div className="col-md-5 mb-3">
                                    <label htmlFor="foto" className="form-label">Foto</label>
                                    <div className="input-group">
                                        <input
                                            id="foto"
                                            type="file"
                                            accept=".jpg, .jpeg, .png"
                                            className="form-control"
                                            {...register("foto")}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                                document.getElementById('foto').click();
                                            }}
                                        >
                                            <i className="bi bi-folder"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label className="form-label">Preço unitário*</label>

                                    <CurrencyInput
                                        defaultValue={valorVenda}
                                        onChange={handleValorVendaChange}
                                    />

                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Descrição</label>
                                    <TextareaAutosize onKeyUp={handleKeyUp} className="form-control" {...register("descricao")} minRows={1} maxRows={7} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label className="form-label">Categoria*</label>

                                    <Select
                                        placeholder="Selecione"
                                        options={categorias.map((categoria) => ({
                                            value: categoria.id,
                                            label: categoria.nome,
                                        }))}
                                        value={selectedCategoria} // Passar o valor selecionado ao componente
                                        onChange={(selectedOption) => {
                                            setSelectedCategoria(selectedOption); // Atualizar o valor selecionado no estado
                                            register('categoria', { value: selectedOption?.value });
                                        }}
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer-page-full">
                    <div className="footer-client">
                        <button className="btn btn-light button-large fw-bold"> CADASTRAR </button>
                        <button type="button" className="btn text-white button-large fw-bold" onClick={() => window.history.back()}> CANCELAR </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default NovoCliente
